import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldProps, useField } from "formik";
import { Button, LinearProgress, Typography, Card, CardContent, Grid, Box, useTheme, FormControlLabel, Radio, RadioGroup, MenuItem, InputLabel, FormControl, Select } from "@mui/material";
import { TextField } from "formik-material-ui";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as yup from "yup";
import { UserState } from "../../../types/User";
import { tokens } from "../../../theme";
import { updateFinancialGoal, updatePersonalInfo } from "../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { Auth } from "aws-amplify";
import handleSubmit from "../goalSetting/index"

// Validation schema
const validationSchema = yup.object({
  passiveIncome: yup.number().required('Passive income is required').positive('Value must be positive'),
  equity: yup.number().required('Equity is required').positive('Value must be positive'),
  numberOfProperties: yup.number().required('Number of properties is required').positive('Value must be a positive integer').integer(),
  yearOfRetirement: yup.number().required('Year of retirement is required').positive('Value must be positive').integer(),
  targetRentalYield: yup.number().required('Target rental yield is required').positive('Value must be positive'),
  isFirstIp: yup.boolean().required('First IP status is required'),
  // rentVesting: yup.boolean().required('Rent vesting status is required'),
  needAssetProtection: yup.boolean().required('Asset protection status is required'),
});




interface FinancialGoal {
  financialGoal: any;

}
interface ProfileData {
  financialGoal: FinancialGoal;
}
interface GoalSettingProps {
  handleNext: (values: Partial<UserState>) => void;
  handleBack: () => void;
  submit: () => void;
  profiledata: ProfileData
  formData: UserState;
  isDisabled?: boolean
}

const GoalSetting: React.FC<GoalSettingProps> = ({ handleNext, handleBack, submit, formData, isDisabled, profiledata }) => {
  console.log("isDisabled", isDisabled)

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userdata, setUserData] = useState({})

  const user = async () => {
    const userData = await Auth?.currentAuthenticatedUser()
    // Assuming storage is your storage object (e.g., localStorage, sessionStorage)


    // Step 1: Extract the user data string
    const keyPrefix = "CognitoIdentityServiceProvider.5t6cadapsb5aef6u7acf91gu3g";
    const userId = userData.username;
    const userDataKey = `${keyPrefix}.${userId}.userData`;
    const userDataString = userData.storage.getItem(userDataKey);

    if (userDataString) {
      // Step 2: Parse the JSON string
      const userData = JSON.parse(userDataString);

      // Step 3: Access the desired attributes
      const sub = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "sub")?.Value;
      const email = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "email")?.Value;
      const name = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "name")?.Value;
      const family_name = userData.UserAttributes.find((attr: { Name: string; }) => attr.Name === "family_name")?.Value;
      const values = {

        id: sub,
        firstName: name,
        lastName: family_name,
        email: email,
        phoneNumber: "987987897897"
      }
      setUserData(values)
      // ("User ID (sub):", sub);
      // ("Email:", email);
    } else {
      console.log("User data not found");
    }


  }


  useEffect(() => {
    user()
  }, [profiledata])
  // ('profiledata.financialGoal?.passive_income', profiledata.financialGoal.financialGoal[0]?.passive_income)


  const currentYear = new Date().getFullYear();
  const years = Array?.from(new Array(41), (val, index) => currentYear + index);


  // const defaultValues = {
  //   year_of_retirement: profiledata?.financialGoal?.financialGoal[0]?.year_of_retirement ?? formData.financialGoal.year_of_retirement ?? '',
  //   // other fields...
  // };

  // ('Converted yearOfRetire>>>>>>>>>>>>>>>>>>>>>>>>>>>ment:', defaultValues);
  // useEffect(() => {
  //   ('profiledata:', profiledata);
  //   ('formData:', formData);
  // }, [profiledata, formData]);


  return (
    <Formik

      initialValues={{

        passive_income: profiledata?.financialGoal?.financialGoal[0]?.passive_income ?? formData.financialGoal.passive_income,
        equity: profiledata?.financialGoal?.financialGoal[0]?.equity ?? formData.financialGoal.equity,
        number_of_properties: profiledata?.financialGoal?.financialGoal[0]?.number_of_properties ?? String(formData.financialGoal.number_of_properties),
        year_of_retirement: profiledata?.financialGoal?.financialGoal[0]?.year_of_retirement ?? formData.financialGoal.year_of_retirement,
        targetRentalYield: profiledata?.financialGoal?.financialGoal[0]?.targetrentalyield ?? formData.financialGoal.targetRentalYield,
        isFirstIp: profiledata?.financialGoal?.financialGoal[0]?.isfirstip ?? formData.financialGoal.isFirstIp,
        needAssetProtection: profiledata?.financialGoal?.financialGoal[0]?.needassetprotection ?? formData.financialGoal.needAssetProtection,

      }}







      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {

        // dispatch(updateFinancialGoal(values || {}));
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        handleNext({ financialGoal: values })
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div className="flex flex-col items-center justify-center">

          <Typography variant="h3" gutterBottom sx={{ textAlign: "center", fontWeight: "bold", color: colors.primary[100] }}>
            Let's Set Your Goals
          </Typography>
          <Form className="w-full">

            <Box my={3} display="flex" justifyContent="end">

              {/* <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                onClick={submitForm}
                sx={{
                  color: colors.grey[900],
                  backgroundColor: colors.blueAccent[100],
                  padding: '15px 15px 15px 18px',
                  height: '40px',
                  "&:hover": {
                    backgroundColor: colors.blueAccent[300], // Adjust hover color as needed
                  },
                }}>
                Save & Continue<ArrowForwardIcon sx={{ ml: 1 }} />
              </Button> */}
              <Box sx={{ position: 'relative', width: 200 }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                    color: '#fff',
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: 14,
                    borderRadius: 5,
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      background: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important',
                      '& .overlay': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  Save & Continue<ArrowForwardIcon sx={{ ml: 1 }} />
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'white',
                      overflow: 'hidden',
                      width: 0,
                      height: '100%',
                      transition: 'all 0.6s ease-in-out 0s',
                    }}
                  >
                    <Box
                      className="text"
                      sx={{
                        color: 'black',
                        fontSize: 16,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Save & Continue<ArrowForwardIcon sx={{ ml: 1 }} />
                    </Box>
                  </Box>
                </Button>
              </Box>

              <div>

              </div>
            </Box>
            <Card
              sx={{
                padding: "20px !important",
                marginBottom: "30px !important",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                borderRadius: "10px",
                // backgroundColor: colors.blueAccent[900],
              }}>
              <CardContent sx={{ padding: "10px !important" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6} lg={4}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <AccountBalanceWalletIcon color="primary" sx={{ color: colors.grey[100] }} />
                      <Typography variant="h5" sx={{ color: colors.grey[100] }}>What is your passive income goal?</Typography>
                    </Box>
                    <Field
                      component={TextField}
                      name="passive_income"
                      label="Passive Income"
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input-focused': {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors.grey[100],
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <AttachMoneyIcon color="primary" sx={{ color: colors.grey[100] }} />
                      <Typography variant="h5" sx={{ color: colors.grey[100] }}>How much equity would you need?</Typography>
                    </Box>
                    <Field
                      component={TextField}
                      name="equity"
                      label="Equity"
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input-focused': {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors.grey[100],
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <HomeIcon color="primary" sx={{ color: colors.grey[100] }} />
                      <Typography variant="h5" sx={{ color: colors.grey[100] }}>Number of properties you want to buy?</Typography>
                    </Box>
                    <Field
                      component={TextField}
                      name="number_of_properties"
                      label="Number of Properties"
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input-focused': {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors.grey[100],
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <EventIcon color="primary" sx={{ color: colors.grey[100] }} />
                      <Typography variant="h5" sx={{ color: colors.grey[100] }}>In which year you want to retire?</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Year of Retirement</InputLabel>
                      <Field name="year_of_retirement">
                        {({ field, form }: FieldProps) => (
                          <Select
                            {...field}
                            labelId="year-of-retirement-label"
                            id="year"
                            label="Year of Retirement"
                            onChange={(event) => {
                              form.setFieldValue("year_of_retirement", event.target.value);
                            }}
                            sx={{
                              '& .MuiInputBase-input-focused': {
                                color: colors.grey[100],
                                borderColor: colors.grey[100],
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: colors.grey[100],
                              },
                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: colors.grey[100],
                              },
                            }}
                            value={field.value || ''}
                          >
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </FormControl>
                    {/* <Field
                   
                      id="year"
                      name="year_of_retirement"
                      label="Year of Retirement"
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input-focused': {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors.grey[100],
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                      }}
                    > 
                    
                    {years.map((year) => {
                      return(
                      <MenuItem key={year} value={year} className="w-full">
                        {year && year}
                      </MenuItem>
                    )})}
                    
                    </Field> */}
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <Typography variant="h5" sx={{ color: colors.grey[100] }}>What is your target rental yield (%)</Typography>
                    </Box>
                    <Field
                      component={TextField}
                      name="targetRentalYield"
                      label="Target Rental Yield"
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input-focused': {
                          color: colors.grey[100],
                          borderColor: colors.grey[100],
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors.grey[100],
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: colors.grey[100],
                        },
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} md={6}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <Typography variant="h5" sx={{ color: theme.palette.mode === 'dark' ? 'white' : theme.palette.grey[800] }}>
                        Is this your first investment property and you want to RentVest?
                      </Typography>
                    </Box>
                    <Field name="isFirstIp" component={TextField}>


                      {({ field }: FieldProps) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel

                            value="true"
                            control={<Radio />}
                            label="Yes"
                            sx={{
                              color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              '& .Mui-checked': {
                                color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              },
                            }}
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                            sx={{
                              color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              '& .Mui-checked': {
                                color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              },
                            }}
                          />
                        </RadioGroup>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box display="flex" alignItems="center" py={2} gap={1}>
                      <Typography variant="h5" sx={{ color: colors.grey[100] }}>
                        Do you need asset protection?
                      </Typography>
                    </Box>
                    <Field name="needAssetProtection">
                      {({ field }: FieldProps) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Yes"
                            sx={{
                              color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              '& .Mui-checked': {
                                color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              },
                            }}
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                            sx={{
                              color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              '& .Mui-checked': {
                                color: theme.palette.mode === 'dark' ? 'white !important' : '#222222 !important',
                              },
                            }}
                          />
                        </RadioGroup>
                      )}
                    </Field>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
            {isSubmitting && <LinearProgress />}

          </Form>
        </div>
      )
      }
    </Formik >
  );
};

export default GoalSetting;
