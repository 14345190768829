import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Grid, Card, useTheme, StepIconProps, CircularProgress, Box, Typography } from '@mui/material';
import GoalSetting from '../goalSetting';
import FamilyMembers from '../familyMembers';
import CurrentPortfolio from '../currentPortfolio';
import Income from '../../pages/income';
import Expensess from '../expenses';
import { useDispatch, useSelector } from 'react-redux';
import StepContent from '@mui/material/StepContent';
import { updatePersonalInfo, updateFinancialGoal, updateFamilyInfo, updateCurrentPortfolio, updateExpenses, updateEmploymentInfo, updatePropertyInfo, initialState } from '../../../features/userSlice';
import { CurrentPortfolioInfo, EmploymentInfo, Expenses, FamilyInfo, FinancialGoal, PersonalInfo, PropertyInfo, UserState } from '../../../types/User';
import { AppDispatch, RootState } from '../../../store';
import AuthenticatedAPI from '../../../auth_helper';
import { tokens } from "../../../theme";
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useSubmitWithStepProgression from './useSubmitWithStepProgression';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AutoUpdateFetchpropertyavm } from '../../../hooks/properties/properties';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 23,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? '#bdbdbd' : '#212121',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#bdbdbd' : '#212121',
  zIndex: 1,
  color: theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#ffffff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%)',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : '#ffffff',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%)',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : '#ffffff',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <img
      alt="profile-user"
      width="21px"
      height="21px"
      src={`/target.svg`}
    />,
    2: <img
      alt="profile-user"
      width="21px"
      height="21px"
      src={`/usersGroupRounded.svg`}
    />,
    3: <img
      alt="profile-user"
      width="21px"
      height="21px"
      src={`/schoolCaseRound.svg`}
    />,
    4: <img
      alt="profile-user"
      width="21px"
      height="21px"
      src={`/pieChart.svg`}
    />,
    5: <img
      alt="profile-user"
      width="21px"
      height="21px"
      src={`/moneyManagement.svg`}
    />,
    6: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Goals', 'Family', 'Income', 'Portfolio', 'Expenses'];

export interface Expense {
  existingInvestmentLoanAmount: number;
  personalLoansAmount: number;
  creditCardLimit: number;
  livingExpenses: number;
  leasePayments: number;
  miscExpesses: number;
}

interface reduxstore {
  personalInfo: PersonalInfo;
  financialGoal: FinancialGoal;
  familyInfo: FamilyInfo;
  employmentInfo: EmploymentInfo
  currentPortfolio: CurrentPortfolioInfo
  propertyInfo: PropertyInfo;
  expenses: Expense
}

export default function MultiStepForm({ investorID }: any) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const userProfile = useSelector((state: RootState) => state.user);
  console.log('userProfile11111111111', userProfile)
  const [activeStep, setActiveStep] = React.useState(0);
  const [profiledata, setProfileData] = React.useState<reduxstore | {}>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [financialGoal, setFinancialGoal] = React.useState<FinancialGoal>(initialState.financialGoal);
  const [familyInfo, setFamilyInfo] = React.useState<FamilyInfo>(initialState.familyInfo);
  const [employmentInfo, setEmploymentInfo] = React.useState<EmploymentInfo>(initialState.employmentInfo);
  const [currentPortfolio, setCurrentPortfolio] = React.useState<CurrentPortfolioInfo>(initialState.currentPortfolio);
  const [propertyInfo, setPropertyInfo] = React.useState<PropertyInfo>(initialState.propertyInfo);
  const [expenses, setExpenses] = React.useState<Expenses>(initialState.expenses);
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    const step = searchParams.get('step');
    if (step) {
      const stepNumber = parseInt(step, 10);
      if (!isNaN(stepNumber)) {
        setActiveStep(stepNumber);
      }
    } else {
      setActiveStep(0);
    }
  }, [searchParams]);

  const {
    handleSubmitWithProgression,
    submissionInProgress,
    submissionCompleted,
  } = useSubmitWithStepProgression();

  console.log('investorID', investorID)
  React.useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      sessionStorage.setItem("redirectAfterReloadRoadmap", "true");
      e.returnValue = ''; // Display the confirmation dialog
      return '';
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  React.useEffect(() => {
    const redirectAfterReload = sessionStorage.getItem("redirectAfterReloadRoadmap");
    if (redirectAfterReload === "true") {
      sessionStorage.removeItem("redirectAfterReloadRoadmap");
      navigate("/goal-setting", { replace: true }); // Replace with your desired page
    }
  }, []);


  const handleSubmit = async (formdata: any) => {

    try {
      setLoading(true);
      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'put',
        '/api/profile/update',
        {
          body: {
            userId: investorID ? investorID : userProfile.personalInfo.id,
            profile: formdata,
          },
        }
      );
      // console.log(response, "responseresponseresponseresponseresponseresponse")
      if (response.success) {
        getUserProfile()
        if (!investorID) {

          handleUpdateStratgy()
        }
        toast.success(response.message);
        // Close loading toast and display success toast
      }
      return response;
    } catch (error) {
      // Display error toast
      toast.error('Error saving user profile');
      console.error('Error saving user profile:', error);
      return error;
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!userProfile.personalInfo) {
      handleSubmit({ 'personalInfo': userProfile.personalInfo })
    }
  }, [userProfile]);

  React.useEffect(() => {
    if (submissionCompleted && !submissionInProgress) {
      if (activeStep < 4) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {

        if (investorID && location.pathname !== '/goal-setting') {
          navigate('/ba-dashboard');
        } else {
          navigate('/dashboard');
        }
      }
    }
  }, [submissionCompleted, submissionInProgress]);

  const handleNext = (newData: Partial<UserState>) => {

    console.log("new data", newData)
    const moveToNextStep = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    switch (activeStep) {
      case 0:
        if (!disabled) {
          setFinancialGoal(prevFinancialGoal => {
            handleSubmitWithProgression(newData, handleSubmit);
            return prevFinancialGoal;
          });
        } else {
          moveToNextStep();
        }
        break;
      case 1:
        if (!disabled) {

          setFamilyInfo(prevsetFamilyInfo => {
            handleSubmitWithProgression(newData, handleSubmit);
            return prevsetFamilyInfo;
          });
        } else {
          moveToNextStep();
        }

        break;
      case 2:
        if (!disabled) {

          setEmploymentInfo(prevsetEmploymentInfo => {
            handleSubmitWithProgression(newData, handleSubmit);
            return prevsetEmploymentInfo;
          });
        } else {
          moveToNextStep();
        }
        break;
      case 3:
        if (!disabled) {

          setCurrentPortfolio(prevCurrentPortfolio => {
            // Merge newData with prevCurrentPortfolio
            const updatedCurrentPortfolio = {
              ...prevCurrentPortfolio,
              ...newData.currentPortfolio, // Ensure newData.currentPortfolio is merged
            };
            console.log(updatedCurrentPortfolio, "updatedCurrentPortfolio")
            // Call handleSubmitWithProgression with the updated data
            handleSubmitWithProgression({ currentPortfolio: updatedCurrentPortfolio }, handleSubmit);

            // Return the updated portfolio
            return updatedCurrentPortfolio;
          });
        } else {
          moveToNextStep();
        }
        // setCurrentPortfolio(prevCurrentPortfolio => {
        //   handleSubmitWithProgression(newData, handleSubmit);
        //   return prevCurrentPortfolio;
        // });
        break;
      case 4:
        if (!disabled) {

          setExpenses(prevExpenses => {
            handleSubmitWithProgression(newData, handleSubmit);
            return prevExpenses;
          });
        } else {
          moveToNextStep()
        }
        break;
      default:
    }
  };

  React.useEffect(() => {
    const fetch = async () => {
      const data = await Auth?.currentAuthenticatedUser().then(async (res) => {
        const response = await AuthenticatedAPI.request(
          'InvestmentAPI',
          'get',
          `/api/strategy/${investorID ? investorID : res.username}`
        );
      });
      const data1 = await Auth?.currentAuthenticatedUser().then(async (res) => {
        const response = await AuthenticatedAPI.request(
          'InvestmentAPI',
          'get',
          `/api/portfolio-analysis/${investorID ? investorID : res.username}`
        );
      });
    };
    fetch();
  }, []);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);  
    
  };





  
  const handleStep = (step: number) => () => {
    if (step <= 4) {
      setActiveStep(step);

    }
  };
  console.log('activeStep', activeStep)
  React.useEffect(() => {
    // Check if we have an investorID and not on goal-settings page
    if (investorID && location.pathname !== '/goal-settings') {
      // Only allow steps 0 and 3 to be editable
      if (activeStep === 0 || activeStep === 3) {
        // setActiveStep(step);
        setDisabled(false);
      } else {
        // setActiveStep(step);
        setDisabled(true);
      }
    }
  }, [activeStep, investorID, location.pathname])
  const handleinvestment = async () => {
    const userDatastart = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'get',
        `/api/strategy/${res.username}`
      );
      if (!response) {
        throw new Error('Failed to fetch user profile');
      }
    });
  };
  const getUserProfile = async () => {
    try {
      let response: any;
      // if (investorID) {
      //   response = await AuthenticatedAPI.request(
      //     'InvestmentAPI',
      //     'get',
      //     `/api/profile/${investorID}`
      //   );
      //   if (!response) {
      //     throw new Error('Failed to fetch user profile');
      //   }
      //   
      //   if (response) {
      //     const profileData = response.data;
      //     setProfileData(profileData);
      //     dispatch(updatePersonalInfo(profileData?.personalInfo?.personalInfo[0]));
      //     dispatch(updateFinancialGoal(profileData?.financialGoal?.financialGoal[0]));
      //     dispatch(updateFamilyInfo(profileData?.familyInfo));
      //     dispatch(updateEmploymentInfo(profileData?.employmentInfo?.employmentInfo[0]));
      //     dispatch(updateCurrentPortfolio(profileData?.currentPortfolio));
      //     // dispatch(updatePropertyInfo(profileData?.propertyInfo?.propertyInfo[0]));
      //     dispatch(updateExpenses(profileData?.expenses?.expenses));


      //   }
      //   return response;
      // }
      // else {
      await Auth?.currentAuthenticatedUser().then(async (res) => {



        response = await AuthenticatedAPI.request(
          'InvestmentAPI',
          'get',
          `/api/profile/${investorID ? investorID : res.username}`
        );


        if (!response) {
          throw new Error('Failed to fetch user profile');
        }

        if (response) {
          const profileData = response.data;
          setProfileData(profileData);
          dispatch(updatePersonalInfo(profileData?.personalInfo?.personalInfo[0]));
          dispatch(updateFinancialGoal(profileData?.financialGoal?.financialGoal[0]));
          dispatch(updateFamilyInfo(profileData?.familyInfo));
          dispatch(updateEmploymentInfo(profileData?.employmentInfo?.employmentInfo[0]));
          dispatch(updateCurrentPortfolio(profileData?.currentPortfolio));
          // dispatch(updatePropertyInfo(profileData?.propertyInfo?.propertyInfo[0]));
          dispatch(updateExpenses(profileData?.expenses?.expenses));

          // const investmentProperties = profileData?.currentPortfolio?.investmentProperties;

          // if (investmentProperties?.length > 0) {
          //   // Array to store promises for properties that need updating
          //   const updatedPropertiesPromises = [];

          //   // First loop to identify properties that need updating
          //   for (const property of investmentProperties) {
          //     const currentEstimatedValue = parseInt(property.currentestimatedvalue, 10);
          //     const propertyId = property.propertyid;

          //     // Check if the fetched value is different from the current value
          //     const promise = AutoUpdateFetchpropertyavm(propertyId).then(data => {
          //       const fetchedValue = data.valuation?.value;
          //       if (fetchedValue !== currentEstimatedValue) {
          //         // Only include the property in the update list if it needs updating
          //         return { ...property, currentestimatedvalue: fetchedValue };
          //       }
          //       // If no update is needed, resolve to null
          //       return null;
          //     });

          //     updatedPropertiesPromises.push(promise);
          //   }

          //   // Wait for all promises to resolve
          //   const updatedInvestmentProperties = (await Promise.all(updatedPropertiesPromises))
          //     .filter(property => property !== null); // Filter out nulls

          //   // If there are any updated properties, submit the updated array
          //   if (updatedInvestmentProperties.length > 0) {
          //     handleSubmit({ currentPortfolio: { investmentProperties: updatedInvestmentProperties } });
          //   }
          // }
        }
        return response;
      });
      // }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  //   const getUserProfile = async () => {
  //     try {
  //       const userData = await Auth?.currentAuthenticatedUser().then(async (res) => {
  //         const response = await AuthenticatedAPI.request(
  //           'InvestmentAPI',
  //           'get',
  //           `/api/profile/${res.username}`
  //         );
  // 
  //         if (!response) {
  //           throw new Error('Failed to fetch user profile');
  //         }
  //         if (response) {
  //           setProfileData(response.data);
  //           dispatch(updatePersonalInfo(response?.data?.personalInfo?.personalInfo[0]));
  //           dispatch(updateFinancialGoal(response?.data?.financialGoal?.financialGoal[0]));
  //           dispatch(updateFamilyInfo(response?.data?.familyInfo));
  //           dispatch(updateEmploymentInfo(response?.data?.employmentInfo?.employmentInfo[0]));
  //           dispatch(updateCurrentPortfolio(response?.data?.currentPortfolio));
  //           dispatch(updatePropertyInfo(response?.data?.propertyInfo?.propertyInfo[0]));
  //           dispatch(updateExpenses(response?.data?.expenses?.expenses));
  //         }
  //         return response;
  //       });
  //     } catch (error) {
  //       console.error('Error fetching user profile:', error);
  //     }
  //   };

 
  React.useEffect(() => {
    console.log("Current activeStep:", activeStep);
  }, [activeStep]);





  React.useEffect(() => {
    const fetchprofile = async () => {
      await getUserProfile();
    };
    fetchprofile();
  }, [investorID]);

  const StepComponent = (props: any) => {
    
    switch (activeStep) {
      case 0:
        return <GoalSetting {...props} profiledata={profiledata} />;
      case 1:
        return <FamilyMembers {...props} profiledata={profiledata} />;
      case 2:
        return <Income {...props} profiledata={profiledata} />;
      case 3:
        return <CurrentPortfolio {...props} profiledata={profiledata} />;
      case 4:
        return <Expensess {...props} profiledata={profiledata} />;
      default:
        return null;
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleUpdateStratgy = async () => {

    const userDatastart = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'put',
        `/api/strategy/update/${res.username}`
      );

      if (!response) {
        throw new Error('Failed to fetch user profile');
      }
    })
  }
  console.log('investorID', investorID)


  return (
    <>
      {investorID && location.pathname !== '/goal-setting' ? (
        <>
          <Grid container justifyContent="center" columns={12} className='mt-8'>
            <Grid item md={12} lg={12} xl={10}>
              <Card
                sx={{
                  padding: "20px !important",
                  marginBottom: "30px !important",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                  backgroundColor: colors.primary[400],
                }}
              >
                <Stack sx={{ width: '100%' }} spacing={0} className='multi_stepForm mt-10'>
                  <Stepper activeStep={activeStep} orientation="vertical" connector={<ColorlibConnector />} className='step_icons mb-16'>

                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}  onClick={handleStep(index)}>{label}</StepLabel>

                        {activeStep === index && (
                          <StepContent className='step_content'>
                            <div className='step_forms'>
                              {loading ? (
                                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <StepComponent
                                  handleNext={handleNext}
                                  handleBack={handleBack}
                                  formData={userProfile}
                                  isDisabled={disabled} />
                              )}
                            </div>
                          </StepContent>
                        )}

                      </Step>
                    ))}
                  </Stepper>

                </Stack>
              </Card>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center" columns={12} className='mt-8'>
          <Grid item md={12} lg={12} xl={10}>
            <Card sx={{
              padding: "20px !important",
              marginBottom: "30px !important",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
              backgroundColor: colors.primary[400],
            }}>
              <Stack sx={{ width: '100%' }} spacing={0} className='multi_stepForm mt-10'>
                <Stepper className='step_icons mb-16' alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label} onClick={handleStep(index)}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div className='step_forms'>
                  {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <StepComponent
                      handleNext={handleNext}
                      handleBack={handleBack}
                      formData={userProfile}
                      isDisabled={disabled}

                    />
                  )}
                </div>
              </Stack>
            </Card>
          </Grid>
        </Grid>

      )
      }



    </>
  );
}
