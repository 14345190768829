import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, useTheme, Grid, Typography, Card, Button, CircularProgress, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import { RootState } from "../../../store";
import DownloadIcon from '@mui/icons-material/Download';
import {
  updatePropertyDetails,
  updateAnnualHoldingCosts,
  updateAcquisitionCosts,
  updateCashFlowProjection,
  submitCashFlowAnalysis,
  updateEmployementIncome,

} from "../../../features/cashFlow";
import PropertyDetails from "../../components/cashFlow/propertyDetails";
import AnnualHoldingCosts from "../../components/cashFlow/annualHoldingCosts";
import AcquisitionCosts from "../../components/cashFlow/acquisitionCosts";
import CashFlowProjection from "../../components/cashFlow/cashFlowProjection";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CashFlowPdf } from "./cashFlowPdf";
import Income from "../../components/cashFlow/income";
import { FetchUserProfile } from "../../../hooks/properties/properties";
import Depreciation from "../../components/cashFlow/depreciation";




interface CashFlowAnalysisProps {
  agentProfile: any;
  investarData?: any;
}

const CashFlowAnalysis: React.FC<CashFlowAnalysisProps> = ({ agentProfile, investarData }) => {
  console.log('investarData', investarData)
  console.log('agentProfile', agentProfile)
  const areaChartFlow = useSelector((state: any) => state.cashFlowAnalysis.areaChartFlows);
  const propertyTitle = useSelector((state: any) => state.cashFlowAnalysis.propertyDetails.address);
  console.log(propertyTitle,"dkldjadjsalkdjsak")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const cashFlowAnalysis = useSelector(
    (state: RootState) => state.cashFlowAnalysis
  );
  console.log('cashFlowAnalysis :>> ', cashFlowAnalysis);
  const [buttonLabel, setButtonLabel] = useState("DOWNLOAD REPORTS");
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserdata] = useState<any>({});
  console.log('userData', userData)
  const handleSubmit = () => {
    dispatch(submitCashFlowAnalysis());
  };
  const pdfRef = useRef<HTMLDivElement>(null);

  const generatePDF = async () => {
    setButtonLabel("Preparing PDF...");

    const element = pdfRef.current;
    if (!element) return;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save("cash-flow-analysis.pdf");

    setButtonLabel("DOWNLOAD REPORTS");
  };
  useEffect(() => {
    if (cashFlowAnalysis) {
      setLoading(false)
    }
  }, [cashFlowAnalysis])
  const handleDownloadPdf = async () => {
    try {
      console.log("clicked")
      setButtonLabel("Preparing PDF...")
      await CashFlowPdf(cashFlowAnalysis, areaChartFlow, propertyTitle)
      setButtonLabel("DOWNLOAD REPORTS")

    } catch (error: any) {
      console.log(error)
    }
  }
  const memoizedUpdateAnnualHoldingCosts = useCallback(
    (updates: any) => {
      dispatch(updateAnnualHoldingCosts(updates));
    },
    [dispatch]
  );
  const annualHoldingCostss = useMemo(
    () => cashFlowAnalysis.propertyDetails?.annualHoldingCosts || {},
    [cashFlowAnalysis.propertyDetails]
  );

  const propertyDetailss = useMemo(
    () => cashFlowAnalysis.propertyDetails || {},
    [cashFlowAnalysis.propertyDetails]
  );
  // Memoized calculation for gross income
  const grossIncome = useMemo(() => {
    if (!userData || !userData.employmentInfo || userData.employmentInfo.length === 0) {
      return 0;
    }
    const employmentInfo = userData.employmentInfo[0];
    const netIncomeFA = parseFloat(employmentInfo?.net_gross_income_fa || "0");
    const netIncomeMA = parseFloat(employmentInfo?.net_gross_income_ma || "0");

    return employmentInfo?.employment_type === 'PAYG'
      ? netIncomeFA + netIncomeMA
      : parseFloat(employmentInfo?.self_employed_income || "0");
  }, [userData]);

  // Memoized calculation for other taxable income
  const otherTaxIncome = useMemo(() => {
    if (!userData || !userData.employmentInfo || userData.employmentInfo.length === 0) {
      return 0;
    }

    const employmentInfo = userData.employmentInfo[0];
    const centre_link = parseFloat(employmentInfo?.centre_link || "0");
    const dividends = parseFloat(employmentInfo?.dividends || "0");
    const other_income = parseFloat(employmentInfo?.other_income || "0");
    const overtime = parseFloat(employmentInfo?.overtime || "0");
    // const portfolio_value = parseFloat(employmentInfo?.portfolio_value || "0");

    return centre_link + dividends + other_income + overtime
  }, [userData]);

  // Memoized fetch function
  const fetchData = useCallback(async () => {

    const res = investarData ? investarData : await FetchUserProfile();
    console.log('res', res)

    setUserdata(res?.employmentInfo);
  }, [investarData]);
  console.log('userData', userData)
  useEffect(() => {
    fetchData();
  }, [fetchData,]);

  // Dispatching updated income only when grossIncome or otherTaxIncome changes
  useEffect(() => {
    if (grossIncome !== undefined && otherTaxIncome !== undefined) {

      dispatch(updateEmployementIncome({ grossFamilyIncome: grossIncome, otherTaxableIncome: otherTaxIncome }));
    }
  }, [dispatch, grossIncome, otherTaxIncome]);


  return (

    <>
      <div className="p-6">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }} className="p-6">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadPdf}
                sx={{
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  color: "#ffffff",
                  padding: "10px 28px",
                }}
                disabled={!propertyTitle}
              >
                <DownloadIcon />
                {buttonLabel}
              </Button>
            </Box>
            <div ref={pdfRef}>
              <Grid container spacing={2} columns={12} className="p-6">
                <Grid item md={4}>
                  <Card
                    sx={{
                      padding: "20px !important",
                      marginBottom: "30px !important",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                      backgroundColor: colors.primary[400],
                    }}>
                    <Typography variant="h3" align="left" pb={3} zIndex={1} gutterBottom>
                      Cash Flow Analysis
                    </Typography>
                    <Income incomeDetails={cashFlowAnalysis.employmentIncome} agentProfile={agentProfile} investarData={investarData?.employmentInfo} />

                    <PropertyDetails propertyDetails={cashFlowAnalysis.propertyDetails} agentProfile={agentProfile} />

                    <Depreciation depreciationDetails={cashFlowAnalysis.depreciation} agentProfile={agentProfile} />
                    <AcquisitionCosts
                      acquisitionCosts={cashFlowAnalysis.propertyDetails?.acquisitionCosts || null} propertyDetails={cashFlowAnalysis?.propertyDetails} />


                    <AnnualHoldingCosts
                      annualHoldingCosts={cashFlowAnalysis.propertyDetails?.annualHoldingCosts || null}
                      updateAnnualHoldingCosts={(updates) => dispatch(updateAnnualHoldingCosts(updates))}
                      propertyDetails={cashFlowAnalysis.propertyDetails} />
                    {/* <AnnualHoldingCosts
                      annualHoldingCosts={cashFlowAnalysis.propertyDetails?.annualHoldingCosts || null}
                      updateAnnualHoldingCosts={memoizedUpdateAnnualHoldingCosts}
                      propertyDetails={cashFlowAnalysis.propertyDetails}
                    /> */}
                    {/* <AnnualHoldingCosts
  annualHoldingCosts={annualHoldingCostss || null }
  updateAnnualHoldingCosts={memoizedUpdateAnnualHoldingCosts}
  propertyDetails={propertyDetailss || null}
/>; */}
                  </Card>
                </Grid>

                <Grid item md={8}>
                  <Card
                    sx={{
                      padding: "20px 20px 0px 20px !important",
                      marginBottom: "30px !important",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                      backgroundColor: colors.primary[400],
                    }}>
                    <Typography variant="h3" align="left" pb={3} zIndex={1} gutterBottom>
                      Cash Flow Projection
                    </Typography>

                    <CashFlowProjection
                      propertyDetails={cashFlowAnalysis.propertyDetails}
                      keyAssumptions={cashFlowAnalysis.keyAssumptions}
                      cashFlowProjection={cashFlowAnalysis.cashFlowProjection} />
                  </Card>
                </Grid>

              </Grid>



              <Box sx={{ display: "none !important" }}
                flexDirection={"column"}
                display={"flex"}
                position={"relative"}
                overflow={"auto"}

              >
                <Grid container gap={2}>
                  <Grid item className="cashflow-left xl:w-[49%] 2xl:w-[40%]">

                    <div
                      className="flex sticky top-0 bottom-0 flex-col z-1000 overflow-x-auto min-lg:w-full gap-4"
                      style={{ position: "fixed", height: "100vh" }}
                    >
                      <Typography variant="h4" align="center" pt={10} zIndex={1} gutterBottom>
                        Cash Flow Analysis
                      </Typography>
                      <div className="card-holder">
                        <Box mb={4}>
                          <PropertyDetails
                            propertyDetails={cashFlowAnalysis.propertyDetails} agentProfile={agentProfile} />
                        </Box>

                        {/* <Box mb={4}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Depecreciation Rate"
                              type="number"
                              value={""}
                              // onChange={(e) =>
                              //   handleChange("estimatedWeeklyRent", Number(e.target.value))
                              // }
                              // value={currentEstimatedValue || ""}
                              // onChange={(e) => setCurrentEstimatedValue(e.target.value)}
                              // onBlur={handleWeeklyRentBlur}
                              onChange={(e) => {
                                const newValue = Number(e.target.value);

                              }}
                              fullWidth
                              sx={{
                                "& .MuiInputBase-input-focused": {
                                  color: colors.grey[100],
                                  borderColor: colors.grey[100],
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: colors.grey[100],
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: colors.grey[100], // Adjust as needed
                                },
                              }}
                            />
                          </Grid>
                        </Box> */}
                        <Box>
                          <AcquisitionCosts
                            acquisitionCosts={cashFlowAnalysis.propertyDetails?.acquisitionCosts || null}
                            propertyDetails={cashFlowAnalysis.propertyDetails} />
                        </Box>
                        <Box mb={4}>
                          <AnnualHoldingCosts
                            annualHoldingCosts={cashFlowAnalysis.propertyDetails?.annualHoldingCosts || null}
                            updateAnnualHoldingCosts={(updates) => dispatch(updateAnnualHoldingCosts(updates))}
                            propertyDetails={cashFlowAnalysis.propertyDetails} />
                        </Box>
                      </div>
                    </div>
                  </Grid>

                  <Grid item className="cashflow-right w-[100%] xl:w-[49%] 2xl:w-[58%]">
                    <Box mb={4} className="flex-col">
                      <Typography variant="h5" align="center" gutterBottom>
                        Cash Flow Projection
                      </Typography>
                      <CashFlowProjection
                        propertyDetails={cashFlowAnalysis.propertyDetails}
                        keyAssumptions={cashFlowAnalysis.keyAssumptions}
                        cashFlowProjection={cashFlowAnalysis.cashFlowProjection} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div></>
        )}
      </div>
    </>
  );
};




export default CashFlowAnalysis;
