import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Slider,
  TextField, Box, Select, MenuItem, FormControl, Grid, SelectChangeEvent, styled
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';

interface Property {
  id: number;
  address: string;
  initialValue: number;
  initialLoan: number;
  passiveIncome: number;
  leverageRatio: number;
  netYield: number;
  growthRate: number;
  effectiveTaxRate: number;
  purchaseYear: number;
  projectedValue: number;
  sellYear: number | null;
  cgt?: number;
}

const initialProperties: Property[] = [
  {
    id: 1,
    address: "23 Wilside Crescent",
    initialValue: 636000,
    initialLoan: 508800,
    passiveIncome: 35681,
    leverageRatio: 80,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2019,
    projectedValue: 0,
    sellYear: null
  },
  {
    id: 2,
    address: "2 Kelburn Road",
    initialValue: 606000,
    initialLoan: 454500,
    passiveIncome: 38924,
    leverageRatio: 75,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2017,
    projectedValue: 0,
    sellYear: null
  },
  {
    id: 3,
    address: "27 Selina Street",
    initialValue: 350000,
    initialLoan: 245000,
    passiveIncome: 17151,
    leverageRatio: 70,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2021,
    projectedValue: 0,
    sellYear: null
  },
  {
    id: 4,
    address: "59 Oriestone Street",
    initialValue: 406000,
    initialLoan: 263900,
    passiveIncome: 17377,
    leverageRatio: 65,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2023,
    projectedValue: 0,
    sellYear: null
  }
];

const ProgressBarContainer = styled(Box)({
  width: '100%',
  height: '24px',
  backgroundColor: '#e0e0e0',
  borderRadius: '12px',
  overflow: 'hidden',
  marginBottom: '8px',
});

const ProgressBarFill = styled(Box)<{ value: number }>(({ value }) => ({
  width: `${value}%`,
  height: '100%',
  backgroundColor: '#4caf50',
  transition: 'width 0.5s ease-in-out',
}));

const PropertyDecisionTool: React.FC = () => {
  const [properties, setProperties] = useState<Property[]>(initialProperties);
  const [modelledYear, setModelledYear] = useState<number>(2024);
  const [assumptions, setAssumptions] = useState({
    cgtRate: 33,
    goalPassiveIncome: 100000,
    pporLoan: 500000,
  });

  const handleModelledYearChange = (_event: Event, value: number | number[]) => {
    setModelledYear(value as number);
  };
  
  const handleAssumptionChange = (key: keyof typeof assumptions, value: string) => {
    setAssumptions(prev => ({ ...prev, [key]: parseFloat(value) }));
  };

  const handlePropertyChange = (id: number, key: keyof Property, value: string | number) => {
    setProperties(prevProps => prevProps.map(prop => 
      prop.id === id ? { ...prop, [key]: key === 'sellYear' ? (value === "Don't Sell" ? null : Number(value)) : Number(value) } : prop
    ));
  };

  const calculateProjectedValue = (initialValue: number, growthRate: number, years: number): number => {
    return initialValue * Math.pow(1 + growthRate / 100, years);
  };

  const calculatePassiveIncome = (projectedValue: number, netYield: number): number => {
    return projectedValue * (netYield / 100);
  };

  const calculateCapitalGainsTax = (initialValue: number, projectedValue: number, effectiveTaxRate: number): number => {
    const gain = projectedValue - initialValue;
    return gain * (effectiveTaxRate / 100);
  };
  useEffect(() => {
    const updatedProperties = properties.map(prop => {
      const years = modelledYear - prop.purchaseYear;
      const projectedValue = calculateProjectedValue(prop.initialValue, prop.growthRate, years);
      const passiveIncome = prop.sellYear && prop.sellYear <= modelledYear ? 0 : calculatePassiveIncome(projectedValue, prop.netYield);
      const cgt = prop.sellYear && prop.sellYear <= modelledYear ? calculateCapitalGainsTax(prop.initialValue, projectedValue, prop.effectiveTaxRate) : 0;
      return { ...prop, projectedValue, passiveIncome, cgt };
    });
    setProperties(updatedProperties);
  }, [modelledYear, properties, assumptions.cgtRate]);

  const totalPassiveIncome = properties.reduce((sum, prop) => sum + prop.passiveIncome, 0);
  const totalProjectedValue = properties.reduce((sum, prop) => sum + prop.projectedValue, 0);
  const totalCapitalGains = properties.reduce((sum, prop) => sum + (prop.cgt || 0), 0);
  const passiveIncomeProgress = (totalPassiveIncome / assumptions.goalPassiveIncome) * 100;
  const pporPayoffProgress = ((totalProjectedValue - totalCapitalGains) / assumptions.pporLoan) * 100;
  const combinedProgress = (passiveIncomeProgress + pporPayoffProgress) / 2;

  const chartData = properties.map(prop => ({
    name: prop.address,
    "Initial Value": prop.initialValue,
    "Projected Value": Math.round(prop.projectedValue),
    "Passive Income": Math.round(prop.passiveIncome),
  }));
  const GoalProgressSection = () => (
    <Box sx={{ marginTop: '20px' }}>
      <Typography variant="h6" gutterBottom>Goal Progress</Typography>
      
      <Typography variant="body1">Passive Income:</Typography>
      <ProgressBarContainer>
        <ProgressBarFill value={Math.min(passiveIncomeProgress, 100)} />
      </ProgressBarContainer>
      <Typography variant="body2" sx={{ marginBottom: '16px' }}>
        {`${Math.round(passiveIncomeProgress)}% ($${Math.round(totalPassiveIncome).toLocaleString()} / $${assumptions.goalPassiveIncome.toLocaleString()})`}
      </Typography>
      
      <Typography variant="body1">PPOR Payoff:</Typography>
      <ProgressBarContainer>
        <ProgressBarFill value={Math.min(pporPayoffProgress, 100)} />
      </ProgressBarContainer>
      <Typography variant="body2" sx={{ marginBottom: '16px' }}>
        {`${Math.round(pporPayoffProgress)}% ($${Math.round(totalProjectedValue - totalCapitalGains).toLocaleString()} / $${assumptions.pporLoan.toLocaleString()})`}
      </Typography>
      
      <Typography variant="body1">Combined Goal:</Typography>
      <ProgressBarContainer>
        <ProgressBarFill value={Math.min(combinedProgress, 100)} />
      </ProgressBarContainer>
      <Typography variant="body2">
        {`${Math.round(combinedProgress)}% Achieved`}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '1400px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Invest Net: Property Decision Tool
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Modelled Year: {modelledYear}
            </Typography>
            <Slider
              value={modelledYear}
              min={2024}
              max={2040}
              step={1}
              onChange={handleModelledYearChange}
              valueLabelDisplay="auto"
            />
          </Box>

          <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell align="right">Initial Value</TableCell>
                  <TableCell align="right">Projected Value</TableCell>
                  <TableCell align="right">Loan</TableCell>
                  <TableCell align="right">Rental Income</TableCell>
                  <TableCell align="right">Growth Rate</TableCell>
                  <TableCell align="right">Effective Tax Rate</TableCell>
                  <TableCell align="right">Net Yield</TableCell>
                  <TableCell align="right">Sell Year</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map((prop) => (
                  <TableRow key={prop.id}>
                    <TableCell component="th" scope="row">{prop.address}</TableCell>
                    <TableCell align="right">${prop.initialValue.toLocaleString()}</TableCell>
                    <TableCell align="right">${Math.round(prop.projectedValue).toLocaleString()}</TableCell>
                    <TableCell align="right">${prop.initialLoan.toLocaleString()}</TableCell>
                    <TableCell align="right">${Math.round(prop.passiveIncome).toLocaleString()}</TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        value={prop.growthRate}
                        onChange={(e) => handlePropertyChange(prop.id, 'growthRate', e.target.value)}
                        inputProps={{ min: 0, max: 100, step: 0.1 }}
                        sx={{ width: '60px' }}
                      />%
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        value={prop.effectiveTaxRate}
                        onChange={(e) => handlePropertyChange(prop.id, 'effectiveTaxRate', e.target.value)}
                        inputProps={{ min: 0, max: 100, step: 0.1 }}
                        sx={{ width: '60px' }}
                      />%
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        value={prop.netYield}
                        onChange={(e) => handlePropertyChange(prop.id, 'netYield', e.target.value)}
                        inputProps={{ min: 0, max: 100, step: 0.1 }}
                        sx={{ width: '60px' }}
                      />%
                    </TableCell>
                    <TableCell align="right">
                      <FormControl sx={{ minWidth: 120 }}>
                        <Select
                          value={prop.sellYear?.toString() || "Don't Sell"}
                          onChange={(e: SelectChangeEvent<string>) => handlePropertyChange(prop.id, 'sellYear', e.target.value)}
                        >
                          <MenuItem value="Don't Sell">Don't Sell</MenuItem>
                          {Array.from({ length: 2040 - 2024 + 1 }, (_, i) => 2024 + i).map((year) => (
                            <MenuItem key={year} value={year.toString()}>{year}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ height: '400px', marginTop: '20px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <ChartTooltip />
                <Legend />
                <Bar dataKey="Initial Value" fill="#8884d8" />
                <Bar dataKey="Projected Value" fill="#82ca9d" />
                <Bar dataKey="Passive Income" fill="#ffa726" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Assumptions</Typography>
            <TextField
              label="CGT Rate (%)"
              type="number"
              value={assumptions.cgtRate}
              onChange={(e) => handleAssumptionChange('cgtRate', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Passive Income Goal"
              type="number"
              value={assumptions.goalPassiveIncome}
              onChange={(e) => handleAssumptionChange('goalPassiveIncome', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="PPOR Loan"
              type="number"
              value={assumptions.pporLoan}
              onChange={(e) => handleAssumptionChange('pporLoan', e.target.value)}
              fullWidth
              margin="normal"
            />

            <GoalProgressSection />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PropertyDecisionTool;