import React, { useEffect, useRef, useState } from "react";

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import HomeIcon from '@mui/icons-material/Home';

import ScoreIcon from '@mui/icons-material/Score';
import BusinessIcon from '@mui/icons-material/Business';



import InfoIcon from '@mui/icons-material/Info';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Legend, ArcElement, } from "chart.js";
import {
  Typography, Grid, Card,

  Button,
  useTheme,
  Box,
  Tooltip,

} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePersonalInfo,
  updateBankingInfo,
} from "../../../features/userSlice";
import { Auth } from "aws-amplify";
import { RootState, AppDispatch } from "../../../store";
import { tokens } from "../../../theme";
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import AuthenticatedAPI from "../../../auth_helper";
import { BankingInfo } from "../../../types/User";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { apiKey, StreetViewImage } from "../../../hooks/properties/properties";

// import { Pdf } from "./pdf";
import { Pdf } from "../dashboard/pdf";
import { formatCurrency } from "../../../hooks/buyerAgent";



// import Investar from "./investar";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  // Tooltip,
  Legend,
  ArcElement
);

Amplify.configure(awsconfig);

interface Bankinginfo {
  bankingInfo: BankingInfo;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface UserStrategy {
  structure?: { investmentType: any };
  ownership: any;
  funding?: {
    lvr: any;
    canBuyAdditionalIp: any;
  };
  recommendations?: {
    growthYoy: any;
    purchasePriceBracket: any[];
    rentalYield: any[];
  };

  [key: string]: any;
}




interface Property {
  propertyId: number;
  address: string;
  capRate: number;
  classification: string;
  dscr: number;
  ltv: number;
  roi: number;
}
export interface ProfileData {
  financialGoal?: any[];

  familyInfo?: { dependentKids: any[]; earningMembers: any[] };
  employmentInfo?: any[];
  currentPortfolio?: { investmentProperties: any[] };
  investmentStrategy?: any;
  [key: string]: any;
}

// const PortfolioSummary = ({ user }: any, { bankingInfo }: Bankinginfo) => {


const PortfolioSummary: React.FC = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userProfile = useSelector(
    (state: RootState) => state.user.personalInfo
  );

  const userBankingInfo = useSelector<RootState, BankingInfo>(
    (state) => state.user.bankingInfo
  );
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const [userFirstName, setUserFirstName] = useState("");
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const [activeIndex, setActiveIndex] = React.useState("recommendation");
  const [activeSubIndex, setActiveSubIndex] = React.useState("growth");
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [investarScore, setInvestarScore] = React.useState(0);
  const [propertyImages, setPropertyImages] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<string[]>([])
  // console.log('address :>> ', address);
  const [portfolioData, setPortfolioData] = useState<Property[]>([]);
  const [userStrategy, setUserStrategy] = React.useState<UserStrategy | null>(
    null
  );

  // const [currentInvestor, setCurrentInvestor] = useState<Buyer | null>(null);
  const [buttonLabel, setButtonLabel] = useState("DOWNLOAD REPORTS");
  const [percentage, setPercentage] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const imageUrl = useStreetViewImage(address)
  // console.log('userBankingInfo', userBankingInfo)
  const auth = useSelector((state: RootState) => state.user.personalInfo);
  const meterImg = theme.palette.mode === 'dark' && investarScore <= 25 ? '/guage_d-01.png' :
    theme.palette.mode === 'dark' && investarScore >= 25 && investarScore <= 50 ? '/guage_d-02.png' :
      theme.palette.mode === 'dark' && investarScore >= 50 && investarScore <= 75 ? '/guage_d-03.png' :
        theme.palette.mode === 'dark' && investarScore >= 75 && investarScore <= 100 ? '/guage_d-04.png' :
          theme.palette.mode === 'light' && investarScore <= 25 ? '/guage_w-01.png' :
            theme.palette.mode === 'light' && investarScore >= 25 && investarScore <= 50 ? '/guage_w-02.png' :
              theme.palette.mode === 'light' && investarScore >= 50 && investarScore <= 75 ? '/guage_w-03.png' :
                theme.palette.mode === 'light' && investarScore >= 75 && investarScore <= 100 ? '/guage_w-04.png' : '';


  const handleSubTitle = (value: any) => {
    // console.log(value,'-----')
    setActiveSubIndex(value);
  };

  const location = useLocation()

  useEffect(() => {
    if (userProfile.id && location.pathname === '/login') {
      navigate('/dashboard')
    }
  }, [location.pathname, userProfile.id])
  const getPortFolioAnalysis = async () => {
    const data1 = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        "InvestmentAPI",
        "get",
        `/api/portfolio-analysis/${res.username}`
      );
      setPortfolioData(response.data)

    })
  }

  useEffect(() => {
    getPortFolioAnalysis();
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await Auth?.currentAuthenticatedUser();
      // console.log('user1111111', user)
      setUserFirstName(user.attributes.given_name);
    };
    getUserInfo();
  }, [userFirstName]);

  const openTransactionsPopup = (url: any) => {
    const consentUrl =
      userBankingInfo.consentUrl?.toString() || url?.toString();
    // Open the URL in a popup window
    if (consentUrl) {
      // window.open(transactionsUrl, '_blank', 'width=600,height=400,resizable=yes');
      window.location.href = consentUrl;
    } else {
      console.error("No consent URL found"); // Handle error case if needed
    }
  };




  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(user);
        const id =
          userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
        const email =
          userAttributes.find((attr) => attr.Name === "email")?.Value ?? "";
        const phone_number =
          userAttributes.find((attr) => attr.Name === "phone_number")?.Value ??
          "";

        const first_name =
          userAttributes.find((attr) => attr.Name === "given_name")?.Value ??
          "";
        const last_name =
          userAttributes.find((attr) => attr.Name === "family_name")?.Value ??
          "";

        dispatch(
          updatePersonalInfo({
            id,
            email,
            phone_number,

            first_name,
            last_name,
          })
        );
        fetchUserBankDetails(id);
        setUserFirstName(first_name);
        getUserProfile();

      } catch (error) {
      }
    };

    fetchUserData();
  }, [dispatch]);



  const fetchUserBankDetails = async (id: any) => {
    try {
      const response = await AuthenticatedAPI.request(
        "FinanceAPI",
        "post",
        `/banking/${id}`,
        {
          // body: {
          //   userId: userProfile.personalInfo.id,
          //   profile: userProfile,
          // },
        }
      );
      // console.log('response', response)
      // if (!response.ok) {
      //   throw new Error('Failed to save user profile');
      // }

      // Handle successful submissions

      dispatch(
        updateBankingInfo({
          consent: response.consent[0],
          accounts: response.accounts.data,
          consentUrl: response.consentUrl,
        })
      );
    } catch (error) {
      console.log("Error saving user profile:", error);
      // Handle error state
    }
  };



  const getUserProfile = async () => {
    try {
      const userData = await Auth?.currentAuthenticatedUser().then(
        async (res) => {
          const response = await AuthenticatedAPI.request(
            "InvestmentAPI",
            "get",
            `/api/profile/${res.username}`
          );

          if (!response) {
            setLoading(false);
            throw new Error("Failed to fetch user profile");
          }
          if (response) {
            setProfileData(response.data);
            setInvestarScore(response.data.investarScore);
            setLoading(false);
          }
          return response;
        }
      );
    } catch (error) {
      setLoading(false);
      console.log("Error fetching user profile:", error);
    }
  };



  function calculateCompletionPercentage(profileData: any) {
    const hasCurrentPortfolio =
      profileData?.currentPortfolio?.investmentProperties?.length > 0;
    const hasEmploymentInfo =
      profileData?.employmentInfo?.employmentInfo?.length > 0;
    const hasExpenses = profileData?.expenses?.expenses?.length > 0;
    const hasFamilyInfo =
      profileData?.familyInfo?.dependentKids?.length > 0 &&
      profileData.familyInfo?.earningMembers?.length > 0;
    const hasFinancialGoal =
      profileData?.financialGoal?.financialGoal?.length > 0;

    const checks = [
      hasCurrentPortfolio,
      hasEmploymentInfo,
      hasExpenses,
      hasFamilyInfo,
      hasFinancialGoal,
    ];
    const falseCount = checks?.filter(value => !value).length;
    const percentage = (checks.length - falseCount) / checks.length * 100;

    setPercentage(percentage);
    return percentage;
  }

  useEffect(() => {
    calculateCompletionPercentage(profileData);
  }, [profileData]);

  //Property Growth
  const investmentProperties =
    profileData?.currentPortfolio?.investmentProperties || [];


  const data = investmentProperties.map(
    (property: any) =>
      property.currentestimatedvalue - property.initialpurchaseprice || 0
  );
  const PropertyNames = investmentProperties.map(
    (property: any) =>
      property.address || ""
  );
  const propertyName = PropertyNames.map(name => name.split(' ').slice(0, 2).join(' '));
  const totalLoan = investmentProperties.map(
    (loan: any) => loan.currentloanamount || 0
  );

  const netRentalYields = investmentProperties.map(
    (property: any) => (property.weeklyrent * 52) / property.initialpurchaseprice * 100
  );

  // Borrowable Equity
  const borrowableEquities = investmentProperties.map((property: any) => {

    console.log("borrowableEquities", investmentProperties)
    const currentEstimatedValue = parseFloat(property.currentestimatedvalue) || 0;
    const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
    return currentEstimatedValue * 0.8 - currentLoanAmount;
  });

  console.log("investmentProperties", investmentProperties)
  const totalValue = investmentProperties?.reduce((accumulator, x) => {

    return accumulator + parseFloat(x.currentestimatedvalue);
  }, 0) || 0;

  const toatlEquity = borrowableEquities.reduce(
    (total, value) => total + value,
    0
  );
  const totalYield = investmentProperties?.reduce((accumulator, x) => {

    return accumulator + parseInt(x.netrentalyield)
  }, 0) || 0;
  const averageYield = profileData?.currentPortfolio ? totalYield / investmentProperties.length : 0;
  const toatlLoanAmount = totalLoan.reduce(
    (total, value) => total + Number(value),
    0
  );


  const total = toatlEquity + toatlLoanAmount;
  const Equitypercente = ((toatlEquity / total) * 100).toFixed(2);
  const Debtpercente = ((toatlLoanAmount / total) * 100).toFixed(2);



  useEffect(() => {
    if (profileData?.currentPortfolio?.investmentProperties) {
      const newAddresses = profileData.currentPortfolio.investmentProperties.map(val => val.address);
      setAddresses(newAddresses);

    }
  }, [profileData]);
  const img = StreetViewImage(addresses)
  useEffect(() => {
    if (img) {
      setPropertyImages(img)
    }
  }, [propertyImages, img])


  useEffect(() => { }, [
    toatlEquity,
    Equitypercente,
    Debtpercente,
    toatlLoanAmount,
  ]);

  const handleUpdateStratgy = async () => {
    const userDatastart = await Auth?.currentAuthenticatedUser().then(
      async (res) => {
        const response = await AuthenticatedAPI.request(
          "InvestmentAPI",
          "put",
          `/api/strategy/update/${res.username}`
        );
        if (!response) {
          throw new Error("Failed to fetch user profile");
        }
      }
    );
  };
  React.useEffect(() => {
    if (userStrategy && !userStrategy) {
      handleUpdateStratgy();
    }
  }, [userStrategy]);



  return (
    <>
      <Grid item md={12}>
        <Card
          sx={{
            padding: "20px !important",

            marginBottom: "0px !important",
            borderRadius: "15px !important",
            boxShadow: "none !important",
            backgroundColor: colors.primary[400],
          }}
        >
          {location.pathname !== "/portfolio" && (
            <Typography
              variant="h4"
              align="left"
              pb={2}
              pt={3}
              zIndex={1}
              gutterBottom
              sx={{
                fontWeight: "700",
              }}
            >
              Portfolio Summary
            </Typography>
          )}
          {location.pathname == "/portfolio" && (
            <Typography
              variant="h4"
              align="left"
              pb={2}
              pt={3}
              zIndex={1}
              gutterBottom
              sx={{
                fontWeight: "700",
              }}
            >
              Summary
            </Typography>
          )}

          <ul className="profileBox_summarylist">
            <li>
              <Box className="profile_box">
                <Box sx={{
                  flex: "100% !important",
                  maxWidth: "100% !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{
                      color: colors.grey[100],
                      fontSize: "14px",
                      lineHeight: 1,
                      padding: "10px 28px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography color='#6870fa' textAlign='center'><ScoreIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                    <Typography fontWeight="700" textAlign='center' paddingTop='10px'>{profileData?.investarScore ?? 0}</Typography>
                    <Typography fontWeight="500" textAlign='center' paddingTop='10px'>Investar Score</Typography>
                  </Typography>
                </Box>
              </Box>
            </li>
            <li>
              <Box className="profile_box">
                <Box sx={{
                  flex: "100% !important",
                  maxWidth: "100% !important",
                  display: "flex", // To enable flexbox
                  flexDirection: "column", // Aligns items vertically
                  alignItems: "center", // Centers items horizontally
                  justifyContent: "center",
                }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{
                      color: colors.grey[100],
                      fontSize: "14px",
                      lineHeight: 1,
                      padding: "10px 28px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography color='#4cceac' textAlign='center' ><HomeIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                    <Typography fontWeight="900" textAlign='center' paddingTop='10px'>{portfolioData?.length}</Typography>
                    <Typography fontWeight="500" paddingTop='10px'>Properties Owned</Typography>
                  </Typography>
                </Box>
              </Box>
            </li>
            <li>
              <Box className="profile_box">
                <Box sx={{
                  flex: "100% !important",
                  maxWidth: "100% !important",
                  display: "flex", // To enable flexbox
                  flexDirection: "column", // Aligns items vertically
                  alignItems: "center", // Centers items horizontally
                  justifyContent: "center",
                }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{
                      color: colors.grey[100],
                      fontSize: "14px",
                      lineHeight: 1,
                      padding: "10px 28px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography color='#6870fa' textAlign='center'><AttachMoneyIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                    <Typography fontWeight="700" textAlign='center' paddingTop='10px'>${formatCurrency(totalValue)}</Typography>
                    <Typography fontWeight="500" textAlign='center' paddingTop='10px'>Total Value</Typography>
                  </Typography>
                </Box>
              </Box>
            </li>
            <li>
              <Box className="profile_box">
                <Box sx={{
                  flex: "100% !important",
                  maxWidth: "100% !important",
                  display: "flex", // To enable flexbox
                  flexDirection: "column", // Aligns items vertically
                  alignItems: "center", // Centers items horizontally
                  justifyContent: "center",
                }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{
                      color: colors.grey[100],
                      fontSize: "14px",
                      lineHeight: 1,
                      padding: "10px 28px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography color='#141414' textAlign='center'><PercentIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                    <Typography fontWeight="700" textAlign='center' paddingTop='10px'>{averageYield.toFixed(0)}</Typography>
                    <Typography fontWeight="500" textAlign='center' paddingTop='10px'> Average Yield</Typography>
                  </Typography>
                </Box>
              </Box>
            </li>
            <li>
              <Box
                className={`${toatlEquity >= 70000 ? "gradient-border-ba-dash" : "profile_box"}`}
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{
                      color: colors.grey[100],
                      fontSize: "14px",
                      lineHeight: 1,
                      padding: "10px 28px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography textAlign='center' color='#2e7d32'><BusinessIcon sx={{ height: '30px !important', width: '40px !important' }} /></Typography>
                    <Typography className="pt-5" fontWeight="600">
                      Borrowable Equity: ${toatlEquity.toFixed(0)}
                      {toatlEquity >= 70000 && (
                        <>
                          <Tooltip title={`Your Portfolio Borrowable Equity is ${toatlEquity.toFixed(0)}`}>
                            <InfoIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        </>
                      )}
                      {/* <Tooltip title="Send email to investor">
                                <IconButton
                                  onClick={() => handleOpenEmailModal(buyerAgentData)}
                                  sx={{ ml: 1 }}
                                >
                                  <EmailIcon />
                                </IconButton>
                              </Tooltip> */}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </li>
          </ul>
        </Card>
      </Grid>



    </>
  );
};
export default PortfolioSummary;