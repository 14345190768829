import React, { useEffect, useState } from 'react';
import { TextField, Tooltip, Box, Typography, Card, useTheme, IconButton } from '@mui/material';
import { tokens } from '../../../../theme';
import InfoIcon from '@mui/icons-material/Info';
import { EmploymentIncomeData } from '../../../../types/CashFlow';
import { updateEmployementIncome } from '../../../../features/cashFlow';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
interface IncomeState {
    employmentIncome: string;
    otherTaxableIncome: string;

}
interface EmploymentIncomeState {

    incomeDetails?: EmploymentIncomeData | null;
    agentProfile?: any;
    investarData?: any;
}

const Income: React.FC<EmploymentIncomeState> = ({ incomeDetails, agentProfile, investarData }) => {
    console.log('investarData', investarData)
    console.log('incomeDetails', incomeDetails)
    const cashFlowAnalysis = useSelector(
        (state: RootState) => state.cashFlowAnalysis
    );
    console.log('cashFlowAnalysis1246', cashFlowAnalysis)
    const [employmentIncome, setEmploymentIncome] = useState<string>('');
    const [otherTaxableIncome, setOtherTaxableIncome] = useState<string>('');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    // Handler for employment income
    const handleEmploymentIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value // Remove non-numeric characters
        const formatted = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(Number(value) || 0);

        setEmploymentIncome(value);
        dispatch(updateEmployementIncome({
            grossFamilyIncome: Number(value),
            otherTaxableIncome: Number(otherTaxableIncome)
        }));
    };

    // Handler for other taxable income
    const handleOtherTaxableIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value; // Remove non-numeric characters
        const formatted = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(Number(value) || 0);

        setOtherTaxableIncome(value);
        dispatch(updateEmployementIncome({
            grossFamilyIncome: Number(employmentIncome),
            otherTaxableIncome: Number(value)
        }));
    };

    useEffect(() => {
        if (incomeDetails) {
            setEmploymentIncome(incomeDetails.grossFamilyIncome?.toString());
            setOtherTaxableIncome(incomeDetails.otherTaxableIncome?.toString());
        }
        // else {
        //     setEmploymentIncome(investarData.employmentInfo?.grossFamilyIncome?.toString());
        //     setOtherTaxableIncome(incomeDetails.otherTaxableIncome?.toString());
        // }
    }, [agentProfile, incomeDetails]);

    return (
        <div className="overflow-x-auto flex flex-col">
            <Card
                sx={{
                    padding: "18px 20px 20px 20px !important",
                    marginBottom: "30px !important",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                    backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
                }}
            >
                <div>
                    <Typography variant="h6" gutterBottom sx={{ pb: 2 }}>
                        Employment Income
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                // label="Gross Family Income "
                                label={
                                    <Box display="flex" alignItems="center">
                                        Gross Family Income
                                        <Tooltip title="Additional information about Gross Family Income">
                                            <IconButton size="small">
                                                <InfoIcon color="primary" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                }
                                value={employmentIncome}
                                onChange={handleEmploymentIncomeChange}
                                InputProps={{
                                    startAdornment: "$",
                                }}
                                fullWidth
                                variant="outlined"
                            />
                            {/* <Tooltip
                                title="Your annual employment income after salary sacrifice, before tax"
                                arrow
                                placement="right"
                            >
                                <IconButton size="small">
                                    <InfoIcon color="primary" fontSize="small" />
                                </IconButton>
                            </Tooltip> */}
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                // label="Other Taxable Income"
                                label={
                                    <Box display="flex" alignItems="center">
                                        Other Taxable Income
                                        <Tooltip title="Additional information about Gross Family Income">
                                            <IconButton size="small">
                                                <InfoIcon color="primary" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                }
                                value={otherTaxableIncome}
                                InputProps={{
                                    startAdornment: "$",
                                }}

                                onChange={handleOtherTaxableIncomeChange}
                                fullWidth
                                variant="outlined"
                            />
                            {/* <Tooltip
                                title="Other total taxable income per year"
                                arrow
                                placement="right"
                            >
                                <IconButton size="small">
                                    <InfoIcon color="primary" fontSize="small" />
                                </IconButton>
                            </Tooltip> */}
                        </Box>
                    </Box>
                </div>
            </Card>
        </div>
    );
};

export default Income;