import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,

} from "react-router-dom";
import Layout from "./views/pages/layout";
import MultiStepForm from "./views/pages/multiForm";
import UserIntrest from './views/pages/userIntrest'
import DashboardContent, { ProfileData } from "./views/pages/dashboard";
import Scoreboard from "./views/pages/scoreboard";
import Portfolio from "./views/pages/investmentPortfolio";
import Map from "./views/pages/map";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { RootState } from "./store";
import BuyAndSell from "./views/pages/buySellInput";
import PropertyResultsPage from "./views/pages/propertyModellingResults/index";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import CashFlowAnalysis from "./views/pages/cashFlowAnalysis";
import HomePage from "./views/pages/home";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./views/components/topbar";
import Sidebar from "./views/components/sidebar/Sidebar";
import BuySellReporting from "./views/pages/buy-sell-reporting";
import PrivateRoute from "./privateRoute";
import { useSelector } from "react-redux";
import Properties from "./views/pages/Properties";
import PropertyDetails from "./views/pages/Properties/PropertiesDetails";
import Advisor from './views/pages/advisor'
import AdvisorCreate from './views/pages/advisor/AdvisorForm'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DisclaimerPage from "./views/pages/disclaimer";
import TermsModalWrapper from "./views/components/termsModal/wrapper";
import BuyersAgentDashboard from "./views/pages/buyersAgentDashboard/index";
import BuyersAgentDashboards from "./views/pages/buyersAgentDashboard/DashBoard";



import TimelineRecommendation from "./views/pages/timelineRecommendation";
import PropertyDecisionTool from "./views/pages/InvestNetDecision";
import { Box, CircularProgress } from "@mui/material";
import SearchPage from "./views/pages/buyersAgentDashboard/SearchPage";
import Index from "./views/pages/buyersAgentDashboard/index";
import AuthenticatedAPI from "./auth_helper";
import PricingPage from "./views/pages/pricing";
import BuyerAgentProfilePage from "./views/pages/BuyersAgentProfile";
import BuyersAgentSignup from "./views/pages/BuyersAgentSignup";
import AddInvestor from "./views/pages/AddInvestor";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Authenticator } from "@aws-amplify/ui-react";
import convertToSubcurrency from "./hooks/buyerAgent";


Amplify.configure(awsconfig);

function App() { 
  const { theme, colorMode } = useMode();
  const auth = useSelector((state: RootState) => state.user.personalInfo);
  console.log('auth', auth)
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  console.log('profileData :>> ', profileData);
  console.log(userGroup,"userGroup111111")
  const [loading, setLoading] = useState(true);
  // const locations = useLocation();
  
  // const authId = profileData.personalInfo.personalInfo.map((elem:any) => elem.id)

  // const getUserProfile = async () => {
  //   try {
      
  //     const userData = await Auth?.currentAuthenticatedUser().then(
  //       async (res) => {
  //         const response = await AuthenticatedAPI.request(
  //           "InvestmentAPI",
  //           "get",
  //           `/api/profile/${res.username}`
  //         );
  //         console.log(response,"respo")
  //         if (!response) {
  //           setLoading(false);
  //           throw new Error("Failed to fetch user profile");
  //         }
  //         if (response) {
  //           setProfileData(response.data);
  //         }
  //         return response;
  //       }
  //     );
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Error fetching user profile:", error);
  //   }
  // };

  // useEffect(() => {
  //   getUserProfile()
  // }, [])
  
  const getUserProfile = async () => {
    try {
      // setLoading(true); // Set loading state at the start
  
      const currentUser = await Auth.currentAuthenticatedUser();
      
      const response = await AuthenticatedAPI.request(
        "InvestmentAPI",
        "get",
        `/api/profile/${currentUser.username}`
      );
  
      if (!response) {
        throw new Error("Failed to fetch user profile");
      }
  
      setProfileData(response.data); // Assuming you want to set the profile data here
    } catch (error) {
      console.log("Error fetching user profile:", error);
    } finally {
      setLoading(false); // Ensure loading state is turned off after the operation
    }
  };
  
  useEffect(() => {
    getUserProfile();
  }, []);
  


  useEffect(() => {
    const checkUserGroup = async () => {
      

      if (auth?.id) {
        
        try {
          const user = await Auth.currentAuthenticatedUser();
          const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
          setUserGroup(groups.includes('InvestarPartner') ? 'InvestarPartner' : 'REGULAR');
        } catch (error) {
          
          console.error('Error checking user group:', error);
          // setUserGroup('REGULAR');
        }
      }
      setLoading(false);
    };

    checkUserGroup();
  }, [auth,userGroup,profileData]);

  if (loading) {
    return  
    <>
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <CircularProgress color="secondary" />
  </Box>; // Or your loading component
  </>
  }
  const location = window.location.pathname;
  const hideTopbarSidebarRoutes = ["/", "/terms", "/interests"];
  

  // live stripe key
  const stripePromise = loadStripe('pk_live_51PFXSvCYdLzPHpqCCVSvcWjOfzFb7hjqYDVv5kEz5VGyP2vS1EbuAJhT8XanPKfzjr4AzuUMKWMznDGWpdNJEnXp006U0uEKyv');

  //testing  stripe key
  // const stripePromise = loadStripe('pk_test_51PFXSvCYdLzPHpqCrY7l1SYTr5Xym3MuMk0JDQ4T1pzZmLkcEsapJEp0wg2tSTYhmiHAufON5Or5L81hiL1WFgER00JyuTNOsG');

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          
            <Router>
            {!hideTopbarSidebarRoutes.includes(location) && (profileData || auth?.id) && (
          
              <>
          
                <Sidebar />
                    </>
            )}
       
            <div className="wrapper">
              
              <main className="content">
                {!hideTopbarSidebarRoutes.includes(location) && (profileData || auth.id ) && (
               
                <>
                  <Topbar />
                </>
                )}
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/terms" element={<DisclaimerPage />} />
                  <Route path="/interests" element={<UserIntrest />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/ba-signup" element={<Authenticator.Provider><BuyersAgentSignup /></Authenticator.Provider>} />
                  <Route path="/buy-sell-modelling" element={<PropertyResultsPage />} />
                  <Route path="/buy-sell-reporting" element={<BuySellReporting />} />
                  <Route element={<PrivateRoute />}>
                    {userGroup && userGroup === 'InvestarPartner' ? (
                      <>
                      AddInvestor
                        <Route path="/ba-dashboard" element={<BuyersAgentDashboard />} />
                        {/* <Route path="/add-investors" element={<BuyersAgentDashboards />} /> */}
                        <Route path="/ba-profile" element={<BuyerAgentProfilePage />} />
                        <Route path="/add-investor" element={<Elements stripe={stripePromise}>
                       
                        
    <AddInvestor />
  </Elements>} />


                        <Route path="/ba-search" element={<Index />} />
                        <Route path="*" element={<Navigate to="/ba-dashboard" replace />} />
                      </>
                    ) : (
                      <>
                        <Route path="/dashboard" element={<TermsModalWrapper><DashboardContent /></TermsModalWrapper>} />
                        <Route path="/login" element={<TermsModalWrapper><DashboardContent /></TermsModalWrapper>} />
                        <Route path="/goal-setting" element={<TermsModalWrapper><MultiStepForm /></TermsModalWrapper>} />
                        <Route path="/scoreboard" element={<TermsModalWrapper><Scoreboard /></TermsModalWrapper>} />
                        <Route path="/portfolio" element={<TermsModalWrapper><Portfolio /></TermsModalWrapper>} />
                        <Route path="/buy-sell-reporting" element={<TermsModalWrapper><BuySellReporting /></TermsModalWrapper>} />
                        <Route path="/advisor" element={<TermsModalWrapper><Advisor /></TermsModalWrapper>} />
                        <Route path="/strategy" element={<TermsModalWrapper><TimelineRecommendation /></TermsModalWrapper>} />
                        <Route path="/decision" element={<TermsModalWrapper><PropertyDecisionTool /></TermsModalWrapper>} />
                        <Route path="/advisor/create/:type" element={<AdvisorCreate />} />
                        <Route path="/advisor/edit/:id" element={<AdvisorCreate />} />
                        <Route path="/map" element={<Map />} />
                        <Route path="/buy-sell" element={<BuyAndSell buyerAgent={''} />} />
                        <Route path="/buy-sell-modelling" element={<PropertyResultsPage />} />
                        <Route path="/cashflow" element={<CashFlowAnalysis agentProfile={false} />} />
                        <Route path="/properties" element={<Properties />} />
                        <Route path="/property-details" element={<PropertyDetails />} />
                        <Route path="*" element={<Navigate to="/dashboard" replace />} />
                      </>
                    )}
                  </Route>
                </Routes>
              </main>
            </div>
          </Router>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;




// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from "react-router-dom";
// import Layout from "./views/pages/layout";
// import MultiStepForm from "./views/pages/multiForm";
// import UserIntrest from './views/pages/userIntrest'
// import DashboardContent from "./views/pages/dashboard";
// import Scoreboard from "./views/pages/scoreboard";
// import Portfolio from "./views/pages/investmentPortfolio";
// import Map from "./views/pages/map";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// import { RootState } from "./store";
// import BuyAndSell from "./views/pages/buySellInput";
// import PropertyResultsPage from "./views/pages/propertyModellingResults";
// import awsconfig from "./aws-exports";
// import "@aws-amplify/ui-react/styles.css";
// import { Amplify, Auth } from "aws-amplify";
// import CashFlowAnalysis from "./views/pages/cashFlowAnalysis";
// import HomePage from "./views/pages/home";
// import { ColorModeContext, useMode } from "./theme";
// import Topbar from "./views/components/topbar";
// import Sidebar from "./views/components/sidebar/Sidebar";
// import BuySellReporting from "./views/pages/buy-sell-reporting";
// import PrivateRoute from "./privateRoute";
// import { useSelector } from "react-redux";
// import Properties from "./views/pages/Properties";
// import PropertyDetails from "./views/pages/Properties/PropertiesDetails";
// import Advisor from './views/pages/advisor'
// import AdvisorCreate from './views/pages/advisor/AdvisorForm'
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import DisclaimerPage from "./views/pages/disclaimer";
// import TermsModalWrapper from "./views/components/termsModal/wrapper";
// import BuyersAgentDashboard from "./views/pages/buyersAgentDashboard";
// import TimelineRecommendation from "./views/pages/timelineRecommendation";

// Amplify.configure(awsconfig);

// function App() {
//   const { theme, colorMode } = useMode();
//   const auth = useSelector((state: RootState) => state.user.personalInfo);
//   const [userGroup, setUserGroup] = useState<string | null>(null);
//   const [loading, setLoading] = useState(true);
//   const [userID,setUserId]=useState()

//   useEffect(() => {
//     const checkUserGroup = async () => {
//         try {
//           const user = await Auth.currentAuthenticatedUser();
//           const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
//           setUserGroup(groups.includes('InvestarPartner') ? 'InvestarPartner' : 'REGULAR');
//           setUserId(user.username)
//         } catch (error) {
//           console.error('Error checking user group:', error);
//           // setUserGroup('REGULAR');
//         }
//       }
//       setLoading(false);
 

//     checkUserGroup();
//   }, [auth.id]);

//   if (loading) {
//     return <div>Loading...</div>; // Or your loading component
//   }
//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ToastContainer />
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <div className="app">
//           <Router>
//             {userID && userGroup !== 'InvestarPartner' ? <Sidebar />:''}
//             {userID && userGroup === 'InvestarPartner' ? <Sidebar />:''}
            

//             <div className="wrapper">
//               <main className="content">
//                 {userID && userGroup !== 'InvestarPartner' && <Topbar />}
//                 {userID && userGroup == 'InvestarPartner' && <Topbar />}

//                 <Routes>
//                   <Route path="/" element={<HomePage />} />
//                   <Route path="/terms" element={<DisclaimerPage />} />
//                   <Route path="/interests" element={<UserIntrest />} />
//                   <Route element={<PrivateRoute />}>
//                     {userGroup === 'InvestarPartner' ? (

//                       <>

//                         <Route path="/ba-dashboard" element={<BuyersAgentDashboard />} />
//                         <Route path="*" element={<Navigate to="/ba-dashboard" replace />} />
//                       </>
//                     ) : (
//                       <>
//                         <Route path="/dashboard" element={<TermsModalWrapper><DashboardContent /></TermsModalWrapper>} />
//                         <Route path="/goal-setting" element={<TermsModalWrapper><MultiStepForm /></TermsModalWrapper>} />
//                         <Route path="/scoreboard" element={<TermsModalWrapper><Scoreboard /></TermsModalWrapper>} />
//                         <Route path="/portfolio" element={<TermsModalWrapper><Portfolio /></TermsModalWrapper>} />
//                         <Route path="/buy-sell-reporting" element={<TermsModalWrapper><BuySellReporting /></TermsModalWrapper>} />
//                         <Route path="/advisor" element={<TermsModalWrapper><Advisor /></TermsModalWrapper>} />
//                         <Route path="/strategy" element={<TermsModalWrapper><TimelineRecommendation/></TermsModalWrapper>} />       
//                         <Route path="/advisor/create/:type" element={<AdvisorCreate />} />
//                         <Route path="/advisor/edit/:id" element={<AdvisorCreate />} />
//                         <Route path="/map" element={<Map />} />
//                         <Route path="/buy-sell" element={<BuyAndSell />} />
//                         <Route path="/buy-sell-modelling" element={<PropertyResultsPage />} />
//                         <Route path="/cashflow" element={<CashFlowAnalysis />} />
//                         <Route path="/properties" element={<Properties />} />
//                         <Route path="/property-details" element={<PropertyDetails />} />
//                         <Route path="*" element={<Navigate to="/dashboard" replace />} />
//                       </>
//                     )}
//                   </Route>
//                 </Routes>
//               </main>
//             </div>
//           </Router>
//         </div>
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
// }

// export default App;