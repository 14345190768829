import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  useTheme,
  Card,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  debounce,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { AcquisitionCostsData, CashFlowProjectionData, PropertyDetailsData } from "../../../../types/CashFlow";
import { tokens } from "../../../../theme";
import { useFetchProperties, useFetchpropertyavm, Result, getLmiRate, StampDuty } from "../../../../hooks/properties/properties";
import { Field, Formik } from "formik";
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from "react-redux";
import { updateAcquisitionCosts, updateCashFlowProjection, updatePropertyDetails, propertyDetailsAddress } from "../../../../features/cashFlow";
import Switch from '@mui/material/Switch';
import swal from 'sweetalert2'
import Swal from "sweetalert2";
import { RootState } from "../../../../store";
interface PropertyDetailsProps {
  propertyDetails: PropertyDetailsData | null;
  agentProfile: any;
  selectedProperty?: any | null;
  hideCashFlow?: boolean

  // Make this prop optional

  // updatePropertyDetails: (updates: Partial<PropertyDetailsData>) => void;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  propertyDetails, agentProfile, selectedProperty, hideCashFlow
  // updatePropertyDetails,
}) => {
  const label = { inputProps: { 'aria-label': 'Color switch demo' } };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [addres, setAddress] = useState('');
  const [propertyID, setPropertyID] = useState('');
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const [propertyIDS, setPropertyIDS] = useState<string[]>([]);
  // const [currentEstimatedValue, setCurrentEstimatedValue] = useState<string[]>([]);
  const [currentEstimatedValue, setCurrentEstimatedValue] = useState<number | "">('');
  const [toggleSwitch, setToggleSwitch] = useState(false)
  console.log(currentEstimatedValue, "currentEstimatedValue")
  const [dbCurrentEstimatedValue, setDBCurrentValue] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [rentalYield, setRentalYield] = useState<string | null>(null);
  const [addedLoanAmount, setAddedLoanAMount] = useState<string | null>(null);
  const [LVR, setLVR] = useState<string | null>(null);
  const [LoanTerm, setLoanTerm] = useState<string | null>(null);
  const [interestRate, setInterestRate] = useState<string | null>(null);
  const [stampValue, setStampValue] = useState<number | null>(null);
  // const fetchresult = useFetchProperties(address);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: boolean }>({});
  const { propertyData, propertyLoading, error } = useFetchProperties(addres);
  const { propertyavmdata, propertyavmloading, propertyavmerror } = useFetchpropertyavm(propertyID);
  console.log("propertyData111", propertyData)
  const [isAutocompleteActive, setIsAutocompleteActive] = useState<number | null>(null);
  // const [localLoanAmount, setLocalLoanAmount] = useState(propertyDetails?.loanAmount || "");
  const [originalLoanAmount, setOriginalLoanAmount] = useState<number | null>(null);
  const [weeklyRent, setWeeklyRent] = useState<number | null>(null);
  const cashFlowAnalysis = useSelector((state: RootState) => state.cashFlowAnalysis);
  const toggleAutocomplete = (index: number) => {
    // 
    if (isAutocompleteActive !== index && selectedAddresses) {
      setIsAutocompleteActive((prev) => (prev === index ? null : index));
    }
  };
  const [localLoanAmount, setLocalLoanAmount] = useState(
    selectedProperty?.data[0].currentloanamount || ""
  );

  // Ensure `localLoanAmount` is updated only once on `selectedProperty` change
  useEffect(() => {
    if (!localLoanAmount && selectedProperty?.data[0].currentloanamount) {
      setLocalLoanAmount(selectedProperty.data[0].currentloanamount);
    }
  }, [selectedProperty, localLoanAmount]);
  const dispatch = useDispatch()
  const handleChange = useCallback(
    (field: keyof PropertyDetailsData, value: string | number) => {
      if (propertyDetails?.[field] !== value) {
        const values = { [field]: value };
        dispatch(updatePropertyDetails(values));

      }
    },
    [propertyDetails, dispatch]
  );
  // const handleChange = useCallback(
  //   debounce((field: keyof PropertyDetailsData, value: string | number) => {
  //     if (propertyDetails?.[field] !== value) {
  //       const values = { [field]: value };
  //       dispatch(updatePropertyDetails(values));
  //     }
  //   }, 300),
  //   [propertyDetails, dispatch]
  // );
  const HandleInput = (value: string) => {

    setAddress(value);


  };

  useEffect(() => {
    handleBlur(1)
  }, [selectedProperty?.data])
  const handleBlur = (e: any) => {

    if (localLoanAmount || selectedProperty?.data[0]?.currentloanamount) {

      let valueLvr = localLoanAmount != '' ? localLoanAmount : selectedProperty.data[0].currentloanamount
      let valueProperty = propertyDetails?.propertyValue != 0 ? propertyDetails?.propertyValue : selectedProperty.data[0].currentestimatedvalue
      const res = calculateLVR(Number(valueProperty), Number(valueLvr))
      setLVR(res.toFixed(0))
      handleChange("loanAmount", Number(localLoanAmount));
      handleChange('lvr', res.toFixed(0))
    }
  };

  const handleLVRBlur = (e: any) => {
    if (LVR) {
      let valueProperty = propertyDetails?.propertyValue != 0 ? propertyDetails?.propertyValue : selectedProperty.data[0].currentestimatedvalue
      const res = calculateLoanAmount(Number(valueProperty), Number(LVR))
      setLocalLoanAmount(res.toFixed(0))
      handleChange("loanAmount", Number(res.toFixed(0)));
      handleChange('lvr', LVR)
    }
  };
  // useEffect(()=>{
  // if(propertyavmdata){
  //   handleChange('propertyValue', propertyavmdata.valuation.value)
  // }
  // },[])

  useEffect(() => {
    if (weeklyRent && (propertyDetails?.propertyValue || currentEstimatedValue)) {

      const Rentalyield = (Number(weeklyRent) * 52 / Number(propertyDetails?.propertyValue || currentEstimatedValue) * 100)
      // const NetrentakYield = Rentalyield?.toFixed(2)
      setRentalYield(Rentalyield?.toFixed(2))

      if (rentalYield && rentalYield) {
        handleChange('grossYield', Rentalyield?.toFixed(2))
      }
    }
  }, [rentalYield, weeklyRent, currentEstimatedValue,])


  const handleSelect = (event: any, value: Result | null, index: any) => {
    console.log(value, "valuesss")
    if (value) {
      const newSelectedAddress: any = `${value.match.address.streetAddress} ${value.match.address.locality} ${value.match.address.state} ${value.match.address.postcode}`;
      dispatch(propertyDetailsAddress(newSelectedAddress))
      setSelectedAddresses(prevAddresses => [...prevAddresses, newSelectedAddress]);
      setSelectedState(value.match.address.state)
      setSelectedAddress(newSelectedAddress);
      if (selectedAddresses) {
        setPropertyID(value.match.id)
        setPropertyIDS(prevID => {
          // Check if the value already exists in the state
          if (!prevID.includes(value.match.id)) {
            return [...prevID, value.match.id];
          }
          return prevID;
        });
        setSelectedOptions(prevOptions => ({
          ...prevOptions,
          [index]: true
        }));
      }
    }
  };

  const handleUpdateStampDuty = useCallback(
    (field: keyof AcquisitionCostsData, value: string | number) => {
      // if (stampValue) {
      const values = { [field]: value };

      dispatch(updateAcquisitionCosts(values));
      // }
    },
    [dispatch]
  );
  const handleUpdateDeposit = useCallback(
    (field: keyof AcquisitionCostsData, value: string | number) => {
      // if (stampValue) {
      const values = { [field]: value };

      dispatch(updateAcquisitionCosts(values));
      // }
    },
    [dispatch]
  );
  useEffect(() => {
    if (propertyavmdata) {


      !agentProfile ? setCurrentEstimatedValue(propertyavmdata?.valuation?.value) : setCurrentEstimatedValue('')
      handleChange('propertyValue', propertyavmdata?.valuation?.value)
      const Rentalyield = (Number(propertyDetails?.estimatedWeeklyRent) * 52 / Number(currentEstimatedValue) * 100)
      // const NetrentakYield = Rentalyield?.toFixed(2)
      setRentalYield(Rentalyield?.toFixed(2))

      if (rentalYield && rentalYield) {
        handleChange('grossYield', Rentalyield?.toFixed(2))
      }
      const stampValue = StampDuty(selectedState as any, Number(!agentProfile && propertyavmdata?.valuation?.value))

      setStampValue(Number(stampValue.toFixed(0)))
      handleChange('stampDuty', stampValue.toFixed(0))
      handleUpdateStampDuty('stampDuty', stampValue.toFixed(0))


    }
  }, [agentProfile, propertyavmdata]);

  const calculateGrossYield = () => {
    if (currentEstimatedValue && weeklyRent) {
      const grossYield = (weeklyRent * 52) / currentEstimatedValue * 100;
      handleChange('grossYield', grossYield.toFixed(2));
    }
  };
  const calculateLVR = (propertyValue: number, loan: number): number => {
    const loanAmount = Number(loan);
    const propertyVal = Number(propertyValue);
    return (loanAmount / propertyVal) * 100;
  };

  const calculateLoanAmount = (propertyValue: number, lvr: number): number => {
    return (lvr / 100) * propertyValue;
  };


  const HandleLMI = (e: any) => {
    if (e === 'addLMI') {
      const rateRes = getLmiRate(Number(propertyDetails && propertyDetails.lvr))

      if (rateRes) {
        const updatedLoanAmount = Number(propertyDetails?.loanAmount) * (1 + rateRes / 100)
        setLocalLoanAmount(updatedLoanAmount.toFixed(0))
        const fixedLoanAmount = Number(propertyDetails?.loanAmount) * (rateRes / 100)
        setAddedLoanAMount(Number(fixedLoanAmount).toFixed(0))
        // handleBlur()
        // console.log('LMI :>> ',Number(propertyDetails?.loanAmount) * (rateRes / 100));
        handleChange('loanAmount', updatedLoanAmount.toFixed(0))
        if (Number(addedLoanAmount !== null)) {

          const depo = Number(propertyDetails?.loanAmount) - Number(addedLoanAmount)
          const totalDeopsit = propertyDetails?.propertyValue - depo
          handleChange('deposit', totalDeopsit)
          handleUpdateDeposit('deposit', totalDeopsit)
        }
        const res = calculateLVR(Number(propertyDetails?.propertyValue), Number(updatedLoanAmount))

        setLVR(res.toFixed(0))
        handleChange('lvr', res.toFixed(0))
        const stampValue = StampDuty(selectedState as any, Number(propertyDetails?.propertyValue))

        setStampValue(Number(stampValue.toFixed(0)))
        handleChange('stampDuty', stampValue.toFixed(0))
        handleUpdateStampDuty('stampDuty', stampValue.toFixed(0))
      }
    }
    if (e !== 'addLMI' && (Number(LVR) > 80 || Number(LVR) < 80)) {
      setToggleSwitch(false)
      setAddedLoanAMount(null)
      const depo = propertyDetails?.loanAmount - Number(addedLoanAmount)
      setLocalLoanAmount(depo.toFixed(0))
      handleChange('loanAmount', depo.toFixed(0))
    }
  }
  // onBlur={handleLoanTermBlur}
  // onChange={(e) =>
  //   handleChange("loanTerm", Number(e.target.value))
  // }
  const handleLoanTermBlur = () => {
    if (LoanTerm) {
      handleChange("loanTerm", Number(LoanTerm))
    }
  }


  const handleInterestRateBlur = (e: any) => {
    // if (interestRate) {
    setInterestRate(e.target.value)
    handleChange("interestRate", Number(e.target.value))

    // }

  }

  // useEffect(() => {
  //   if (interestRate) {
  //     const currentProjection = cashFlowAnalysis.cashFlowProjection;
  //     const updatedProjection = updateProjection(currentProjection, Number(interestRate));
  //     if (updatedProjection) {
  //       dispatch(updateCashFlowProjection(updatedProjection));
  //     }
  //   }
  // }, [cashFlowAnalysis.cashFlowProjection, dispatch, interestRate]);

  // const updateProjection = (
  //   currentProjection: CashFlowProjectionData | null,
  //   interestRate: number
  // ): CashFlowProjectionData | undefined => {
  //   if (currentProjection) {
  //     return {
  //       ...currentProjection,
  //       scenario2: {
  //         ...currentProjection.scenario2,
  //         cashFlowYears: currentProjection.scenario2.cashFlowYears.map(
  //           (cashFlowYear) => ({
  //             ...cashFlowYear,
  //             InterestRate: interestRate, // Set the updated interest rate
  //           })
  //         ),
  //       },
  //     };
  //   }
  //   return undefined; // Ensure a return if currentProjection is null/undefined
  // };



  useEffect(() => {
    if (Number(LVR) > 80 && !toggleSwitch) {
      setToggleSwitch(true)
      Swal.fire('As your loan is over 80% LVR you may be required to pay Lenders Mortgage Insurance (LMI).', '', 'warning');
    }
    if (Number(LVR) < 80) {
      setToggleSwitch(false)
    }
  }, [LVR])

  const handlecurrentEstimatedValueBlur = () => {
    if (currentEstimatedValue) {
      handleChange("propertyValue", Number(currentEstimatedValue))
      const stampValue = StampDuty(selectedState as any, Number(currentEstimatedValue))

      setStampValue(Number(stampValue.toFixed(0)))
      handleChange('stampDuty', stampValue.toFixed(0))
      handleUpdateStampDuty('stampDuty', stampValue.toFixed(0))
    }
  }
  const handleWeeklyRentBlur = () => {
    if (weeklyRent) {
      handleChange("estimatedWeeklyRent", Number(weeklyRent))
      // calculateGrossYield()
    }
  }
  useEffect(() => {
    if ((Number(propertyDetails?.loanAmount) === Number(localLoanAmount)) && Number(addedLoanAmount === null)) {

      handleChange('deposit', (propertyDetails && propertyDetails.propertyValue) - propertyDetails?.loanAmount)
      handleUpdateDeposit('deposit', (propertyDetails && propertyDetails.propertyValue) - propertyDetails?.loanAmount)
      // } else if ((Number(propertyDetails?.loanAmount) === Number(localLoanAmount)) && Number(addedLoanAmount !== null)) {
      //   // } else {
      //   
      //   const depo = Number(propertyDetails?.loanAmount) - Number(addedLoanAmount)
      //   const totalDeopsit = propertyDetails?.propertyValue - depo
      //   handleChange('deposit', totalDeopsit)
      //   handleUpdateDeposit('deposit', totalDeopsit)
    }
  }, [propertyDetails?.loanAmount, addedLoanAmount]);
  // }, [propertyDetails?.propertyValue,propertyDetails?.loanAmount ])


  useEffect(() => {
    if (selectedState) {
      handleChange('state', selectedState)
    }
  }, [selectedState])

  console.log('rentalYield :>> ', rentalYield);
  console.log('propertyrentalYield :>> ', propertyDetails?.grossYield);
  return (
    //   <Formik
    //   initialValues={{ address: '' }}
    //   onSubmit={(values) => {
    //     console.log(values);
    //   }}
    // >
    //   {({ values, handleChange }) => (


    <Box>
      <div className="overflow-x-auto flex flex-col">
        {!hideCashFlow && <Card
          sx={{
            padding: "18px 20px 20px 20px !important",
            marginBottom: "30px !important",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
            backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
          }}>
          <div>
            <Typography variant="h6" gutterBottom sx={{ pb: 2 }}>
              Property Details & Costs
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} onClick={() => toggleAutocomplete(0)}>

                <Autocomplete
                  options={propertyData?.results || []}
                  // defaultValue={selectedProperty.data[0].propertydetails[0] && `${selectedProperty.data[0].propertydetails[0].streetAddress || ''} ${selectedProperty.data[0].propertydetails[0].address.locality || ''} ${selectedProperty.data[0].propertydetails[0].address.state || ''} ${selectedProperty.data[0].propertydetails[0].postcode || ''}`  }
                  getOptionLabel={(option) =>
                    option && `${option.match.address?.streetAddress} ${option.match.address?.locality} ${option.match.address?.state} ${option.match.address?.postcode}`
                  }
                  // defaultValue={selectedProperty.data[0].address}
                  onInputChange={(e: any) => HandleInput(e?.target.value)}
                  onChange={(event, value) => handleSelect(event, value, 0)}
                  loading={propertyLoading}
                  renderInput={(params) => (
                    <TextField
                      // component={TextField}
                      name="address" // or the correct field name
                      label="Property address"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {selectedOptions[0] && (
                              <InputAdornment position="end">
                                <CheckIcon />
                              </InputAdornment>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{

                        "& .MuiInputBase-input-focused": {
                          color: 'grey',
                          borderColor: 'grey',
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: 'grey',
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: 'grey',
                        },
                      }}
                    />
                  )}
                />

              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
                <TextField
                  label="State"
                  value={selectedState || ""}
                  onChange={(e) => handleChange("state", e.target.value)}
                  // select
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                >

                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ marginTop: "10px" }}>
                <TextField
                  label="Property Value"
                  type="number"
                  value={currentEstimatedValue}
                  // onChange={(e) => setCurrentEstimatedValue(e.target.value)}
                  onBlur={handlecurrentEstimatedValueBlur}
                  onChange={(e) => {
                    const newValue = Number(e.target.value);
                    // handleChange("propertyValue", newValue);
                    setCurrentEstimatedValue(newValue)
                  }}
                  fullWidth
                  InputProps={{


                    endAdornment: (
                      <>

                        {propertyavmloading && propertyavmloading && !agentProfile
                          && (<InputAdornment position="end"><CircularProgress size={20} /></InputAdornment>)}
                        {/* <>{selectedOptions && <InputAdornment position="end"><CheckIcon /></InputAdornment>}</> */}

                      </>
                    )
                  }}
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Estimated Weekly Rent"
                  type="number"
                  value={weeklyRent || ""}
                  // onChange={(e) =>
                  //   handleChange("estimatedWeeklyRent", Number(e.target.value))
                  // }
                  // value={currentEstimatedValue || ""}
                  // onChange={(e) => setCurrentEstimatedValue(e.target.value)}
                  onBlur={handleWeeklyRentBlur}
                  onChange={(e) => {
                    const newValue = Number(e.target.value);
                    // handleChange("propertyValue", newValue);
                    setWeeklyRent(newValue)
                  }}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Gross Yield"
                  type="number"
                  value={rentalYield || propertyDetails?.grossYield || ''}
                  onChange={(e) =>
                    handleChange("grossYield", Number(e.target.value))
                  }
                  InputProps={{
                    endAdornment: (<>{propertyavmloading && propertyavmloading
                      ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : "%"}
                    </>
                    )
                  }}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>


            </Grid>
          </div>
        </Card>}

        <Card
          sx={{
            padding: "18px 20px 20px 20px !important",
            marginBottom: "30px !important",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
            // backgroundColor: colors.blueAccent[900]
            backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',

          }}>
          <div>
            <Typography variant="subtitle1" gutterBottom pb={2}>
              Funding Structure
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Loan Amount"
                  type="number"
                  // value={propertyDetails?.loanAmount || ""}
                  // onChange={(e) =>
                  //   handleChange("loanAmount", Number(e.target.value))
                  // }
                  value={localLoanAmount}
                  onChange={(e) => setLocalLoanAmount(e.target.value)}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
                {/* <TextField
  label="Loan Amount"
  type="number"
  value={localLoanAmount}
  onChange={(e) => setLocalLoanAmount(e.target.value)}
  onBlur={handleBlur}
  fullWidth
  sx={{
    "& .MuiInputBase-input-focused": {
      color: colors.grey[100],
      borderColor: colors.grey[100],
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: colors.grey[100],
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.grey[100],
    },
  }}
/> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="LVR"
                  type="number"
                  value={LVR || ""}
                  // onChange={(e) => handleChange("lvr", Number(e.target.value))}
                  onChange={(e) => setLVR(e.target.value)}
                  onBlur={handleLVRBlur}
                  InputProps={{
                    endAdornment: "%",
                  }}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Interest Rate"
                  type="number"
                  value={interestRate || ""}
                  // onChange={(e) => setInterestRate(e.target.value)}
                  onChange={(e) => handleInterestRateBlur(e)}
                  // onBlur={handleInterestRateBlur}
                  // onChange={(e) =>
                  //   handleChange("interestRate", Number(e.target.value))
                  // }
                  InputProps={{
                    endAdornment: "%",
                  }}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Loan Term"
                  type="number"
                  value={LoanTerm || ""}
                  onChange={(e) => setLoanTerm(e.target.value)}
                  onBlur={handleLoanTermBlur}
                  // onChange={(e) =>
                  //   handleChange("loanTerm", Number(e.target.value))
                  // }
                  InputProps={{
                    endAdornment: "years",
                  }}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography>Lenders Mortgage Insurance</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Switch {...label} checked={toggleSwitch} disabled={true}
                  onChange={() => setToggleSwitch(!toggleSwitch)}
                  sx={{
                    '& .MuiSwitch-thumb': {
                      backgroundColor: toggleSwitch ? '#4cceac !important' : '#5c5c5c !important',
                      color: toggleSwitch ? '#4cceac !important' : '#5c5c5c !important',
                      opacity: "1 !important",
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: toggleSwitch ? '#cccccc !important' : '#cccccc !important',
                      color: toggleSwitch ? '#4cceac !important' : '#5c5c5c !important',
                      opacity: "1 !important",
                    },
                  }}
                />
              </Grid>
              {toggleSwitch && (
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">LMI</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      defaultValue=""
                      onChange={(e) => HandleLMI(e.target.value)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select LMI</em>;
                        }
                        if (selected === "addLMI") {

                          return 'Add LMI to Loan Amount';
                        } else {
                          return 'I have an LMI Waiver';
                        }
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select LMI</em>
                      </MenuItem>
                      <MenuItem value='addLMI' >Add LMI to Loan Amount</MenuItem>
                      {/* <MenuItem value='Add LMI to Acquisition Costs'>Add LMI to Acquisition Costs</MenuItem> */}
                      <MenuItem value='LMIWaiver'>I have an LMI Waiver</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <TextField
                  label="LMI Premium (Varies by Bank)"
                  type="number"
                  disabled={true}
                  value={addedLoanAmount || "0.00"}
                  // onChange={(e) =>
                  //   handleChange("loanTerm", Number(e.target.value))
                  // }

                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <TextField
                  label="Loan Term Additional Stamp Duty on LMI(Estimated)"
                  type="number"
                  value={stampValue || "0.00"}
                  // onChange={(e) =>
                  //   handleChange("loanTerm", Number(e.target.value))
                  // }
                  disabled={true}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                /> */}
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
    </Box>
    // )}
    // </Formik>
  );
};

export default PropertyDetails;