// import React, { useEffect, useState } from 'react';
// import { TextField, Tooltip, Box, Typography, Card, useTheme, IconButton } from '@mui/material';
// import { tokens } from '../../../../theme';
// import InfoIcon from '@mui/icons-material/Info';
// import { DepreciationData, EmploymentIncomeData } from '../../../../types/CashFlow';
// import { updateDepreciation, updateEmployementIncome } from '../../../../features/cashFlow';
// import { useDispatch } from 'react-redux';
// interface IncomeState {
//     employmentIncome: string;
//     otherTaxableIncome: string;

// }
// interface DepreciationState {

//     depreciationDetails?: DepreciationData | null;
//     agentProfile?: any;
// }

// const Depreciation: React.FC<DepreciationState> = ({ depreciationDetails, agentProfile }) => {
//     const [depreciation, setDepreciation] = useState<string>('');

//     const theme = useTheme();
//     const colors = tokens(theme.palette.mode);
//     const dispatch = useDispatch();

//     // Handler for employment income
//     const handleDepreciationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         let value = e.target.value // Remove non-numeric characters
//         const formatted = new Intl.NumberFormat('en-US', {
//             style: 'currency',
//             currency: 'USD'
//         }).format(Number(value) || 0);

//         setDepreciation(value);
//         dispatch(updateDepreciation({
//             depreciation: Number(value),

//         }));
//     };

//     // Handler for other taxable income


//     useEffect(() => {
//         if (depreciationDetails) {
//             setDepreciation(depreciationDetails.depreciation?.toString());

//         }
//     }, [depreciationDetails]);

//     return (
//         <div className="overflow-x-auto flex flex-col">
//             <Card
//                 sx={{
//                     padding: "18px 20px 20px 20px !important",
//                     marginBottom: "30px !important",
//                     boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
//                     backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
//                 }}
//             >
//                 <div>
//                     <Typography variant="h6" gutterBottom sx={{ pb: 2 }}>
//                         Depreciation
//                     </Typography>
//                     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
//                         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                             <TextField
//                                 label="Depreciation"
//                                 value={depreciation || ''}
//                                 onChange={handleDepreciationChange}
//                                 InputProps={{
//                                     startAdornment: "$",
//                                 }}
//                                 fullWidth
//                                 variant="outlined"
//                             />
//                             {/* <Tooltip
//                                 title="Your annual employment income after salary sacrifice, before tax"
//                                 arrow
//                                 placement="right"
//                             >
//                                 <IconButton size="small">
//                                     <InfoIcon color="primary" fontSize="small" />
//                                 </IconButton>
//                             </Tooltip> */}
//                         </Box>


//                     </Box>
//                 </div>
//             </Card>
//         </div>
//     );
// };

// export default Depreciation;



import React, { useEffect, useState } from 'react';
import { TextField, Box, Typography, Card, useTheme } from '@mui/material';
import { tokens } from '../../../../theme';
import { DepreciationData } from '../../../../types/CashFlow';
import { updateDepreciation } from '../../../../features/cashFlow';
import { useDispatch } from 'react-redux';

interface DepreciationState {
    depreciationDetails?: DepreciationData | null;
    agentProfile?: any;
}

const Depreciation: React.FC<DepreciationState> = ({ depreciationDetails, agentProfile }) => {
    const [depreciation, setDepreciation] = useState<string>('0');
    const [inputValue, setInputValue] = useState<string>('0'); 
    const [debouncedValue, setDebouncedValue] = useState<string>('0'); 

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    const handleDepreciationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const cleanedValue = value.replace(/^0+/, '').replace(/[^\d.-]/g, '');

        setInputValue(cleanedValue || '0');
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 500); 

        return () => {
            clearTimeout(handler); 
        };
    }, [inputValue]);

    useEffect(() => {
        if (debouncedValue !== '') {
            dispatch(updateDepreciation({
                depreciation: Number(debouncedValue) || 0, 
            }));
        }
    }, [debouncedValue, dispatch]);

    useEffect(() => {
        if (depreciationDetails) {
            setDepreciation(depreciationDetails.depreciation?.toString() || '0');
        }
    }, [depreciationDetails]);

    return (
        <div className="overflow-x-auto flex flex-col">
            <Card
                sx={{
                    padding: "18px 20px 20px 20px !important",
                    marginBottom: "30px !important",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                    backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
                }}
            >
                <div>
                    <Typography variant="h6" gutterBottom sx={{ pb: 2 }}>
                        Depreciation
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                label="Depreciation"
                                value={inputValue} // Bind to local state
                                onChange={handleDepreciationChange}
                                InputProps={{
                                    startAdornment: "$",
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </div>
            </Card>
        </div>
    );
};

export default Depreciation;
