import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, Grid, TextField, Typography, useTheme } from '@mui/material';
import { AnnualHoldingCostsData, PropertyDetailsData } from '../../../../types/CashFlow';
import { tokens } from '../../../../theme';

interface AnnualHoldingCostsProps {
  annualHoldingCosts: AnnualHoldingCostsData | null;
  updateAnnualHoldingCosts: (updates: Partial<AnnualHoldingCostsData>) => void;
  propertyDetails: PropertyDetailsData | null;
}
// interface AnnualHoldingCostsProps {
//   annualHoldingCosts: any | null;
//   updateAnnualHoldingCosts: (updates: Partial<AnnualHoldingCostsData>) => void;
//   propertyDetails: any | null;
// }

const AnnualHoldingCosts: React.FC<AnnualHoldingCostsProps> = ({ annualHoldingCosts, updateAnnualHoldingCosts, propertyDetails }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [inputValue, setInputValue] = useState(annualHoldingCosts?.miscellaneous_costs?.toString() || '0');
  const [debouncedValue, setDebouncedValue] = useState<any>(inputValue);
  console.log(annualHoldingCosts,"annualHoldingCostssssssss")
  const handleChange = useMemo(
    () => (field: keyof AnnualHoldingCostsData, value: string | number) => {
      updateAnnualHoldingCosts({ [field]: value });
    },
    [updateAnnualHoldingCosts] 
  );
  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    const cleanedValue = value.replace(/^0+/, '');
    setInputValue(cleanedValue || '0'); 
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue); 
    }, 500); 

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    handleChange('miscellaneous_costs', Number(debouncedValue));
  }, [debouncedValue]);
  console.log(inputValue, "annnnnnnaaaaaaaaaaaaaaaa")
  return (
    <Box>
      <Card
        sx={{
          padding: "18px 20px 20px 20px !important",
          marginBottom: "0px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
          // backgroundColor: colors.blueAccent[900],
          backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
        }}>
        <Typography variant="h6" gutterBottom pb={2}>
          Annual Holding Costs
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Property Management Fee"
              type="number"
              value={annualHoldingCosts?.propertyManagementFee || ''}
              onChange={(e) => handleChange('propertyManagementFee', Number(e.target.value))}
              InputProps={{
                endAdornment: '%',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Letting Fee"
              type="number"
              value={annualHoldingCosts?.lettingFee || ''}
              onChange={(e) => handleChange('lettingFee', Number(e.target.value))}
              InputProps={{
                endAdornment: 'Week',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Maintenance Cost PA"
              type="number"
              value={annualHoldingCosts?.maintenanceCostPA || ''}
              onChange={(e) => handleChange('maintenanceCostPA', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Council Rates"
              type="number"
              value={annualHoldingCosts?.councilRates || ''}
              onChange={(e) => handleChange('councilRates', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Water Rates"
              type="number"
              value={annualHoldingCosts?.waterRates || ''}
              onChange={(e) => handleChange('waterRates', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Insurance"
              type="number"
              value={annualHoldingCosts?.insurance || ''}
              onChange={(e) => handleChange('insurance', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              label="Miscellaneous costs"
              type="number"
              // value={annualHoldingCosts?.miscellaneous_costs  ? annualHoldingCosts?.miscellaneous_costs : 0}
              value={annualHoldingCosts?.miscellaneous_costs !== undefined ? annualHoldingCosts?.miscellaneous_costs.toString() : 0}
              onChange={(e) => handleChange('miscellaneous_costs', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            /> */}
            <TextField
              label="Miscellaneous costs"
              type="number"
              value={inputValue}
              onChange={handleChangeInputValue} // Update local state on every input change
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default AnnualHoldingCosts;