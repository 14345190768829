// import React from 'react';
// import { ResponsiveLine } from '@nivo/line';
// import { useTheme } from '@mui/material';
// import { tokens } from '../../../../theme';
// import { useSelector } from 'react-redux';

// const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {
//   const lineChartLoansAfterSalesItem = useSelector(
//     (state) => state.investedProperties.lineChartModelledPropertyValue
//   );
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   return (
//     <ResponsiveLine
//       data={lineChartLoansAfterSalesItem}
//       theme={{
//         axis: {
//           domain: {
//             line: {
//               stroke: colors.grey[100],
//             },
//           },
//           legend: {
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//           ticks: {
//             line: {
//               stroke: colors.grey[100],
//               strokeWidth: 1,
//             },
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//         },
//         legends: {
//           text: {
//             fill: colors.grey[100],
//           },
//         },
//         tooltip: {
//           container: {
//             color: colors.primary[500],
//           },
//         },
//       }}
//       colors={isDashboard ? { datum: 'color' } : { scheme: 'nivo' }}
//       margin={{ top: 50, right: 250, bottom: 50, left: 60 }}
//       xScale={{ type: 'point' }}
//       yScale={{
//         type: 'linear',
//         min: 'auto',
//         max: 'auto',
//         stacked: true,
//         reverse: false,
//       }}
//       yFormat={(value) => value} // Ensure yFormat matches your data
//       curve="catmullRom"
//       axisTop={null}
//       axisRight={null}
//       axisBottom={{
//         orient: 'bottom',
//         tickSize: 0,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: isDashboard ? undefined : 'transportation',
//         legendOffset: 36,
//         legendPosition: 'middle',
//       }}
//       axisLeft={{
//         orient: 'left',
//         tickValues: 5,
//         tickSize: 3,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: isDashboard ? undefined : 'count',
//         legendOffset: -40,
//         legendPosition: 'middle',
//       }}
//       enableGridX={false}
//       enableGridY={false}
//       pointSize={8}
//       pointColor={(point) => point.serieColor} // Set point color based on the series color
//       pointBorderWidth={2}
//       pointBorderColor={{ from: 'serieColor' }}
//       pointLabelYOffset={-12}
//       useMesh={true}
//       tooltip={({ point }) => {
//         // Log point data for debugging

//         return (
//           <div
//             style={{
//               background: colors.primary[400],
//               padding: '5px 10px',
//               border: '1px solid #ccc',
//               fontSize: '12px',

//               borderRadius: '4px',
//               display: 'flex',
//               alignItems: 'center',
//             }}
//           >
//             <div
//               style={{
//                 width: '12px',
//                 height: '12px',
//                 borderRadius: '50%',
//                 backgroundColor: point.serieColor,
//                 marginRight: '8px',
//               }}
//             />
//             <div>
//               <strong>name:{point.data.x}</strong>
//               <br />
//               Value: {point.data.y.toFixed(2)}
//             </div>
//           </div>
//         );
//       }}
//       legends={[
//         {
//           anchor: 'bottom-right',
//           direction: 'column',
//           justify: false,
//           translateX: 100,
//           translateY: 0,
//           itemsSpacing: 0,
//           itemDirection: 'left-to-right',
//           itemWidth: 80,
//           itemHeight: 20,
//           itemOpacity: 0.75,
//           symbolSize: 12,
//           symbolShape: 'circle',
//           symbolBorderColor: 'rgba(0, 0, 0, .5)',
//           effects: [
//             {
//               on: 'hover',
//               style: {
//                 itemBackground: 'rgba(0, 0, 0, .03)',
//                 itemOpacity: 1,
//               },
//             },
//           ],
//         },
//       ]}
//     />
//   );
// };

// export default LineChart;

// import React from 'react';
// import { ResponsiveBar } from '@nivo/bar';
// import { useTheme } from '@mui/material';
// import { tokens } from '../../../../theme';
// import { useSelector } from 'react-redux';

// const LineChart = ({ isDashboard = false }) => {
//   const barChartData = useSelector(
//     (state) => state.investedProperties.lineChartModelledPropertyValue
//   );
//   console.log('barChartData', barChartData)
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);


//   const transformBarChartData = (barChartData) => {
//     // Create a map to hold property-wise data
//     const propertyMap = new Map();

//     // Iterate over the barChartData array
//     barChartData.forEach((series) => {
//       series.data.forEach((property) => {
//         const propertyName = property.x;

//         // If this property is not already in the map, initialize it
//         if (!propertyMap.has(propertyName)) {
//           console.log(propertyMap,'--->>>>mappp')

//           propertyMap.set(propertyName, { property: propertyName });
//         }

//         // Add the series value (y) to the corresponding property in the map and round it to the nearest integer
//         propertyMap.get(propertyName)[series.id] = parseFloat(property.y.toFixed(0));
//       });
//     });

//     // console.log(Array.from(propertyMap.values()),'hhhhyyyy')
//     // Convert the map back to an array
//     return Array.from(propertyMap.values());
//   };


//   // Transform the barChartData to the format required by the ResponsiveBar component
//   const transformedData = transformBarChartData(barChartData);

//   const barColors = [
//     theme.palette.mode === 'dark' ? '#94a5f2' : '#94a5f2',
//     theme.palette.mode === 'dark' ? '#e67e22' : '#e67e22',
//     theme.palette.mode === 'dark' ? '#2ecc71' : '#2ecc71',
//     theme.palette.mode === 'dark' ? '#3700B3' : '#3FA2F6',
//     theme.palette.mode === 'dark' ? '#C738BD' : '#C738BD',
//     theme.palette.mode === 'dark' ? '#9BEC00' : '#9BEC00',
//   ];
//   return (
//     <ResponsiveBar
//       data={transformedData}
//       keys={[
//         'loan values before sales',
//         'loan values after sales',
//         'remaining cash from sales after c g t',
//         'properties with no loans',
//         'pre tax passive income',
//       ]}
//       indexBy="property" // This field will be used for the x-axis (property names)
//       margin={{ top: 50, right: 250, bottom: 50, left: 60 }}
//       padding={0.3}
//       groupMode="grouped" // Ensure bars are grouped, not stacked
//       colors={barColors} // Use your preferred color scheme or custom colors
//       axisTop={null}
//       axisRight={null}
//       axisBottom={{
//         tickSize: 5,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: 'Property',
//         legendPosition: 'middle',
//         legendOffset: 32,
//       }}
//       axisLeft={{
//         tickSize: 5,
//         tickPadding: 5,
//         tickRotation: 0,
//         // legend: 'Value',
//         legendPosition: 'middle',
//         legendOffset: -40,
//       }}
//       labelSkipWidth={12}
//       labelSkipHeight={12}
//       labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
//       tooltip={({ id, value, color, indexValue }) => (
//         <div
//           style={{
//             background: '#333',
//             padding: '5px 10px',
//             border: '1px solid #ccc',
//             fontSize: '12px',
//             borderRadius: '4px',
//             color: '#fff',
//           }}
//         >
//           <strong>{id}</strong>
//           <br />
//           Property: {indexValue}
//           <br />
//           Value: {value}
//         </div>
//       )}
//       legends={[
//         {
//           dataFrom: 'keys',
//           anchor: 'bottom-right',
//           direction: 'column',
//           justify: false,
//           translateX: 120,
//           translateY: 0,
//           itemsSpacing: 2,
//           itemWidth: 100,
//           itemHeight: 20,
//           itemDirection: 'left-to-right',
//           itemOpacity: 0.75,
//           symbolSize: 12,
//           symbolShape: 'circle',
//           effects: [
//             {
//               on: 'hover',
//               style: {
//                 itemBackground: 'rgba(0, 0, 0, .03)',
//                 itemOpacity: 1,
//               },
//             },
//           ],
//         },
//       ]}
//     />
//   );
// };


// export default LineChart;import React from 'react';





// import React from 'react';
// import { ResponsiveBar } from '@nivo/bar';
// import { useTheme } from '@mui/material';
// import { tokens } from '../../../../theme';
// import { useSelector } from 'react-redux';

// const LineChart = ({ isDashboard = false }) => {
//   const barChartData = useSelector(
//     (state) => state.investedProperties.lineChartModelledPropertyValue
//   );
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const transformBarChartData = (barChartData) => {
//     const propertyMap = new Map();

//     barChartData.forEach((series) => {
//       series.data.forEach((property) => {
//         const propertyName = property.x;

//         if (!propertyMap.has(propertyName)) {
//           propertyMap.set(propertyName, { property: propertyName, totalValue: 0 });
//         }

//         // Check if property.y is a valid number
//         const value = typeof property.y === 'number' ? parseFloat(property.y.toFixed(0)) : 0;

//         // Add the series value (y) to the corresponding property in the map
//         propertyMap.get(propertyName)[series.id] = value;

//         // Increment the total value for the property
//         propertyMap.get(propertyName).totalValue += value;
//       });
//     });

//     return Array.from(propertyMap.values());
//   };


//   // Transform the barChartData to the format required by the ResponsiveBar component
//   const transformedData = transformBarChartData(barChartData);

//   const barColors = [
//     theme.palette.mode === 'dark' ? '#4CAF50' : '#FF5722',
//     theme.palette.mode === 'dark' ? '#FFEB3B' : '#673AB7',
//     theme.palette.mode === 'dark' ? '#FF9800' : '#3F51B5',
//     theme.palette.mode === 'dark' ? '#03A9F4' : '#8BC34A',
//     theme.palette.mode === 'dark' ? '#E91E63' : '#9C27B0',
//   ];

//   return (
//     <ResponsiveBar
//       labelPosition="top"  // Set labels to appear at the top
//       labelOffset={-20}    // Adjust this to fine-tune label position
//       data={transformedData}
//       keys={[
//         'loan values before sales',
//         'loan values after sales',
//         'remaining cash from sales after c g t',
//         'properties with no loans',
//         'pre tax passive income',
//       ]}
//       indexBy="property"
//       margin={{ top: 50, right: 150, bottom: 70, left: 80 }}
//       padding={0.25}
//       groupMode="grouped"
//       colors={barColors}
//       axisTop={null}
//       axisRight={null}
//       axisBottom={{
//         tickSize: 5,
//         tickPadding: 10,
//         tickRotation: 0,
//         legend: 'Property',
//         legendPosition: 'middle',
//         legendOffset: 40,
//       }}
//       axisLeft={{
//         tickSize: 5,
//         tickPadding: 10,
//         tickRotation: 0,
//         legend: 'Aud',
//         legendPosition: 'middle',
//         legendOffset: -70,
//       }}
//       labelSkipWidth={10}
//       labelSkipHeight={4}
//       labelTextColor={{
//         from: 'color',
//         modifiers: [['darker', 1.6]],
//       }}
//       tooltip={({ id, value, color, indexValue, data }) => {
//         const totalValue = data.totalValue;
//         const percentage = ((value / totalValue) * 100).toFixed(2);
//         return (
//           <div style={{ padding: '10px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.3)' }}>
//             <strong>{id}</strong>
//             <div>Property: {indexValue}</div>
//             <div>Value: {value}</div>
//             <div>Percentage: {percentage}%</div>
//             <div>Total: {totalValue}</div>
//           </div>
//         );
//       }}
//       legends={[
//         {

//           dataFrom: 'keys',
//           anchor: 'bottom-right',
//           direction: 'column',
//           justify: false,
//           translateX: 100,
//           translateY: 20,
//           itemsSpacing: 2,
//           itemWidth: 120,
//           itemHeight: 20,
//           itemDirection: 'left-to-right',
//           itemOpacity: 0.85,
//           symbolSize: 12,
//           symbolShape: 'circle',
//         },
//       ]}
//     />

//   );
// };

// export default LineChart;



import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@mui/material';
import { tokens } from '../../../../theme';
import { useSelector } from 'react-redux';

const LineChart = ({ isDashboard = false }) => {
  const barChartData = useSelector(
    (state) => state.investedProperties.lineChartModelledPropertyValue
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const transformBarChartData = (barChartData) => {
    const propertyMap = new Map();

    barChartData.forEach((series) => {
      series.data.forEach((property) => {
        const propertyName = property.x;

        if (!propertyMap.has(propertyName)) {
          propertyMap.set(propertyName, { property: propertyName, totalValue: 0 });
        }

        // Check if property.y is a valid number
        const value = typeof property.y === 'number' ? parseFloat(property.y.toFixed(0)) : 0;

        // Add the series value (y) to the corresponding property in the map
        propertyMap.get(propertyName)[series.id] = value;

        // Increment the total value for the property
        propertyMap.get(propertyName).totalValue += value;
      });
    });

    return Array.from(propertyMap.values());
  };

  const transformedData = transformBarChartData(barChartData);

  const barColors = [
    theme.palette.mode === 'dark' ? '#4CAF50' : '#FF5722',
    theme.palette.mode === 'dark' ? '#FFEB3B' : '#673AB7',
    theme.palette.mode === 'dark' ? '#FF9800' : '#3F51B5',
    theme.palette.mode === 'dark' ? '#03A9F4' : '#8BC34A',
    theme.palette.mode === 'dark' ? '#E91E63' : '#9C27B0',
  ];

  return (

    <ResponsiveBar
      data={transformedData}
      keys={[
        'loan values before sales',
        'loan values after sales',
        'remaining cash from sales after c g t',
        'properties with no loans',
        'pre tax passive income',
      ]}
      indexBy="property"
      margin={{ top: 50, right: 150, bottom: 70, left: 80 }}
      padding={0.25}
      groupMode="grouped"
      colors={barColors}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legend: 'Property',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legend: 'Aud',
        legendPosition: 'middle',
        legendOffset: -70,
      }}
      enableLabel={false}  // This line disables the labels
      tooltip={({ id, value, color, indexValue, data }) => {
        const totalValue = data.totalValue;
        const percentage = ((value / totalValue) * 100).toFixed(2);
        return (
          <div
            style={{
              padding: '10px',
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.3)',
            }}
          >
            <strong>{id}</strong>
            <div>Property: {indexValue}</div>
            <div>Value: {value}</div>
            <div>Percentage: {percentage}%</div>
            <div>Total: {totalValue}</div>
          </div>
        );
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 20,
          itemsSpacing: 2,
          itemWidth: 120,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 12,
          symbolShape: 'circle',
        },
      ]}
    />
  );
};

export default LineChart;