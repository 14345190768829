import React, { useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Paper,
  IconButton,
  useTheme,
  Drawer,
  Fab,
  Tooltip,
  Badge,
  Chip
} from '@mui/material';
import {
  Psychology,
  TrendingUp,
  Warning,
  AutoAwesome,
  Refresh,
  Assignment,
  SmartToy,
  NewReleases
} from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AuthenticatedAPI from '../../../auth_helper';
import { keyframes } from '@mui/system';

// Add pulse animation
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

// Add glow animation
const glowAnimation = keyframes`
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(150%);
  }
  100% {
    filter: brightness(100%);
  }
`;

interface PortfolioAIAnalysisProps {
    // Required props - matching backend exactly
    properties: any[];
    modelledYear: number;
    passiveGoal: { passive_income: number };
    PPORLoan: number;
    totalSellAndPayOffLoansValue: number;
    passiveIncome: number;
    effectiveTaxRate: number;
    totalPortfolioValue: number;
    averageYield: number;
    averageGrowth: number;
    
    // Optional UI props
    scenarioName?: string;
  }

interface PortfolioAIAnalysisProps {
  properties: any[];
  modelledYear: number;
  passiveGoal: { passive_income: number };
  PPORLoan: number;
  totalSellAndPayOffLoansValue: number;
  passiveIncome: number;
  effectiveTaxRate: number;
  totalPortfolioValue: number;
  averageYield: number;
  averageGrowth: number;
  isGoalAchieved?: boolean;
  scenarioName?: string;
}

interface AIAnalysis {
  strengths: string[];
  opportunities: string[];
}

const PortfolioAIAnalysis: React.FC<PortfolioAIAnalysisProps> = ({
  properties = [],
  modelledYear = new Date().getFullYear(),
  passiveGoal = { passive_income: 0 },
  PPORLoan = 0,
  totalSellAndPayOffLoansValue = 0,
  passiveIncome = 0,
  effectiveTaxRate = 0,
  totalPortfolioValue = 0,
  averageYield = 0,
  averageGrowth = 0,
  isGoalAchieved = false,
  scenarioName = 'Default Scenario'
}) => {
  const theme = useTheme();
  const [analysis, setAnalysis] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const fetchAnalysis = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'post',
        '/api/portfolio/ai-analysis',
        {
          body: {
            properties,
            modelledYear,
            passiveGoal,
            PPORLoan,
            totalSellAndPayOffLoansValue,
            passiveIncome,
            effectiveTaxRate,
            totalPortfolioValue,
            averageYield,
            averageGrowth
          }
        }
      );

      if (response.success && response.data) {
        // Map backend response to match existing UI structure
        setAnalysis({
          strategy: response.data.strengths,
          risks: [], // Remove risks section
          recommendations: {
            shortTerm: response.data.opportunities.slice(0, Math.ceil(response.data.opportunities.length / 2)),
            longTerm: response.data.opportunities.slice(Math.ceil(response.data.opportunities.length / 2))
          }
        });
      } else {
        throw new Error(response.message || 'Failed to fetch analysis');
      }
    } catch (err) {
      console.error('Error fetching analysis:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Enhanced AI Trigger Button */}
      <Tooltip 
        title={isGoalAchieved ? "View AI Analysis" : "Complete your goals to unlock AI insights"} 
        placement="left"
      >
        <span>
          <Fab
            color="primary"
            disabled={!isGoalAchieved}
            sx={{
              position: 'fixed',
              right: 20,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1000,
              background: 'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)',
              transition: 'all 0.3s ease',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              '&:hover': { 
                background: 'linear-gradient(135deg, #3bbd9b 0%, #1976d2 100%)',
                transform: 'translateY(-50%) scale(1.05)',
                boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
              },
              '&:disabled': {
                background: 'linear-gradient(135deg, #ccc 0%, #999 100%)',
                opacity: 0.7
              },
              width: 64,
              height: 64
            }}
            onClick={() => isGoalAchieved && setDrawerOpen(true)}
          >
            <Badge
              badgeContent={isGoalAchieved ? "AI" : "🔒"}
              color="secondary"
              sx={{
                '& .MuiBadge-badge': {
                  background: isGoalAchieved ? 
                    'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)' : 
                    'linear-gradient(135deg, #999 0%, #666 100%)',
                  color: 'white',
                  transition: 'all 0.3s ease'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  '&::before': isGoalAchieved ? {
                    content: '""',
                    position: 'absolute',
                    top: -2,
                    left: -2,
                    right: -2,
                    bottom: -2,
                    background: 'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)',
                    borderRadius: '50%',
                    opacity: 0.5,
                    animation: `${pulseAnimation} 2s infinite`,
                    zIndex: -1
                  } : {}
                }}
              >
                <SmartToy 
                  sx={{ 
                    fontSize: 28,
                    color: 'white',
                    transition: 'all 0.3s ease',
                    transform: isGoalAchieved ? 'scale(1.1)' : 'scale(1)',
                    filter: isGoalAchieved ? 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))' : 'none'
                  }} 
                />
              </Box>
            </Badge>
          </Fab>
        </span>
      </Tooltip>

      {/* Enhanced AI Analysis Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '50%',
            maxWidth: '800px',
            minWidth: '300px',
            bgcolor: '#f8fafc',
            p: 3,
          }
        }}
      >
        <Box sx={{ width: '100%' }}>
          {/* Header */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
            {/* Title and Refresh Button */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h5" sx={{ 
                      background: 'linear-gradient(45deg, #4cceac, #2196f3)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      fontWeight: 700,
                      fontSize: '1.5rem'
                    }}>
                      AI Portfolio Analysis
                    </Typography>
                    <Chip
                      icon={<NewReleases sx={{ fontSize: 14 }} />}
                      label="BETA*"
                      size="small"
                      sx={{
                        background: 'linear-gradient(45deg, #4cceac, #2196f3)',
                        color: 'white',
                        fontWeight: 500,
                        fontSize: '0.6rem',
                        height: '20px',
                        '& .MuiChip-label': {
                          padding: '0 6px'
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Refresh />}
                onClick={fetchAnalysis}
                disabled={loading}
                sx={{
                  background: 'linear-gradient(45deg, #4cceac, #2196f3)',
                  color: 'white',
                  borderRadius: '12px',
                  textTransform: 'none',
                  px: 3,
                  py: 1,
                  '&:hover': {
                    background: 'linear-gradient(45deg, #3bbd9b, #1976d2)',
                  }
                }}
              >
                {loading ? 'Analyzing...' : 'Refresh Analysis'}
              </Button>
            </Box>
            
            {/* Scenario Information */}
            <Paper 
              elevation={0} 
              sx={{ 
                p: 2.5,
                background: 'linear-gradient(135deg, rgba(76, 206, 172, 0.1), rgba(33, 150, 243, 0.1))',
                border: '1px solid rgba(76, 206, 172, 0.2)',
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}
            >
              <AutoAwesome sx={{ 
                color: '#4cceac',
                fontSize: 28
              }} />
              <Box>
        
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'text.primary',
                    fontWeight: 600
                  }}
                >
                  Analysis for {scenarioName || 'Default Scenario'}
                  </Typography>
              </Box>
            </Paper>
          </Box>

          {/* Content */}
          {error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : analysis ? (
            <Grid container spacing={3}>
              {/* Strategy Section */}
              {analysis.strategy?.length > 0 && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 3, borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <TrendingUp color="primary" />
                      <Typography variant="h6">Strategy Analysis</Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {analysis.strategy.map((point: string, index: number) => (
                        <Grid item xs={12} key={index}>
                          <Alert severity="info">{point}</Alert>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {/* Risks Section */}
              {analysis.risks?.length > 0 && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 3, borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <Warning color="warning" />
                      <Typography variant="h6">Risk Assessment</Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {analysis.risks.map((risk: any, index: number) => (
                        <Grid item xs={12} key={index}>
                          <Alert severity="warning">
                            <AlertTitle>{risk.identified}</AlertTitle>
                            {risk.mitigation}
                          </Alert>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {/* Recommendations Section */}
              {(analysis.recommendations?.shortTerm?.length > 0 || analysis.recommendations?.longTerm?.length > 0) && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 3, borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <Assignment color="primary" />
                      <Typography variant="h6">Recommendations</Typography>
                    </Box>
                    <Grid container spacing={3}>
                      {/* Short-term Actions */}
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                          Short-term Actions
                        </Typography>
                        <Grid container spacing={1}>
                          {analysis.recommendations.shortTerm.map((rec: string, index: number) => (
                            <Grid item xs={12} key={index}>
                              <Alert severity="success">{rec}</Alert>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>

                      {/* Long-term Strategy */}
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                          Long-term Strategy
                        </Typography>
                        <Grid container spacing={1}>
                          {analysis.recommendations.longTerm.map((rec: string, index: number) => (
                            <Grid item xs={12} key={index}>
                              <Alert severity="info">{rec}</Alert>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          ) : (
            <Alert 
              severity="info"
              icon={<AutoAwesome />}
              sx={{ 
                bgcolor: 'primary.light', 
                color: 'primary.contrastText',
                '& .MuiAlert-icon': { color: 'primary.contrastText' }
              }}
            >
              <AlertTitle>Get AI Insights</AlertTitle>
              Click the Refresh Analysis button to receive AI-powered insights about your portfolio strategy.
            </Alert>
          )}

          {/* Add footnote at the bottom of the Drawer */}
          <Box sx={{ 
            mt: 'auto', 
            pt: 2, 
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            typography: 'caption',
            color: 'text.secondary',
            opacity: 0.8
          }}>
            * AI-generated insights are for guidance purposes only and should not be considered as financial advice.
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default PortfolioAIAnalysis;