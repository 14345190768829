import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  useTheme,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";
// import InvestedPropertyColumn from "../../components/investedProperty";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import {
  // addProperty,
  updateProperty,
} from "../../../features/investedProperties";
import { addScenario, updateScenario, deleteScenario, setCurrentScenario, addProperty } from "../../../features/scenarios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { tokens } from "../../../theme";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CurrentPortFolio } from "../../../types/User";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { GetUserProfile } from "../../../hooks/userprofile/user";
import AuthenticatedAPI from "../../../auth_helper";
import { DeleteInvestScenario, GetInvestScenario, SaveInvestScenario } from "../../../hooks/buyerAgent";
import { toast } from "react-toastify";
import InvestedPropertyColumn from "../../components/investedProperty";
import { InvestNetScenario, ScenarioProperty } from "../../../types/BuySell";

const InvestmentPropertiesSchema = yup.object({
  investmentProperties: yup.array().of(
    yup.object({
      deposit: yup
        .number()
        .required("Deposit is required")
        .min(0, "Invalid deposit amount"),
    })
  ),
});

interface BuyAndSellPageProps {
  buyerAgent: any;

}

const BuyAndSellPage: React.FC<BuyAndSellPageProps> = ({ buyerAgent }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const [scenarioRefresh, setScenarioRefresh] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [investmentProperties, setInvestmentProperties] = useState<CurrentPortFolio[]>([]);
  // console.log('investmentProperties', investmentProperties)
  const propertiesss = useSelector((state: RootState) => state.investedProperties.properties);
  const currentScenario = useSelector((state: RootState) => state.scenarios.currentScenario);
  // console.log('currentScenario----<<<<>>><><>', currentScenario)
  const scenarios = useSelector((state: RootState) => state.scenarios.scenarios[0]);
  // console.log('scenarios', scenarios)
  const scenariosssss = useSelector((state: RootState) => state.scenarios.scenarios);


  // console.log('scenarios1212121212', scenariosssss)
  const [scenarioName, setScenarioName] = useState("");
  const [currentScenarioName, setCurrentScenarionNAme] = useState("");
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [selectedScenarioId, setSelectedScenarioId] = useState<string | null>(null);
  // console.log('selectedScenarioId', selectedScenarioId)

  const location = useLocation();
  // const agentId = searchParams.get('agentId') || '';
  const [searchParams] = useSearchParams();
  // console.log('searchParams', searchParams)
  const scenarioId = searchParams.get('scenarioId');
  // Filter to get the selected scenario based on the selected ID
  const selectedScenario = Array.isArray(scenarios)
    ? scenarios.find((scenario: any) => scenario?.id === selectedScenarioId)
    : scenarios?.id;
  // console.log('selectedScenario', selectedScenario)
  // Extract properties from the selected scenario
  const properties = currentScenario && currentScenario.properties;
  // console.log(currentScenario?.properties, '-------------', investmentProperties)
  const investorId = JSON.parse(sessionStorage.getItem('investorData') || '{}').investor_id;
  useEffect(() => {
    const fetchData = async () => {
      const profileData = await GetUserProfile(buyerAgent.investor_id);

      if (profileData && profileData.currentPortfolio) {
        setInvestmentProperties(profileData.currentPortfolio?.investmentProperties);
        await fetchScenarios()
      }
      setLoading(false);
    };

    fetchData();
  }, [buyerAgent.investor_id]);

  useEffect(() => {
    if (investmentProperties.length > 0 && properties?.length === 0) {
      investmentProperties.forEach((property, index) => {
        dispatch(
          updateProperty({
            index,
            data: {
              id: Number(property.id),
              address: property.address,
              value: property.initialpurchaseprice.toLocaleString(),
              currentEstimated_value: property.currentestimatedvalue.toLocaleString(),
              purchaseYear: property.yearofpurchase,
              netYield: 4,
              compoundingAnnualGrowthRate: 7,
              marginalTaxRate: 45,
              loanBeforeSales: 0,
              sellAndPayOffLoans: false,
              leverageRatio: 0,
              deposit: 0,
              loan: 0,
              equityAccessed: 0,
              modelledYear: 0,
              sellYear: 0,
              ispporproperty: property.ispporproperty
            },
          })
        );
      });
    }
  }, [investmentProperties, dispatch, properties?.length]);

  useEffect(() => {
    if (scenarioId) {
      setSelectedScenarioId(scenarioId);
    }
  }, [scenarioId])

  // useEffect(() => {
  //   // Check if there are no scenarios and investmentProperties are present
  //   if (investmentProperties.length > 0 && (!currentScenario || currentScenario.properties.length === 0)) {
  //     // Initialize a new scenario with default values
  //     const newScenario: InvestNetScenario = {
  //       id: `scenario-${Date.now()}`, // Unique ID
  //       user_id: 'defaultUserId', // Set this to the appropriate user ID or pass it as a prop
  //       scenario_name: 'New Scenario', // Default name for the scenario
  //       modelled_year: 2024, // Default modelled year
  //       passive_income_goal: 10000, // Default passive income goal
  //       effective_tax_rate: 0, // Default effective tax rate
  //       cgt_rate: 0, // Default capital gains tax rate
  //       ppor_loan: 0, // Default PPOR loan amount
  //       properties: [], // Start with an empty properties array
  //       portfolio_analysis: {
  //         totalProjectedValue: 0, // Default total projected value
  //         outstandingLoanAmount: 0, // Default outstanding loan amount
  //         totalPassiveIncome: 0, // Default total passive income
  //         isGoalAchieved: false, // Default to not achieved
  //         pporPayoffProgress: 0, // Default PPOR payoff progress
  //         passiveIncomeProgress: 0, // Default passive income progress
  //       },
  //       sellYear: null, // Default value for sellYear
  //     };

  //     // Set the new scenario as the current one
  //     dispatch(setCurrentScenario(newScenario));

  //     // Add properties based on investmentProperties
  //     investmentProperties.forEach((property) => {
  //       const newProperty: Omit<ScenarioProperty, "id"> = {
  //         address: property.address,
  //         value: property.initialpurchaseprice.toLocaleString(),
  //         purchaseYear: property.yearofpurchase,
  //         netYield: 4,
  //         compoundingAnnualGrowthRate: 7,
  //         marginalTaxRate: 45,
  //         sellAndPayOffLoans: false,
  //         leverageRatio: 0,
  //         deposit: 0,
  //         equityAccessed: 0,
  //         modelledYear: 0,
  //         sellYear: 0,
  //         ispporproperty: property.ispporproperty,
  //         effective_tax_rate: 0, // Include the required property
  //       };

  //       dispatch(addProperty(newProperty));
  //     });
  //   }
  // }, [dispatch, investmentProperties, currentScenario]);


  useEffect(() => {
    dispatch(setCurrentScenario(selectedScenario))
  }, [dispatch, selectedScenario])
  const handleAddProperty = () => {

    dispatch(addProperty({
      address: "",
      value: "",
      currentEstimated_value: "",
      purchaseYear: 0,
      deposit: 0,
      loan: 0,
      leverageRatio: 0,
      compoundingAnnualGrowthRate: 7,
      marginalTaxRate: 45,
      netYield: 4,
      equityAccessed: 0,
      modelledYear: 0,
      ispporproperty: false,
      sellYear: null,
      effective_tax_rate: 33,
      isNewProperty: true,
    }));

  };

  useEffect(() => {

  }, [scenarioName])
  const handleSave = () => {
    setSaveDialogOpen(true);
  };







  const handleSaveScenario = async () => {

    if (currentScenario) {

      dispatch(updateScenario({
        ...currentScenario,
        scenarioName,
        properties,
      }));
      const response = await SaveInvestScenario({

        scenarioName,
        modelledYear: new Date().getFullYear(),
        passiveIncomeGoal: 0,
        effectiveTaxRate: 33,
        cgtRate: 0,
        pporLoan: 0,
        properties: currentScenario.properties,
        portfolioAnalysis: {
          totalProjectedValue: 0,
          outstandingLoanAmount: 0,
          totalPassiveIncome: 0,
          isGoalAchieved: false,
          pporPayoffProgress: 0,
          passiveIncomeProgress: 0,
        }

      }, buyerAgent.investor_id)
      if (response.success) {
        setSaveDialogOpen(false);
        await fetchScenarios()
        toast.success('Scenario saved successfully')

      }
      return

    } else {

      const response = await SaveInvestScenario({

        scenarioName,
        modelledYear: new Date().getFullYear(),
        passiveIncomeGoal: 0,
        effectiveTaxRate: 33,
        cgtRate: 7,
        pporLoan: 0,
        properties,
        portfolioAnalysis: {
          totalProjectedValue: 0,
          outstandingLoanAmount: 0,
          totalPassiveIncome: 0,
          isGoalAchieved: false,
          pporPayoffProgress: 0,
          passiveIncomeProgress: 0,
        }

      }, buyerAgent.investor_id)
      if (response.success) {
        setSaveDialogOpen(false);
        await fetchScenarios()
        toast.success('Scenario saved successfully')
      }
      return
    }

    // window.location.replace('/buy-sell')
    // setScenarioRefresh(!scenarioRefresh)
  };

  const handleLoadScenario = () => {
    const selectedScenario = scenarios.find((s: any) => s.id === selectedScenarioId);
    if (selectedScenario) {
      dispatch(setCurrentScenario(selectedScenario));
      // Update properties state with the loaded scenario's properties
      selectedScenario.properties.forEach((property: any, index: number) => {
        dispatch(updateProperty({ index, data: property }));
      });
    }
  };
  console.log('currentScenario', currentScenario)
  const handleDeleteScenario = async () => {
    if (selectedScenarioId) {
      setLoading(true)
      const deletescenario = await DeleteInvestScenario(selectedScenarioId);
      if (deletescenario.success) {
        toast.success('Scenario deleted successfully');
        dispatch(deleteScenario(selectedScenarioId));
        setSelectedScenarioId(null);
        setLoading(false)
        window.location.replace('/buy-sell')
      } else {
        setLoading(false)
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transformProperties = useCallback(() => {
    return investmentProperties.map(property => {
      const {
        address,
        initialpurchaseprice: value,
        currentestimatedvalue: currentEstimated_value,
        currentloanamount,
        yearofpurchase: purchaseYear,

        ispporproperty,
        id,
        netrentalyield: netYield
      } = property;

      // Calculate the deposit
      // const deposit = property.initialpurchaseprice - currentloanamount;

      // Calculate leverage ratio
      // const leverageratio = (Number(property.initialpurchaseprice) * 1.06 - Number(deposit)) / Number(property.initialpurchaseprice) * 100;

      // Return the transformed object
      return {
        address,
        value, // initialpurchaseprice becomes value
        purchaseYear, // yearofpurchase becomes purchaseYear
        deposit: 0, // calculated as initialpurchaseprice - currentloanamount
        ispporproperty, // remains the same
        id, // remains the same,
        currentEstimated_value,
        currentloanamount,
        compoundingAnnualGrowthRate: 7,
        effective_tax_rate: 33,
        equityAccessed: 0,
        // leverageRatio: leverageratio,
        leverageRatio: 0,
        marginalTaxRate: 45,
        netYield: 4, // Convert net rental yield to number
        sellAndPayOffLoans: false,
        sellYear: 0
      };
    });
  }, [investmentProperties]);


  // useEffect(() => {
  //   const fetchData = async () => {


  //     const res1 = await GetInvestScenario(buyerAgent.investor_id);
  //     if (res1?.data[0]?.properties?.length === 0) {
  //       
  //       setScenarioName('New Investnet')
  //       const response = await SaveInvestScenario({

  //         scenarioName: 'New Investnet',
  //         modelledYear: new Date().getFullYear(),
  //         passiveIncomeGoal: 0,
  //         effectiveTaxRate: 0,
  //         cgtRate: 0,
  //         pporLoan: 0,
  //         properties: {
  //           address: "",
  //           value: "",
  //           currentEstimated_value: "",
  //           purchaseYear: 0,
  //           deposit: 0,
  //           loan: 0,
  //           leverageRatio: 0,
  //           compoundingAnnualGrowthRate: 7,
  //           marginalTaxRate: 45,
  //           netYield: 4,
  //           equityAccessed: 0,
  //           modelledYear: 0,
  //           ispporproperty: false,
  //           sellYear: null,
  //           effective_tax_rate: 33,
  //           isNewProperty: true,
  //         },
  //         portfolioAnalysis: {
  //           totalProjectedValue: 0,
  //           outstandingLoanAmount: 0,
  //           totalPassiveIncome: 0,
  //           isGoalAchieved: false,
  //           pporPayoffProgress: 0,
  //           passiveIncomeProgress: 0,
  //         }

  //       }, investorId)
  //     }

  //   }
  //   fetchData()
  // }, [buyerAgent.investor_id, investorId])

  const fetchScenarios = async () => {

    setLoading(true)
    try {
      // if (currentScenario) {
      //   
      //   setScenarioName(scenarioName)
      //   setSelectedScenarioId(currentScenario.id)
      //   setLoading(false)

      //   return
      // }
      const res2 = await GetInvestScenario(investorId);

      if (res2?.data.length === 0) {


        setScenarioName('New Investnet')
        const response = await SaveInvestScenario({

          scenarioName: 'New Investnet',
          modelledYear: new Date().getFullYear(),
          passiveIncomeGoal: 0,
          effectiveTaxRate: 0,
          cgtRate: 0,
          pporLoan: 0,
          properties: [{
            address: "",
            value: "",
            currentEstimated_value: "",
            purchaseYear: 0,
            deposit: 0,
            loan: 0,
            leverageRatio: 0,
            compoundingAnnualGrowthRate: 7,
            marginalTaxRate: 45,
            netYield: 4,
            equityAccessed: 0,
            modelledYear: 0,
            ispporproperty: false,
            sellYear: null,
            effective_tax_rate: 33,
            isNewProperty: true,
          }],
          portfolioAnalysis: {
            totalProjectedValue: 0,
            outstandingLoanAmount: 0,
            totalPassiveIncome: 0,
            isGoalAchieved: false,
            pporPayoffProgress: 0,
            passiveIncomeProgress: 0,
          }

        }, investorId)
      } else {

        const newInvestnetScenario = res2.data?.filter((s: any) => s.scenario_name === "New Investnet");

        if (newInvestnetScenario && transformProperties()?.length === 0) {
          setSelectedScenarioId(newInvestnetScenario[0]?.id);
          // setScenarioName(res.data[0]?.scenario_name)
          dispatch(addScenario(newInvestnetScenario));
          setLoading(false)
        }

      }
      const res1 = await GetInvestScenario(buyerAgent.investor_id);

      const defaultScenario = res1.data?.filter((s: any) => s.scenario_name === "Deafult Scenario");

      if (defaultScenario && transformProperties()?.length > 0) {

        await SaveInvestScenario({

          scenarioName: "Deafult Scenario",
          modelledYear: new Date().getFullYear(),
          ...res1.data[0],
          properties: transformProperties()
        }, buyerAgent.investor_id)
        setScenarioName('Deafult Scenario')
      }
      const res = await GetInvestScenario(buyerAgent.investor_id);

      if (res?.data[0]?.properties?.length > 0) {
        // setSelectedScenarioId(res.data[0]?.id || currentScenario.id)

        const sortedData = [...res.data].sort((a:any, b:any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        setSelectedScenarioId(sortedData[0]?.id ?? currentScenario?.id);
        setScenarioName(sortedData[0]?.scenario_name)
        dispatch(addScenario(res.data));
        setLoading(false)
      } else {
        if (transformProperties()?.length > 0) {

          setScenarioName('Deafult Scenario')
          const response = await SaveInvestScenario({

            scenarioName,
            modelledYear: new Date().getFullYear(),
            passiveIncomeGoal: 0,
            effectiveTaxRate: 0,
            cgtRate: 0,
            pporLoan: 0,
            properties: transformProperties(),
            portfolioAnalysis: {
              totalProjectedValue: 0,
              outstandingLoanAmount: 0,
              totalPassiveIncome: 0,
              isGoalAchieved: false,
              pporPayoffProgress: 0,
              passiveIncomeProgress: 0,
            }

          }, buyerAgent.investor_id)
        }
        // } else {
        // setScenarioName('New Investnet')
        // const response = await SaveInvestScenario({

        //   scenarioName,
        //   modelledYear: new Date().getFullYear(),
        //   passiveIncomeGoal: 0,
        //   effectiveTaxRate: 0,
        //   cgtRate: 0,
        //   pporLoan: 0,
        //   properties: {
        //     address: "",
        //     value: "",
        //     currentEstimated_value: "",
        //     purchaseYear: 0,
        //     deposit: 0,
        //     loan: 0,
        //     leverageRatio: 0,
        //     compoundingAnnualGrowthRate: 7,
        //     marginalTaxRate: 45,
        //     netYield: 4,
        //     equityAccessed: 0,
        //     modelledYear: 0,
        //     ispporproperty: false,
        //     sellYear: null,
        //     effective_tax_rate: 33,
        //     isNewProperty: true,
        //   } as ScenarioProperty,
        //   portfolioAnalysis: {
        //     totalProjectedValue: 0,
        //     outstandingLoanAmount: 0,
        //     totalPassiveIncome: 0,
        //     isGoalAchieved: false,
        //     pporPayoffProgress: 0,
        //     passiveIncomeProgress: 0,
        //   }

        // }, investorId)
        // const res = await dispatch(addScenario({

        //   scenario_name: 'New Investnet',
        //   modelled_year: new Date().getFullYear(),
        //   passive_income_goal: 0,
        //   effective_tax_rate: 0,
        //   cgt_rate: 0,
        //   ppor_loan: 0,
        //   properties: [] as ScenarioProperty[],
        //   portfolio_analysis: {
        //     totalProjectedValue: 0,
        //     outstandingLoanAmount: 0,
        //     totalPassiveIncome: 0,
        //     isGoalAchieved: false,
        //     pporPayoffProgress: 0,
        //     passiveIncomeProgress: 0,
        //   },
        //   user_id: investorId,
        //   sellYear: 0

        // }))
        // handleAddProperty()
        // }
      }
    } catch (error) {
      setLoading(false)

      console.error("Error fetching scenarios:", error);
    }
  };
  console.log('selectedScena', selectedScenarioId)

  useEffect(() => {
    const initialAgentId = JSON.parse(sessionStorage.getItem('investorData') || '{}').investor_id;
    if (currentScenario?.user_id !== initialAgentId) {

      fetchScenarios();
    }
  }, [currentScenario?.user_id, dispatch, transformProperties]); // Only run once when the component mounts or when 'dispatch' changes


  const handleNext = async () => {

    if (buyerAgent) {
      // navigate(`/buy-sell-modelling?agentId=${buyerAgent.investor_id}&scenarioId=${selectedScenarioId || currentScenario?.id}`);
      navigate(`/buy-sell-modelling?agentId=${buyerAgent.investor_id}&scenarioId=${selectedScenarioId || currentScenario?.id}`, {
        state: { fromBuyersAgentDashboard: true }
      });
    } else {
      // navigate(`/buy-sell-modelling?scenarioId=${selectedScenarioId || currentScenario?.id}`);
      navigate(`/buy-sell-modelling?scenarioId=${selectedScenarioId || currentScenario?.id}`, {
        state: { fromBuyersAgentDashboard: false }
      });
    }
  };

  const handleScenarioChange = (event: any) => {
    setSelectedScenarioId(event.target.value as string)

    // const scenerioname = selectedScenario[0]?.filter((sid: any) => sid.id === event.target.value)
    // 
    // setScenarioName(scenerioname?.scenario_name)

  }

  // const fetchSceneraioName = async () => {
  //   setScenarioName(selectedScenario?.scenario_name as string || scenarioName)
  // }

  // useEffect(() => {
  //   fetchSceneraioName()
  // }, [fetchSceneraioName, selectedScenario])
  const handleSetSceneraioName = (event: any) => {
    setScenarioName(event.target.value as string)
    setCurrentScenarionNAme(event.target.value as string)
  }
  useEffect(() => {
    setCurrentScenarionNAme(selectedScenario?.scenario_name as string)
  }, [selectedScenario])
  useEffect(() => {
    if (!currentScenario) {

      fetchScenarios()

    }

  }, [transformProperties])
  useEffect(() => {
    if (transformProperties()?.length === 0) {
      setSelectedScenarioId(scenarios?.id)
    }
  }, [scenarios?.id, transformProperties])
  return (
    <Formik
      initialValues={{ investmentProperties: properties }}
      validationSchema={InvestmentPropertiesSchema}
      onSubmit={(values) => console.log(values)}
    >
      {({ validateForm }) => (
        <Container>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            textAlign="center"
            marginTop={4}
            sx={{ color: colors.grey[100], py: 2 }}
          >
            Investnet Scenario
          </Typography>

          {/* Scenario Management UI */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4, color: colors.grey[100] }}>
            <Select
              value={selectedScenarioId || 'loading....'}
              onChange={(e) => handleScenarioChange(e)}
              displayEmpty
              sx={{ minWidth: 200 }}
            >
              {/* {transformProperties()?.length === 0 ? (
                <MenuItem value='' disabled>{scenarios?.scenario_name}</MenuItem>

              ) : (
                <> */}
              <MenuItem value="" disabled>Select a scenario</MenuItem>
              {scenarios?.map((scenario: any) => {

                // Check if scenario_name is not empty before rendering
                if (scenario.scenario_name !== '') {
                  return (
                    <MenuItem key={scenario.id} value={scenario.id}>
                      {scenario.scenario_name}
                    </MenuItem>
                  );
                }
                // Return null or nothing if the scenario_name is empty
                return null;
              })}
              {/* </>

              )} */}


            </Select>
            <Box>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleLoadScenario}
                startIcon={<FolderOpenIcon />}
                sx={{ mr: 2 }}
                disabled={!selectedScenarioId}
              >
                Load Scenario
              </Button> */}
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteScenario}
                startIcon={<DeleteIcon />}
                disabled={!selectedScenarioId}
              >
                Delete Scenario
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>

                {properties?.map((property: any, index: number) => {

                  return (

                    <InvestedPropertyColumn
                      key={property.id}
                      index={index}
                      scenario={currentScenario ? currentScenario : selectedScenario}
                      isNewProperty={property.isNewProperty || false}

                    // defaultProperty={investmentProperties}

                    />
                  )
                })}


                {properties && properties?.length < 20 && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <Box
                      padding={2}
                      boxShadow={3}
                      bgcolor="background.paper"
                      borderRadius={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                      sx={{ backgroundColor: colors.primary[400] }}
                    >
                      <IconButton onClick={handleAddProperty} size="large">
                        <AddIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 4,
              color: colors.grey[100],
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              startIcon={<BookmarkAddedOutlinedIcon />}
            >
              Save Scenario
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </Button>
          </Box>

          <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
            <DialogTitle>Save Scenario</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="scenarioName"
                label="Scenario Name"
                type="text"
                fullWidth
                variant="standard"
                value={currentScenarioName}
                // onChange={(e) => setScenarioName(e.target.value)}
                onChange={(e) => handleSetSceneraioName(e)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleSaveScenario}>Save</Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </Formik>
  );
};

export default BuyAndSellPage;