import React, { useEffect, useState } from 'react';
import { Auth } from "aws-amplify";
import { Box, Typography, Button, Card, CardMedia, CardContent, Grid, useTheme, Link, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { tokens } from '../../../theme';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AuthenticatedAPI from "../../../auth_helper";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { StreetViewImage } from '../../../hooks/properties/properties';
import PortfolioSummary from '../PortfolioSummary';
const getPropertyImage = (address: string | number | boolean) => `https://via.placeholder.com/150?text=${encodeURIComponent(address)}`;


interface ProfileData {
  financialGoal?: any[];

  familyInfo?: { dependentKids: any[], earningMembers: any[] };
  employmentInfo?: any[];
  currentPortfolio?: { investmentProperties: any[] };
  investmentStrategy?: any;
  [key: string]: any;
}

interface Property {
  propertyId: number;
  address: string;
  capRate: number;
  classification: string;
  dscr: number;
  ltv: number;
  roi: number;
}
const Investments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [loading, setLoading] = useState(true);
  const [propertyImages, setPropertyImages] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<string[]>([])
  // Dummy data for investments
  const navigate = useNavigate()



  // This is a dummy function that you'll replace with actual functionality
  const handleAddProperty = () => {
    console.log("Add Property Clicked");
  };



  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [portfolioData, setPortfolioData] = useState<Property[]>([]);
  console.log(profileData, "profileDataprofileDataprofileData")

  const getUserProfile = async () => {
    try {
      const userData = await Auth?.currentAuthenticatedUser().then(async (res) => {
        const response = await AuthenticatedAPI.request(
          'InvestmentAPI',
          'get',
          `/api/profile/${res.username}`
        );

        if (!response) {
          setLoading(false)
          throw new Error('Failed to fetch user profile');
        }
        if (response) {
          setLoading(false)
          setProfileData(response.data)

        }
        return response;
      })
    } catch (error) {
      setLoading(false)
      console.error('Error fetching user profile:', error);
    }
  };

  const getPortFolioAnalysis = async () => {
    const data1 = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'get',
        `/api/portfolio-analysis/${res.username}`
      );
      setPortfolioData(response.data)
      console.log('responseportfoliooo111111', response)

    })
  }


  useEffect(() => {
    if (profileData?.currentPortfolio?.investmentProperties) {
      const newAddresses = profileData.currentPortfolio.investmentProperties.map(val => val.address);
      setAddresses(newAddresses);

    }
  }, [profileData]);
  const img = StreetViewImage(addresses)
  console.log('img :>> ', img);
  useEffect(() => {
    if (img) {
      setPropertyImages(img)
    }
  }, [propertyImages, img])
  useEffect(() => {
    getPortFolioAnalysis()
  }, [])

  useEffect(() => {
    getUserProfile();

  }, []);

  const getPropertyDetailHandler = (id: string) => {

    navigate(`/property-details?id=${id}`)
  }

  return (
    <Box className="p-6">
      <Grid item md={12} mb={0}>
          <Typography variant="h3" align="left" zIndex={1} gutterBottom
            sx={{
              fontWeight: "700",
            }}>
            Portfolio 
          </Typography>
        </Grid> 
      <PortfolioSummary />
      <Grid container spacing={3} sx={{paddingTop:"30px"}}>

        {/* <Grid item md={12} mb={0}>
          <Typography variant="h3" align="left" zIndex={1} gutterBottom
            sx={{
              fontWeight: "700",
            }}>
            Portfolio Analysis
          </Typography>
        </Grid> */}
        <Grid item xs={12} >
          
          <Card
            sx={{
              padding: "20px 20px !important",
              marginBottom: "30px !important",
              borderRadius: "15px !important",
              boxShadow: "none !important",
              backgroundColor: colors.primary[400],
              justifyContent: `${loading && 'center'}`,
              alignItems: `${loading && 'center'}`,
            }}>
              <Typography
            variant="h4"
            align="left"
            pb={2}
            zIndex={1}
            gutterBottom
            sx={{
              fontWeight: "700",
            }}
          >
             Analysis
          </Typography>
            <Grid container spacing={3} columns={12} alignItems="center" sx={{

              justifyContent: `${loading && 'center'}`,
              alignItems: `${loading && 'center'}`,
            }}>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                  <CircularProgress />
                </Box>
              ) : (
                profileData?.currentPortfolio?.investmentProperties && profileData?.currentPortfolio?.investmentProperties?.length > 0 ? (
                  profileData.currentPortfolio.investmentProperties.map((property, index) => {
                    const propertyData = portfolioData.find(p => p.propertyId === property.id);
                    return (

                      <Grid item xs={12} md={6} lg={4} >
                        <Card
                          className="propertyBox"
                          sx={{
                            padding: "18px !important",
                            marginBottom: "0px !important",
                            borderRadius: "15px !important",
                            boxShadow: "none !important",
                            backgroundColor: "rgb(91 192 190 / 10%) !important",
                            cursor: "pointer",
                          }}
                          onClick={() => getPropertyDetailHandler(property.id)}

                        >
                          <Box className="property_img">
                            <div className="relative w-full">
                              {/* Image */}
                              <img
                                className="w-full"
                                src={propertyImages[index]}
                                alt="profile-user"
                              />

                              {/* Overlayed PPOR Label */}
                              {property.ispporproperty && (
                                <Typography
                                  sx={{
                                    position: 'absolute',
                                    bottom: '1px',
                                    left: '115px',
                                    padding: "2px 8px",
                                    backgroundColor: "#4cceac",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                    color: '#fff',
                                    textAlign: "center",
                                  }}
                                >
                                  PPOR
                                </Typography>
                              )}
                            </div>

                          </Box>
                          <Box className="property_content">
                            <Typography variant="h5" align="left" mb={1} zIndex={1} gutterBottom
                              sx={{
                                fontWeight: "700",
                              }}>
                              {/* {investment.name} */}
                              {property.address}
                            </Typography>
                            <Typography variant="body1" align="left" mb={2} zIndex={1} gutterBottom
                              sx={{
                                fontSize: "12px",
                                fontWeight: "600",
                              }}>
                              {/* {property.address} */}
                              Estimated value: {property.currentestimatedvalue}
                            </Typography>
                            {!property.ispporproperty && <Button
                                variant="contained"
                                color={
                                  propertyData &&
                                    propertyData.classification ===
                                    "Performing Well"
                                    ? "success"
                                    : propertyData &&
                                      propertyData.classification === "Average"
                                      ? "warning"
                                      : propertyData &&
                                        propertyData.classification === "Risky"
                                        ? "error"
                                        : "info"
                                }
                                endIcon={<ErrorOutlineIcon />}
                                sx={{
                                  borderRadius: "50px",
                                  fontSize: "12px",
                                  textTransform: "capitalize",
                                  color: "#ffffff",
                                  cursor: "none",
                                }}
                              >
                                {propertyData && propertyData.classification}
                              </Button>}

                          </Box>
                        </Card>
                      </Grid>
                    )
                  }
                  )
                ) : (
                  <Typography variant="h5">No data found</Typography>
                )
              )}
            </Grid>
          </Card>
        </Grid>


      </Grid>
      {/* Include your Google Maps component here */}
      {/* This is commented out as it requires setup of Google Maps API */}
      {/* <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={{ lat: -37.9220, lng: 145.3443 }}
          zoom={10}
        >
          {investmentData.map(investment => (
            <Marker key={investment.id} position={{ lat: investment.lat, lng: investment.lng }} />
          ))}
        </GoogleMap>
      </LoadScript> */}
    </Box>
  );
};

export default Investments;
