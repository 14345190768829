import React, { useEffect, useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapIcon from "@mui/icons-material/Map";
import SavingsIcon from "@mui/icons-material/Savings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { tokens } from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { logout, updatePersonalInfo } from "../../../features/userSlice";
import { RootState } from "../../../store";
import {
  CreateBuyerAgent,
  getImageByName,
  GetAssociatedBuyersAgent,
} from "../../../hooks/userprofile/user";
import AuthenticatedAPI from "../../../auth_helper";

interface Props {
  title: string;
  to?: string;
  icon: any;
  selected: string;
  setSelected: any;
  onClick?: () => Promise<void>;
}


interface BuyerAgentProfile {
  userid: string;
  name: string;
  email: string;
  logo_url: string;
  theme_color: string;
}
const Item = ({ title, to, icon, selected, onClick, setSelected }: Props) => {
  const handleItemClick = () => {
    setSelected(title);
    localStorage.setItem("selectedRoute", to || "");
    localStorage.setItem("selectedTitle", title);
    if (onClick) {
      onClick();
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleItemClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      {to && <Link to={to} />}
    </MenuItem>
  );
};

const Sidebar = () => {
  const auth = useSelector((state: RootState) => state.user.personalInfo);
  console.log('auth', auth)
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<BuyerAgentProfile | null>(null);
  console.log('profile111', profile)
  const [comapnyName, setCompanyName] = useState("");

  const [buyersAgent, setBuyersAgent] = useState<{
    logo_url: string | null;
    theme_color: string | null;
    company_name: string | null;
  } | null>(null);
  console.log('buyersAgent', buyersAgent)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [userFirstName, setUserFirstName] = useState("");
  const [themeColor, setThemeColor] = useState("");
  console.log('themeColor', themeColor)
  // Extract the first letter
  const firstLetter = userFirstName?.charAt(0)?.toUpperCase();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      sessionStorage.removeItem('investorData')
      dispatch(logout());
      const origin = localStorage.getItem("company_origin");
      if (origin) {
        window.location.href = origin;
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const savedState = localStorage.getItem("sidebarCollapsed");
    return savedState ? JSON.parse(savedState) : true;
  });

  const [selected, setSelected] = useState(() => {
    const savedTitle = localStorage.getItem("selectedTitle");
    if (savedTitle) {
      return savedTitle;
    }
    const path = location.pathname.split("/")[1];
    return path ? path?.charAt(0).toUpperCase() + path.slice(1) : "Home";
  });

  useEffect(() => {
    const path = location.pathname;
    const activeRoute = path.split("/")[1];
    const savedTitle = localStorage.getItem("selectedTitle");
    if (!savedTitle) {
      setSelected(activeRoute?.charAt(0).toUpperCase() + activeRoute.slice(1));
    } else {
      setSelected(savedTitle);
    }
    localStorage.setItem("selectedRoute", path);
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);


  const fetchAssociatedBuyersAgent = async () => {
    try {
      const response = await GetAssociatedBuyersAgent();

      if (response) {
        console.log("agentData", response);
        // setBuyersAgent(response.data);
      }
    } catch (error) {
      console.error("Error fetching associated buyer's agent:", error);
    }
  };
  useEffect(() => {
    fetchAssociatedBuyersAgent();
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {

      try {
        const user = await Auth?.currentAuthenticatedUser();
        if (user) {
          const userAttributes = await Auth.userAttributes(user);
          const company_name =
            userAttributes.find((attr) => attr.Name === "custom:company_name")
              ?.Value ?? "";
          const company_origin =
            userAttributes.find((attr) => attr.Name === "custom:origin")
              ?.Value ?? "";
          localStorage.setItem("company_origin", company_origin);

          setCompanyName(company_name);
          setUserFirstName(user.attributes.given_name);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    };
    getUserInfo();
  }, [userFirstName]);

  useEffect(() => {
    const checkUserGroup = async () => {

      try {
        const user = await Auth.currentAuthenticatedUser();

        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"] || [];

        setUserGroup(
          groups.includes("InvestarPartner") ? "InvestarPartner" : "REGULAR"
        );
        if (userGroup === "InvestarPartner") {
          if (auth.id) {
            try {
              const user = await Auth.currentAuthenticatedUser();
              const userAttributes = await Auth.userAttributes(user);
              const id =
                userAttributes.find((attr) => attr.Name === "sub")?.Value ?? "";
              const email =
                userAttributes.find((attr) => attr.Name === "email")?.Value ??
                "";
              const company_name =
                userAttributes.find(
                  (attr) => attr.Name === "custom:company_name"
                )?.Value ?? "";
              const contact_name =
                userAttributes.find(
                  (attr) => attr.Name === "custom:primary_contact_name"
                )?.Value ?? "";

              dispatch(
                updatePersonalInfo({
                  id,
                  email,
                  phone_number: "",
                  first_name: contact_name,
                  last_name: company_name,
                })
              );
              await CreateBuyerAgent({ id, email, company_name, contact_name });
            } catch (error) {
              console.log("Error fetching user data:", error);
            }
          }
        } else {
          const userId = user.attributes.sub;
          console.log("userId", userId);
          const name = user.attributes.given_name;
          const email = user.attributes.email;
          const response = await AuthenticatedAPI.request(
            "BuyersAgentsAPI",
            "get",
            `/agents/buyers-agent/${userId}`
          );
          console.log(response, "sdfd");
          if (response && response) {
            setBuyersAgent({ ...response[0], name: name, email: email });
          } else {
            throw new Error("Failed to fetch buyer agent profile");
          }
        }
      } catch (error) {
        console.error("Error checking user group:", error);
      }
      setLoading(false);
    };

    checkUserGroup();
  }, [auth.id, dispatch, userGroup]);

  useEffect(() => {
    console.log('buyersAgent?.theme_color44444', buyersAgent?.theme_color)
  }, [buyersAgent?.theme_color])

  console.log('buyersAgent?.theme_color44444', buyersAgent?.theme_color)

  useEffect(() => {
    const GetAssociatedBuyersAgent = async () => {
      try {
        await Auth?.currentAuthenticatedUser().then(async (res) => {

          if (!res) {
            throw new Error('No authenticated user found');
          }

          const response = await AuthenticatedAPI.request(
            'InvestmentAPI',
            'get',
            `/api/investor/associated-agent/${res.username}`
          );

          if (!response) {
            console.log('Failed to fetch associated buyer\'s agent');
          }
          console.log('respons34534534e', response)
          setThemeColor(response.data?.theme_color)
          return response;
        });

      } catch (error) {
        console.log('Error fetching associated buyer\'s agent:', error);
        return null;
      }
    };
    GetAssociatedBuyersAgent()
  }, [])
  // useEffect(()=>{
  // const fetchProfile = async () => {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     console.log("usesdfr", user);
  //     const userId = user.attributes.sub; // Assuming the user ID is stored in the 'sub' attribute
  //     console.log("userId", userId);
  //     const name = user.attributes.given_name;
  //     const email = user.attributes.email;
  //     const response = await AuthenticatedAPI.request(
  //       "BuyersAgentsAPI",
  //       "get",
  //       `/agents/buyers-agent/${userId}`
  //     );
  //     console.log(response, "sdfd");
  //     if (response && response) {
  //       setProfile({ ...response[0], name: name, email: email });
  //     } else {
  //       throw new Error("Failed to fetch buyer agent profile");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching buyer agent profile:", error);
  //   }
  // };
  // fetchProfile()
  // },[])

  console.log('coma', comapnyName)
  return (
    <Box
      className="sidebar"
      style={{
        width: isCollapsed ? "70px" : "220px",
        minWidth: isCollapsed ? "70px" : "220px",
      }}
      sx={{
        "& .pro-sidebar-inner": {
          background: `${buyersAgent?.theme_color ? buyersAgent?.theme_color : themeColor || '#1945C3'
            } !important`,
          height: "100vh",
          display: "flex",
          position: "sticky",
          justifyContent: "center",
          zIndex: 1000,
          top: 0,
          bottom: 0,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 15px 5px 15px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#eee !important",
        },
        "& .pro-menu-item.active": {
          color: "black !important",
        },
        "& .pro-menu-item": {
          color: "white !important",
        },
        // Add these styles to prevent scrollbars
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        style={{
          height: "100%", // Changed from 100vh to 100%
          width: isCollapsed ? "70px" : "220px",
          minWidth: isCollapsed ? "70px" : "220px",
          backgroundColor: `${buyersAgent?.theme_color ? buyersAgent?.theme_color : themeColor || "#1945C3"}` || colors.primary[400],
        }}
      >
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="0px"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90px",
                    height: "80px",
                    overflow: "hidden",
                    background: "transparent",
                    // borderRadius: "50%",
                    // border: "2px solid #07e6c8",
                    padding: " 5px"
                  }}
                >
                  {/* {comapnyName === "KevTranGroup" ? (

                    <img
                      alt="profile-user"
                      src={

                        getImageByName(comapnyName, theme)
                      }
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "auto",
                        maxHeight: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                    />
                  ) : ( */}

                  <img
                    alt="profile-user"
                    src={
                      buyersAgent?.logo_url ||
                      getImageByName(comapnyName, theme)
                    }
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "auto",
                      maxHeight: "100%",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                  {/* )} */}
                </Box>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {isCollapsed && (
            <Box mb="25px">
              <Box
                // display="flex"
                // justifyContent="center"
                // alignItems="center"
                // width="60px"
                // height="60px"
                // margin="0 auto"
                // overflow="hidden"

                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "60px",
                  height: "60px",
                  overflow: "hidden",
                  background: "transparent",
                  borderRadius: "50%",
                  margin: "0 auto",
                  border: "2px solid #07e6c8",
                }}
              >
                <img
                  alt="profile-user"
                  src={
                    buyersAgent?.logo_url || getImageByName(comapnyName, theme)
                  }
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "auto",
                    maxHeight: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              </Box>
              {/* <Box textAlign="center">
                {isCollapsed ? (
                  <Typography
                    variant="h2"
                    // color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{
                      color: "#ffffff",
                      m: "10px auto 0",
                      width: "50px",
                      height: "50px",
                      lineHeight: "50px",
                      borderRadius: "50%",
                      backgroundColor: "#4cceac",
                      border: "1px solid #fff",
                      boxShadow: "0px 6px 6px -3px rgb(0 0 0 / 20%)",
                    }}
                  >
                    {firstLetter}
                  </Typography>
                ) : (
                  <Typography
                    variant="h6"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {userFirstName}
                  </Typography>
                )}
              </Box> */}
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            {userGroup && userGroup === "InvestarPartner" ? (
              <>
                <Item
                  title="Home"
                  to="/ba-dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Profile"
                  to="/ba-profile"
                  icon={<AccountBoxIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Add Investors"
                  to="/add-investor"
                  icon={<PersonAddAlt1Icon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            ) : (
              <>
                <Item
                  title="Home"
                  to="/dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Roadmap"
                  to="/goal-setting"
                  icon={<MapIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Invest Net"
                  to="/buy-sell"
                  icon={<RequestQuoteIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Portfolio"
                  to="/portfolio"
                  icon={<SavingsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Cashflow"
                  to="/cashflow"
                  icon={<MonetizationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {/* <Item
                  title="Advisors"
                  to="/advisor"
                  icon={<GroupsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
              </>
            )}
            <Item
              onClick={handleLogout}
              title="Logout"
              icon={<LogoutIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
