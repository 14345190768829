import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvestNetScenario, ScenarioProperty } from '../types/BuySell';

interface ScenariosState {
  scenarios: InvestNetScenario[];
  currentScenario: InvestNetScenario | null; // Allowing null for flexibility
  scenarioProperty: ScenarioProperty[]; // Array of properties in the current scenario
}

let nextPropertyId = 4;

const initialState: ScenariosState = {
  scenarios: [],
  scenarioProperty: [],
  currentScenario: null, // Set as null to allow for flexible state management
};

const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    addScenario: (state, action: PayloadAction<InvestNetScenario>) => {
      state.scenarios = []
      state.scenarios.push(action.payload);
    },
    updateScenario: (state, action: PayloadAction<any>) => {
      const index = state.scenarios.findIndex(s => s.id === action.payload.id);
      if (index !== -1) {
        state.scenarios[index] = action.payload;
      }

    },
    deleteScenario: (state, action: PayloadAction<string>) => {
      state.scenarios = state.scenarios?.filter(s => s.id !== action.payload);
    },
    setCurrentScenario: (state, action: PayloadAction<InvestNetScenario | null>) => {
      if (!action.payload) {
        state.scenarioProperty = [];
      } else {
        // Sync the scenarioProperty with the properties of the current scenario
        state.currentScenario = action.payload;
        state.scenarioProperty = action.payload.properties ?? [];
      }

      // state.scenarioProperty = action.payload?.properties ?? [];
      // Sync the current properties
    },

    // addProperty: (state, action: PayloadAction<ScenarioProperty>) => {
    //   
    //   state.scenarioProperty.push({ ...action.payload, id: nextPropertyId++ });
    // },
    addProperty: (state, action: PayloadAction<Omit<ScenarioProperty, 'id'>>) => {

      const newProperty = { ...action.payload, id: nextPropertyId++ };

      // Push the new property to scenarioProperty array
      state.scenarioProperty?.push(newProperty);

      // Update the currentScenario's properties
      if (state.currentScenario) {
        state.currentScenario?.properties?.push(newProperty);
      }

      // Update the scenario in the main scenarios array
      const scenarioIndex = state.scenarios.findIndex(
        (scenario) => scenario?.id === state.currentScenario?.id
      );

      if (scenarioIndex !== -1) {
        state.scenarios[scenarioIndex].properties?.push(newProperty);
      }
    },

    // // updateProperty: (state, action: PayloadAction<ScenarioProperty>) => {
    // //   const updatedProperty = action.payload;

    // //   // 1. Update in scenarioProperty array
    // //   const propertyIndex = state.scenarioProperty.findIndex(prop => prop.id === updatedProperty.id);
    // //   if (propertyIndex !== -1) {
    // //     state.scenarioProperty[propertyIndex] = updatedProperty;
    // //   }

    // //   // 2. Update in currentScenario's properties array
    // //   if (state.currentScenario) {
    // //     const currentPropertyIndex = state.currentScenario.properties.findIndex(prop => prop.id === updatedProperty.id);
    // //     if (currentPropertyIndex !== -1) {
    // //       state.currentScenario.properties[currentPropertyIndex] = updatedProperty;
    // //     }
    // //   }

    // //   // 3. Update in scenarios array
    // //   const scenarioIndex = state.scenarios.findIndex(scenario => scenario.id === state.currentScenario?.id);
    // //   if (scenarioIndex !== -1) {
    // //     const propertyInScenarioIndex = state.scenarios[scenarioIndex].properties.findIndex(prop => prop.id === updatedProperty.id);
    // //     if (propertyInScenarioIndex !== -1) {
    // //       state.scenarios[scenarioIndex].properties[propertyInScenarioIndex] = updatedProperty;
    // //     }
    // //   }
    // // },
    updateProperty: (state, action: PayloadAction<{ updatedProperty: ScenarioProperty; field: keyof ScenarioProperty; value: any; index: number }>) => {
      const { updatedProperty, field, value, index } = action.payload;
      // 1. Update in `scenarioProperty` array
      if (index !== -1 && state.scenarioProperty[index]) {
        state.scenarioProperty[index] = {
          ...state.scenarioProperty[index],
          [field]: value, // Update only the specific field
        };

        // if (field === 'deposit') {
        //   
        //   const propertyValue = state.scenarioProperty[index].value || 0;
        //   const propertyDeposit = state.scenarioProperty[index].deposit || 0;
        //   const leverageRatio =
        //     ((parseFloat(propertyValue.toString()) * 1.06 - propertyDeposit) /
        //       parseFloat(propertyValue.toString())) *
        //     100;
        //   // Recalculate inside the reducer logic
        //   state.scenarioProperty[index].leverageRatio = leverageRatio;
        // }
      }

      // 2. Update in `currentScenario.properties` array
      if (state.currentScenario && index !== -1 && state.currentScenario.properties[index]) {
        state.currentScenario.properties[index] = {
          ...state.currentScenario.properties[index],
          [field]: value, // Update only the specific field
        };
      }

      // 3. Update in the corresponding scenario's properties array in the `scenarios` array
      if (state.currentScenario) {
        const scenarioIndex = state.scenarios.findIndex(
          scenario => scenario?.id === state.currentScenario?.id
        );
        if (scenarioIndex !== -1) {
          const properties = state.scenarios[scenarioIndex].properties;
          if (index !== -1 && properties[index]) {
            properties[index] = {
              ...properties[index],
              [field]: value, // Update only the specific field
            };
          }
        }
      }
    },
    removeProperty: (state, action: PayloadAction<number>) => {
      const index = action.payload;

      // 1. Remove from `scenarioProperty` array
      if (index !== -1 && state.scenarioProperty[index]) {
        state.scenarioProperty.splice(index, 1);
      }

      // 2. Remove from `currentScenario.properties` array
      if (state.currentScenario && index !== -1 && state.currentScenario.properties[index]) {
        state.currentScenario.properties.splice(index, 1);
      }

      // 3. Remove from the corresponding scenario's properties array in the `scenarios` array
      if (state.currentScenario) {
        const scenarioIndex = state.scenarios.findIndex(
          scenario => scenario.id === state.currentScenario?.id
        );
        if (scenarioIndex !== -1) {
          const properties = state.scenarios[scenarioIndex].properties;
          if (index !== -1 && properties[index]) {
            properties.splice(index, 1);
          }
        }
      }
    }

  },
});

export const { addScenario, updateScenario, deleteScenario, setCurrentScenario, addProperty, updateProperty, removeProperty } = scenariosSlice.actions;
export default scenariosSlice.reducer;
