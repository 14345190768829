import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Bar, Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Theme } from '@mui/material/styles';
import html2canvas from "html2canvas";
import { start } from "repl";
import { Chart, ChartItem } from "chart.js";
import { ApexOptions } from "apexcharts";
const A4_WIDTH = 210; // mm
const A4_HEIGHT = 297; // mm
const PAGE_MARGIN = 10; // mm

interface InvestmentProperty {
    address: string;
    currentestimatedvalue: number;
    netrentalyield: number;
    currentloanamount: number;
    initialpurchaseprice: number;
    yearofpurchase: number;
    weeklyrent: number;

}
export async function SimplePdf() {
    const doc = new jsPDF({ format: 'a4' });
    doc.text('Hello world!', 10, 10);
    doc.save('SimpleReport.pdf');
}
export async function CashFlowPdf(cashFlowAnalysis: any, areaChartFlow: any, propertyTitle: any) {
    let startY = PAGE_MARGIN;
    const pageHeight = A4_HEIGHT - 2 * PAGE_MARGIN;
    const doc = new jsPDF({ format: [A4_WIDTH, A4_HEIGHT] });

    function addContent(contentHeight: number, contentCallback: () => void) {
        const currentPageNumber = (doc as any).internal.getNumberOfPages();
        doc.setFont("Helvetica", "normal");
        addFooter(doc, currentPageNumber);

        if (startY + contentHeight > pageHeight) {
            doc.addPage();
            startY = PAGE_MARGIN;
            doc.setFont("Helvetica", "normal");
            addFooter(doc, currentPageNumber + 1);
        }

        contentCallback();
        startY += contentHeight;
    }


    try {
        console.log("cashFlowAnalysis:", cashFlowAnalysis);

        const cashFlowProjection: any = cashFlowAnalysis.keyAssumptions;
        const CapitalAndCashTable = cashFlowAnalysis.cashFlowProjection.scenario1.cashFlowYears;
        const beforeTaxData = cashFlowAnalysis.CashflowChart.map((elem: any) => (elem.beforeTaxCashFlow))
        const growthTaxData = cashFlowAnalysis.GrowthChart.map((elem: any) => elem.equity)
        // Add Property Title (center aligned and styled)
        addContent(0, () => {
            const title = propertyTitle || "No Property Selected"; // Check if propertyTitle is null or undefined
            doc.setFont("Helvetica", "bold");
            doc.setFontSize(20);
            const titleWidth = doc.getStringUnitWidth(title) * (doc as any).internal.getFontSize() / doc.internal.scaleFactor;
            const titleX = (A4_WIDTH - titleWidth) / 2;
            doc.text(title, titleX, startY); // Centered title
            startY += 15;
        });

        addContent(0, () => {
            doc.setFont("Helvetica", "bold");
            doc.setFontSize(15);
            doc.text("Cash Flow Projection", PAGE_MARGIN, startY);
            const tableStartY = startY + 5;
            autoTable(doc, {
                startY: tableStartY,
                head: [['Key Assumptions', 'Value']],
                body: [
                    ['Capital Growth Rate', `${cashFlowProjection.capitalGrowthRate}%`],
                    ['Rental Growth Rate', `${cashFlowProjection.inflationRate}%`],
                    ['Inflation Rate', `${cashFlowProjection.rentalGrowthRate}%`],
                    ['Vacancy Period', `${cashFlowProjection.vacancyPeriod} weeks`]
                ],
                margin: { top: 5, bottom: 10 },
                pageBreak: 'auto',
                theme: 'grid',
                headStyles: {
                    fillColor: [64, 64, 64],
                    fontSize: 12,
                    fontStyle: 'bold',
                    halign: 'center',
                    valign: 'middle',
                    textColor: [255, 255, 255],
                },
                alternateRowStyles: {
                    fillColor: [255, 255, 255],
                },
                styles: {
                    minCellHeight: 15,
                    overflow: 'linebreak',
                    fontSize: 11,
                    halign: 'left',
                    valign: 'middle',
                    cellPadding: 4,
                    lineColor: [0, 0, 0],
                    lineWidth: 0.5,
                },
                columnStyles: {
                    0: { cellWidth: 80 },
                    1: { cellWidth: 60 },
                },
                didDrawCell: function (data) {
                    startY = data.cell.y + data.cell.height + 10;
                },
            });

            startY = Math.max(startY, tableStartY + 60);
        });

        addContent(20, () => {
            doc.setFont("Helvetica", "bold");
            doc.setFontSize(15);
            doc.text("Capital Growth", PAGE_MARGIN, startY);
            autoTable(doc, {
                startY: startY + 5,
                head: [
                    [
                        'Year', 'Property Value', 'Loan Amount', 'Equity', 'Gross Rent',
                        'Principal & Interest Repayment', 'Rental Expenses',
                        'Before Tax Cash Flow', 'Cash/Income pw'
                    ]
                ],
                body: CapitalAndCashTable.map((item: any) => [
                    item.year,
                    item.propertyValue == 0 ? '0' : `$${parseFloat(item.propertyValue).toFixed(0)}`,
                    item.loanAmount == 0 ? '0' : `$${parseFloat(item.loanAmount).toFixed(0)}`,
                    item.equity == 0 ? '0' : `$${parseFloat(item.equity).toFixed(0)}`,
                    item.grossRent == 0 ? '0' : `$${parseFloat(item.grossRent).toFixed(0)}`,
                    item.PrincipalInterestRepayment == 0 ? '0' : `$${parseFloat(item.PrincipalInterestRepayment).toFixed(0)}`,
                    item.rentalExpenses == 0 ? '0' : `$${parseFloat(item.rentalExpenses).toFixed(0)}`,
                    item.beforeTaxCashFlow == 0 ? '0' : `$${parseFloat(item.beforeTaxCashFlow).toFixed(0)}`,
                    item.costIncomePw == 0 ? '0' : `$${parseFloat(item.costIncomePw).toFixed(0)}`
                ]),
                margin: { top: 5, bottom: 20 },
                pageBreak: 'auto',
                headStyles: {
                    fillColor: [64, 64, 64],
                    fontSize: 12,
                    fontStyle: 'bold',
                    halign: 'center',
                    valign: 'middle',
                    textColor: [255, 255, 255],
                },
                didDrawCell: function (data) {
                    startY = data.cell.y + data.cell.height + 10;
                },
            });
        })

        addContent(20, () => {
            doc.setFont("Helvetica", "bold");
            doc.setFontSize(15);
            doc.text("Principal & Interest Loan", PAGE_MARGIN, startY);
        });

        try {
            const principalChart: any = await GenerateChart('area1', areaChartFlow);
            const cashflowChart: any = await GenerateChart('area', CapitalAndCashTable);
            const growthChart: any = await GenerateChart('area2', CapitalAndCashTable);
            if (principalChart) {
                addContent(100, () => {
                    doc.addImage(principalChart, 'PNG', PAGE_MARGIN, startY, 180, 100);
                });
                addContent(60, () => {
                    doc.setFont("Helvetica", "bold");
                    doc.setFontSize(15);
                    doc.text("Cashflow Projection", PAGE_MARGIN, startY + 10);
                    doc.addImage(cashflowChart, 'PNG', PAGE_MARGIN, startY + 20, 90, 60);
                    doc.text('Growth Projections', PAGE_MARGIN + 90, startY + 10);
                    doc.addImage(growthChart, 'PNG', PAGE_MARGIN + 90, startY + 20, 90, 60);
                });
                startY += 30;
                addContent(20, () => {
                    doc.setFont("Helvetica", "bold");
                    doc.setFontSize(15);

                    doc.text("Before Tax", PAGE_MARGIN + 10, startY);
                    doc.setFontSize(10);
                    doc.text("Year 1", PAGE_MARGIN + 10, startY + 10);
                    doc.text(`${isNaN(beforeTaxData[0]) ? 0 : beforeTaxData[0]}`, PAGE_MARGIN + 30, startY + 10);
                    doc.text("Year 10", PAGE_MARGIN + 10, startY + 20);
                    doc.text(`${isNaN(beforeTaxData[9]) ? 0 : beforeTaxData[9]}`, PAGE_MARGIN + 30, startY + 20);
                    doc.text("Year 20", PAGE_MARGIN + 10, startY + 30);
                    doc.text(`${isNaN(beforeTaxData[19]) ? 0 : beforeTaxData[19]}`, PAGE_MARGIN + 30, startY + 30);
                    doc.setFontSize(15);
                    doc.text("Growth", PAGE_MARGIN + 90, startY)
                    doc.setFontSize(10);
                    doc.text("Year 1", PAGE_MARGIN + 90, startY + 10);
                    doc.text(`${isNaN(growthTaxData[0]) ? 0 : growthTaxData[0]}`, PAGE_MARGIN + 120, startY + 10);
                    doc.text("Year 10", PAGE_MARGIN + 90, startY + 20);
                    doc.text(`${isNaN(growthTaxData[9]) ? 0 : growthTaxData[9]}`, PAGE_MARGIN + 120, startY + 20);
                    doc.text("Year 20", PAGE_MARGIN + 90, startY + 30);
                    doc.text(`${isNaN(growthTaxData[19]) ? 0 : growthTaxData[19]}`, PAGE_MARGIN + 120, startY + 30);
                });
                doc.save('Cash Flow Report.pdf');
            }
        } catch (error) {
            console.error("Error generating PDF:", error);
        }


    } catch (error) {
        console.error("Error generating PDF:", error);
    }
}
// export async function CashFlowPdf(cashFlowAnalysis: any, areaChartFlow: any, propertyTitle: string) {
//     let startY = PAGE_MARGIN;
//     const pageHeight = A4_HEIGHT - 2 * PAGE_MARGIN;
//     const doc = new jsPDF({ format: [A4_WIDTH, A4_HEIGHT] });

//     function addContent(contentHeight: number, contentCallback: () => void) {
//         const currentPageNumber = (doc as any).internal.getNumberOfPages();
//         doc.setFont("Helvetica", "normal");
//         addFooter(doc, currentPageNumber);

//         if (startY + contentHeight > pageHeight) {
//             doc.addPage();
//             startY = PAGE_MARGIN;
//             doc.setFont("Helvetica", "normal");
//             addFooter(doc, currentPageNumber + 1);
//         }

//         contentCallback();
//         startY += contentHeight;
//     }

//     try {
//         console.log("cashFlowAnalysis:", cashFlowAnalysis);

//         const cashFlowProjection: any = cashFlowAnalysis.keyAssumptions;
//         const CapitalAndCashTable = cashFlowAnalysis.cashFlowProjection.scenario1.cashFlowYears;
//         const beforeTaxData = cashFlowAnalysis.CashflowChart.map((elem: any) => elem.beforeTaxCashFlow);
//         const growthTaxData = cashFlowAnalysis.GrowthChart.map((elem: any) => elem.equity);

//         // Add Property Title
//         addContent(0, () => {
//             doc.setFont("Helvetica", "bold");
//             doc.setFontSize(20);
//             const titleWidth = doc.getStringUnitWidth(propertyTitle) * (doc as any ).internal.getFontSize() / doc.internal.scaleFactor;
//             const titleX = (A4_WIDTH - titleWidth) / 2;
//             doc.text(propertyTitle, titleX, startY); // Centered title
//             startY += 15;
//         });

//         // Key Assumptions Table
//         addContent(0, () => {
//             doc.setFont("Helvetica", "bold");
//             doc.setFontSize(15);
//             doc.text("Cash Flow Projection", PAGE_MARGIN, startY);
//             const tableStartY = startY + 5;
//             autoTable(doc, {
//                 startY: tableStartY,
//                 head: [['Key Assumptions', 'Value']],
//                 body: [
//                     ['Capital Growth Rate', `${cashFlowProjection.capitalGrowthRate}%`],
//                     ['Rental Growth Rate', `${cashFlowProjection.inflationRate}%`],
//                     ['Inflation Rate', `${cashFlowProjection.rentalGrowthRate}%`],
//                     ['Vacancy Period', `${cashFlowProjection.vacancyPeriod} weeks`]
//                 ],
//                 margin: { top: 5, bottom: 10 },
//                 pageBreak: 'auto',
//                 theme: 'grid',
//                 headStyles: {
//                     fillColor: [64, 64, 64],
//                     fontSize: 12,
//                     fontStyle: 'bold',
//                     halign: 'center',
//                     valign: 'middle',
//                     textColor: [255, 255, 255],
//                 },
//                 alternateRowStyles: {
//                     fillColor: [255, 255, 255],
//                 },
//                 styles: {
//                     minCellHeight: 15,
//                     overflow: 'linebreak',
//                     fontSize: 11,
//                     halign: 'left',
//                     valign: 'middle',
//                     cellPadding: 4,
//                     lineColor: [0, 0, 0],
//                     lineWidth: 0.5,
//                 },
//                 columnStyles: {
//                     0: { cellWidth: 80 },
//                     1: { cellWidth: 60 },
//                 },
//                 didDrawCell: function (data) {
//                     startY = data.cell.y + data.cell.height + 10;
//                 },
//             });

//             startY = Math.max(startY, tableStartY + 60);
//         });

//         // Capital Growth Table
//         addContent(20, () => {
//             doc.setFont("Helvetica", "bold");
//             doc.setFontSize(15);
//             doc.text("Capital Growth", PAGE_MARGIN, startY);
//             autoTable(doc, {
//                 startY: startY + 5,
//                 head: [
//                     [
//                         'Year', 'Property Value', 'Loan Amount', 'Equity', 'Gross Rent',
//                         'Principal & Interest Repayment', 'Rental Expenses',
//                         'Before Tax Cash Flow', 'Cash/Income pw'
//                     ]
//                 ],
//                 body: CapitalAndCashTable.map((item: any) => [
//                     item.year,
//                     item.propertyValue === 0 ? '0' : `$${parseFloat(item.propertyValue).toFixed(0)}`,
//                     item.loanAmount === 0 ? '0' : `$${parseFloat(item.loanAmount).toFixed(0)}`,
//                     item.equity === 0 ? '0' : `$${parseFloat(item.equity).toFixed(0)}`,
//                     item.grossRent === 0 ? '0' : `$${parseFloat(item.grossRent).toFixed(0)}`,
//                     item.PrincipalInterestRepayment === 0 ? '0' : `$${parseFloat(item.PrincipalInterestRepayment).toFixed(0)}`,
//                     item.rentalExpenses === 0 ? '0' : `$${parseFloat(item.rentalExpenses).toFixed(0)}`,
//                     item.beforeTaxCashFlow === 0 ? '0' : `$${parseFloat(item.beforeTaxCashFlow).toFixed(0)}`,
//                     item.costIncomePw === 0 ? '0' : `$${parseFloat(item.costIncomePw).toFixed(0)}`
//                 ]),
//                 margin: { top: 5, bottom: 20 },
//                 pageBreak: 'auto',
//                 headStyles: {
//                     fillColor: [64, 64, 64],
//                     fontSize: 12,
//                     fontStyle: 'bold',
//                     halign: 'center',
//                     valign: 'middle',
//                     textColor: [255, 255, 255],
//                 },
//                 didDrawCell: function (data) {
//                     startY = data.cell.y + data.cell.height + 10;
//                 },
//             });
//         });

//         // Principal & Interest Loan
//         addContent(20, () => {
//             doc.setFont("Helvetica", "bold");
//             doc.setFontSize(15);
//             doc.text("Principal & Interest Loan", PAGE_MARGIN, startY);
//         });

//         try {
//             const principalChart: any = await GenerateChart('area1', areaChartFlow);
//             const cashflowChart: any = await GenerateChart('area', CapitalAndCashTable);
//             const growthChart: any = await GenerateChart('area2', CapitalAndCashTable);

//             if (principalChart) {
//                 addContent(100, () => {
//                     doc.addImage(principalChart, 'PNG', PAGE_MARGIN, startY, 180, 100);
//                 });

//                 addContent(60, () => {
//                     doc.setFont("Helvetica", "bold");
//                     doc.setFontSize(15);
//                     doc.text("Cashflow Projection", PAGE_MARGIN, startY + 10);
//                     doc.addImage(cashflowChart, 'PNG', PAGE_MARGIN, startY + 20, 90, 60);
//                     doc.text('Growth Projections', PAGE_MARGIN + 90, startY + 10);
//                     doc.addImage(growthChart, 'PNG', PAGE_MARGIN + 90, startY + 20, 90, 60);
//                 });

//                 startY += 30;

//                 addContent(20, () => {
//                     doc.setFont("Helvetica", "bold");
//                     doc.setFontSize(15);
//                     doc.text("Before Tax", PAGE_MARGIN + 10, startY);
//                     doc.setFontSize(10);
//                     doc.text("Year 1", PAGE_MARGIN + 10, startY + 10);
//                     doc.text(`${isNaN(beforeTaxData[0]) ? 0 : beforeTaxData[0]}`, PAGE_MARGIN + 30, startY + 10);
//                     doc.text("Year 10", PAGE_MARGIN + 10, startY + 20);
//                     doc.text(`${isNaN(beforeTaxData[9]) ? 0 : beforeTaxData[9]}`, PAGE_MARGIN + 30, startY + 20);
//                     doc.text("Year 20", PAGE_MARGIN + 10, startY + 30);
//                     doc.text(`${isNaN(beforeTaxData[19]) ? 0 : beforeTaxData[19]}`, PAGE_MARGIN + 30, startY + 30);

//                     doc.setFontSize(15);
//                     doc.text("Growth", PAGE_MARGIN + 90, startY);
//                     doc.setFontSize(10);
//                     doc.text("Year 1", PAGE_MARGIN + 90, startY + 10);
//                     doc.text(`${isNaN(growthTaxData[0]) ? 0 : growthTaxData[0]}`, PAGE_MARGIN + 120, startY + 10);
//                     doc.text("Year 10", PAGE_MARGIN + 90, startY + 20);
//                     doc.text(`${isNaN(growthTaxData[9]) ? 0 : growthTaxData[9]}`, PAGE_MARGIN + 120, startY + 20);
//                     doc.text("Year 20", PAGE_MARGIN + 90, startY + 30);
//                     doc.text(`${isNaN(growthTaxData[19]) ? 0 : growthTaxData[19]}`, PAGE_MARGIN + 120, startY + 30);
//                 });
//                 doc.save('Cash Flow Report.pdf');
//             }
//         } catch (err) {
//             console.error(err);
//         }

//         // return doc;
//     } catch (err) {
//         console.error(err);
//         // return null;
//     }
// }





async function GenerateChart(type: 'area1' | 'area' | 'bar1' | 'bar2' | 'area2', CapitalAndCashTable: any[]): Promise<string> {
    const data = CapitalAndCashTable.map((elem: any) => ({
        name: elem.year,
        data: [
            elem.beforeTaxCashFlow,
            elem.costIncomePw,
        ]
    }));
    const growthChartData = CapitalAndCashTable.map((elem: any) => ({
        name: elem.year,
        data: [
            elem.equity
        ]
    }))
    const princpalChartData = CapitalAndCashTable.map((elem: any) => ({
        name: elem.year,
        data: [
            elem.beforeTaxCashFlow,
            elem.repaymentAmount,
            elem.afterTaxCashFlow
        ]
    }));
    const calculateYAxisLabels = (maxValue: any) => {
        const step = 15000;  // Step between each label
        const labels = [];
        let currentValue = 0;

        while (currentValue <= maxValue) {
            labels.push(currentValue);
            currentValue += step;
        }

        return labels;
    };

    const maxDataValue = Math.max(...data.map(d => d.data.reduce((acc, curr) => acc + curr, 0)));
    const yAxisLabels = calculateYAxisLabels(maxDataValue);
    let chartOptions: ApexOptions;

    switch (type) {
        case 'area':
        case 'bar1':
        case 'bar2':
            chartOptions = chartOptions = {
                chart: {
                    type: 'area',
                    width: 600,
                    height: 400
                },
                plotOptions: {
                    line: {
                        // Customize line chart appearance here if needed
                    }
                },
                dataLabels: {
                    enabled: false
                },
                series: [{
                    name: 'Cashflow projection',
                    data: data.map(d => d.data.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0))
                }],
                xaxis: {
                    categories: data.map((d: any) => d.name),
                    title: {
                        text: 'Year'
                    },
                    axisBorder: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#000',
                        height: 6,
                        offsetX: 0,
                        offsetY: 0
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return Math.round(value).toString();
                        }
                    },
                    title: {
                        text: 'Value'
                    },
                    axisBorder: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    crosshairs: {
                        show: true,
                        position: 'back',
                        stroke: {
                            color: '#000',
                            width: 1
                        }
                    },
                    tickAmount: 5
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return Math.round(value).toString();
                        }
                    }
                },
                grid: {
                    show: true,
                }

            };
            break;

        case 'area1':
            // chartOptions = {
            //     chart: {
            //         type: 'area',
            //         width: 600,
            //         height: 400
            //     },
            //     plotOptions: {
            //         line: {
            //             // Customize line chart appearance here if needed
            //         }
            //     },
            //     dataLabels: {
            //         enabled: false
            //     },
            //     series: [{
            //         name: 'Portfolio Growth',
            //         data: princpalChartData.map(elem => elem.data.reduce((acc, curr) => acc + curr, 0))
            //     }],
            //     xaxis: {
            //         categories: princpalChartData.map((elem: any) => elem.name), 
            //         title: {
            //             text: 'Year'
            //         },
            //         axisBorder: {
            //             show: true,
            //             color: '#000',
            //             offsetX: 0,
            //             offsetY: 0
            //         },
            //         axisTicks: {
            //             show: true,
            //             borderType: 'solid',
            //             color: '#000', 
            //             height: 6,
            //             offsetX: 0,
            //             offsetY: 0
            //         }
            //     },
            //     yaxis: {
            //         labels: {
            //             formatter: function (value) {
            //                 return Math.round(value).toString();
            //             }
            //         },
            //         title: {
            //             text: 'Value'
            //         },
            //         axisBorder: {
            //             show: true,
            //             color: '#000', 
            //             offsetX: 0,
            //             offsetY: 0
            //         },
            //         axisTicks: {
            //             show: true,
            //             color: '#000', 
            //             offsetX: 0,
            //             offsetY: 0
            //         },

            //         crosshairs: {
            //             show: true,
            //             position: 'back',
            //             stroke: {
            //                 color: '#000',
            //                 width: 1
            //             }
            //         },
            //         tickAmount: 5 
            //     },
            //     tooltip: {
            //         y: {
            //             formatter: function (value) {
            //                 return Math.round(value).toString();
            //             }
            //         }
            //     },
            //     grid: {
            //         show: true,
            //     }
            // };
            chartOptions = {
                chart: {
                    type: 'area',
                    width: 600,
                    height: 400
                },
                plotOptions: {
                    line: {
                        // Customize line chart appearance here if needed
                    }
                },
                dataLabels: {
                    enabled: false
                },
                series: [{
                    name: 'Portfolio Growth',
                    data: princpalChartData.map(elem =>
                        elem.data.reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0) // Replace NaN with 0
                    )
                }],
                xaxis: {
                    categories: princpalChartData.map((elem: any) => elem.name || 'Unknown'), // Fallback if name is missing
                    title: {
                        text: 'Year'
                    },
                    axisBorder: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#000',
                        height: 6,
                        offsetX: 0,
                        offsetY: 0
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return Math.round(value).toString();
                        }
                    },
                    title: {
                        text: 'Value'
                    },
                    axisBorder: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    crosshairs: {
                        show: true,
                        position: 'back',
                        stroke: {
                            color: '#000',
                            width: 1
                        }
                    },
                    tickAmount: 5
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return Math.round(value).toString();
                        }
                    }
                },
                grid: {
                    show: true
                }
            };

            break;

        case 'area2':
            chartOptions = chartOptions = {
                chart: {
                    type: 'area',
                    width: 600,
                    height: 400
                },
                plotOptions: {
                    area: {

                    }
                },
                dataLabels: {
                    enabled: false
                },
                series: [{
                    name: 'Portfolio Growth',
                    data: growthChartData.map(elem => elem.data.reduce((acc, curr) => acc + curr, 0))
                }],
                xaxis: {
                    categories: growthChartData.map((elem: any) => elem.name),
                    title: {
                        text: 'Year'
                    },
                    axisBorder: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#000',
                        height: 6,
                        offsetX: 0,
                        offsetY: 0
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return Math.round(value).toString();
                        }
                    },
                    title: {
                        text: 'Value'
                    },
                    axisBorder: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        color: '#000',
                        offsetX: 0,
                        offsetY: 0
                    },
                    crosshairs: {
                        show: true,
                        position: 'back',
                        stroke: {
                            color: '#000',
                            width: 1
                        }
                    },
                    tickAmount: 5
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return Math.round(value).toString();
                        }
                    }
                },
                grid: {
                    show: true,
                }
            };
            break;

        default:
            throw new Error('Unsupported chart type');
    }

    try {
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        const chartContainer = document.createElement('div');
        chartContainer.style.position = 'absolute';
        chartContainer.style.top = '-10000px';
        document.body.appendChild(chartContainer);

        const chart = new ApexCharts(chartContainer, chartOptions);
        await chart.render();

        await delay(3000);

        const base64Image = await chart.dataURI();

        chart.destroy();
        document.body.removeChild(chartContainer);

        return (base64Image as { imgURI: string; }).imgURI;
    } catch (error) {
        console.error("Error generating chart image:", error);
        return Promise.reject(error);
    }
}
function addHeader(doc: jsPDF, profileData: any, auth: any) {

    const headerMargin = 10;
    const headerWidth = A4_WIDTH - 2 * headerMargin;


    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Investar Report', headerMargin, headerMargin + 10);

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.text(`Name: ${profileData.personalInfo.personalInfo[0]?.first_name} ${profileData.personalInfo.personalInfo[0]?.last_name} | Investar Score: ${profileData.investarScore}`, headerMargin, headerMargin + 20);







    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(100);

    const today = new Date();
    const dateStr = today.getDate() + ' ' + getMonthName(today.getMonth()) + ' ' + today.getFullYear();
    doc.text(`Prepared on ${dateStr} for ${profileData.personalInfo.personalInfo[0]?.first_name} ${profileData.personalInfo.personalInfo[0]?.last_name}`, headerMargin, headerMargin + 30);
    doc.text(`Prepared by ${auth?.first_name} | ${auth?.email} | ${auth?.phone_number}`, headerMargin, headerMargin + 35);
}

function getMonthName(month: number) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[month];

}

function addFooter(doc: jsPDF, pageNumber: number) {
    const footerText = `© Copyright 2024 | Investar Ltd and its licensors are the sole and exclusive owners of all rights, title and interest (including intellectual property rights) subsisting in this publication including any data, analytics, statistics and other information.`;

    doc.setFontSize(8);
    doc.setTextColor(150);
    const footerMargin = 10;

    doc.setPage(pageNumber);
    doc.text(`Page ${pageNumber}`, footerMargin, A4_HEIGHT - footerMargin - 5);
    doc.text(footerText, footerMargin, A4_HEIGHT - footerMargin, { maxWidth: A4_WIDTH - 2 * footerMargin });
}