import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  TextField,
  IconButton,
} from "@mui/material";
import { Chart, ChartConfiguration, ChartTypeRegistry, registerables } from 'chart.js';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import { tokens } from "../../../theme";
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import DownloadIcon from '@mui/icons-material/Download';
import { logout, updateCurrentPortfolio, updateEmploymentInfo, updateExpenses, updateFamilyInfo, updateFinancialGoal, updatePersonalInfo } from '../../../features/userSlice';
import { withAuthenticator } from "@aws-amplify/ui-react";
import BusinessIcon from '@mui/icons-material/Business';
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
// @ts-ignore
import html2pdf from 'html2pdf.js';
import { RootState } from "../../../store";
import { fetchAdvisors, fetchPortfolio, fetchStrategy, formatCurrency } from "../../../hooks/buyerAgent";
import Strategy from "../strategy";
import ScoreIcon from '@mui/icons-material/Score';
import PropertyGrowthChart from "../dashboard/graph/Propertygrowth";
import Propertyyields from "../dashboard/graph/Propertyyields";
import BorrowableEquity from "../dashboard/graph/BorrowableEquity";
import LoanToValueRatioChart from "../dashboard/graph/LoanToValueRatioChart";
import AuthenticatedAPI from "../../../auth_helper";
import {
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { text } from "body-parser";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { formatNumber } from "../../../hooks/properties/properties";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";
import { GeneratePdf } from "./GeneratePdf";
import EmailCompositionModal from '../../components/EmailCompositionModal';
import CashFlowAnalysis from "../cashFlowAnalysis";
import BuyAndSellPage from "../buySellInput";
import CashFlowAnalysisNew from "../cashFlowAnalysis";
import MultiStepForm from "../multiForm";

Chart.register(ChartDataLabels);

// Define the structure for an investor
interface Investor {
  id: number;
  name: string;
  investarScore: number;
  portfolioGrowth: number[];
  portfolioYields: number[];
  borrowableEquity: number[];
  loanToValueRatio: { equity: number; debt: number };
  properties: Array<{ address: string; value: number; classification: string }>;
}

interface Buyer {
  id: number;
  investor_name: any;
  agentid: any;
  investor_email: any;
  investor_id: any;
}

interface Strategy {
  id: number;
  investor_name: any;
  agentid: any;
  investor_id: any;
  strategy: {
    investorReadinessScore: number;
  };
}

interface Portfolio {
  [key: string]: any;
}

interface ProfileData {
  financialGoal?: any[];
  familyInfo?: { dependentKids: any[]; earningMembers: any[] };
  employmentInfo?: any[];
  currentPortfolio?: { investmentProperties: any[] };
  investmentStrategy?: any;
  [key: string]: any;
}

interface Agent {
  investor_name: string;
  investor_id: string;
  // Add other properties of the agent object here
}

interface Property {
  propertyId: number;
  address: string;
  capRate: number;
  classification: string;
  dscr: number;
  ltv: number;
  roi: number;
}

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

interface BuyersAgentDashboardPageProps {
  buyerAgentData: any // Define the prop type,
  agents: [] | any
  activetab: [] | any
  // Call the provided callback to set the activeIndex
  // onNavigateBack: (searchTerm: any) => void;
}

const BuyersAgentDashboard: React.FC<BuyersAgentDashboardPageProps> = ({ buyerAgentData, agents, activetab }) => {
  console.log('agents', agents)
  const theme = useTheme();
  const printRef = useRef<HTMLDivElement>(null);
  const currentScenario = useSelector((state: RootState) => state.scenarios.currentScenario);
  const scenarios = useSelector((state: RootState) => state.scenarios.scenarios[0]);
  console.log('curr', currentScenario)
  console.log('currsce', scenarios)
  const colors = tokens(theme.palette.mode);
  const [selectedInvestor, setSelectedInvestor] = useState<number>(0);
  const [buyersAgent, setBuyersAgent] = useState<Buyer[] | null>()
  const [portfolio, setPortfolio] = useState<Portfolio[] | undefined>()
  console.log('portfolio', portfolio)
  const [strategy, setStrategy] = useState<Strategy[] | undefined>()
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  console.log('profileData', profileData)
  const [portfolioData, setPortfolioData] = useState<Property[]>([]);
  const [buyerAgent, setBuyerAgentName] = useState('User')
  const [investorID, setInvestorID] = useState('')
  console.log('investorID', investorID)
  const [loading, setLoading] = useState(true);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [currentInvestor, setCurrentInvestor] = useState<Buyer | null>(null);
  const [chartImage, setChartImage] = useState<string | null>(null);
  const [series, setSeries] = useState([{ data: [] as number[] }]);
  const [yieldSeries, setYieldSeries] = useState([{ data: [] as number[] }]);
  const [equitySeries, setEquitySeries] = useState([{ data: [] as number[] }]);
  const [buttonLabel, setButtonLabel] = useState("DOWNLOAD REPORTS");
  const [activeAdduser, setActiveAdduser] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector(
    (state: RootState) => state.user.personalInfo
  );


  const auth = useSelector((state: RootState) => state.user.personalInfo);
  const [activeTab, setActiveTab] = useState<number>(0);

  const fetchInvestorData = async (agentId: string) => {
    try {
      setLoading(true)
      await getUserProfile(agentId)
      const portFolio = await fetchPortfolio(agentId);
      setPortfolio(portFolio);
      const strategy = await fetchStrategy(agentId);
      setStrategy(strategy);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  const handleInvestorChange = (event: React.SyntheticEvent, newValue: number) => {
    setLoading(true)
    setSelectedInvestor(newValue);
    if (buyersAgent && buyersAgent[newValue]) {
      const selectedAgentId = buyersAgent[newValue].investor_id;
      fetchInvestorData(selectedAgentId);
    }
  };

  const fetchUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setBuyerAgentName(user.attributes[`custom:primary_contact_name`])
      setBuyersAgent(buyerAgentData);
      if (buyerAgentData) {
        const initialAgentId = investorID ? investorID : buyerAgentData.investor_id;
        await getUserProfile(initialAgentId)
        fetchInvestorData(initialAgentId);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [buyerAgentData, investorID]);

  useEffect(() => {
    const fetchData = async () => {
      await Auth?.currentAuthenticatedUser().then((res) => {

        const groups = res.signInUserSession.accessToken.payload["cognito:groups"] || []
        setActiveAdduser(groups.includes("onboarding") ? "onboarding" : "");
      })

    }
    fetchData()
  }, [activeAdduser])

  const getUserProfile = async (id: any) => {
    try {
      const userData = await Auth?.currentAuthenticatedUser().then(
        async (res) => {

          const sessionUser = JSON.parse(sessionStorage.getItem('investorData') || '{}').investor_id;

          const response = await AuthenticatedAPI.request(
            "InvestmentAPI",
            "get",
            `/api/profile/${id}`
          );

          await getPortFolioAnalysis(id)

          if (!response) {
            setLoading(false);
            throw new Error("Failed to fetch user profile");
          }
          if (response) {
            if (sessionUser === id) {
              setInvestorID(sessionUser)

            }

            if (sessionUser === id && response.data?.currentPortfolio?.investmentProperties?.length === 0 && activeAdduser === "onboarding") {

              // setActiveTab(3)
              dispatch(updatePersonalInfo(response.data?.personalInfo?.personalInfo[0]));
              dispatch(updateFinancialGoal(response.data?.financialGoal?.financialGoal[0]));
              dispatch(updateFamilyInfo(response.data?.familyInfo));
              dispatch(updateEmploymentInfo(response.data?.employmentInfo?.employmentInfo[0]));
              dispatch(updateCurrentPortfolio(response.data?.currentPortfolio));
              // dispatch(updatePropertyInfo(response.data?.propertyInfo?.propertyInfo[0]));
              dispatch(updateExpenses(response.data?.expenses?.expenses))

            }
            setProfileData(response.data);
            setLoading(false);
          }
          return response;
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const getPortFolioAnalysis = async (id: any) => {
    const data1 = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        "InvestmentAPI",
        "get",
        `/api/portfolio-analysis/${id}`,
      );
      setPortfolioData(response.data)
    })
  }

  const saveUserData = async (data: any) => {
    const data1 = await Auth?.currentAuthenticatedUser().then(async (res) => {
      const response = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "post",
        `/agents/buyers-agents`,
        {
          body: { userId: data.sub, email: data.email, company_name: data[`custom:company_name`], primary_contact_name: data[`custom:primary_contact_name`] },
        }
      );
    })
  }

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 15,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 15,
      backgroundColor: theme.palette.mode === "light" ? "#4cceac" : "#4cceac",
    },
  }));

  const investmentProperties = profileData?.currentPortfolio?.investmentProperties || [];

  const data = investmentProperties.map(
    (property: any) =>
      property.currentestimatedvalue - property.initialpurchaseprice || 0
  );
  const PropertyNames = investmentProperties.map(
    (property: any) =>
      property.address || ""
  );
  const propertyName = PropertyNames.map(name => name.split(' ').slice(0, 2).join(' '));
  const totalLoan = investmentProperties.map(
    (loan: any) => loan.currentloanamount || 0
  );

  const netRentalYields = investmentProperties.map(
    (property: any) => (property.weeklyrent * 52) / property.initialpurchaseprice * 100
  );

  const borrowableEquities = investmentProperties.map((property: any) => {
    const currentEstimatedValue =
      parseFloat(property.currentestimatedvalue) || 0;
    const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
    return currentEstimatedValue * 0.8 - currentLoanAmount;
  });
  const toatlEquity = borrowableEquities.reduce(
    (total, value) => total + value,
    0
  );

  const toatlLoanAmount = totalLoan.reduce(
    (total, value) => total + Number(value),
    0
  );

  const total = toatlEquity + toatlLoanAmount;
  const Equitypercente = ((toatlEquity / total) * 100).toFixed(2);
  const Debtpercente = ((toatlLoanAmount / total) * 100).toFixed(2);

  const totalValue = portfolio?.reduce((accumulator, x) => {
    return accumulator + parseFloat(x.currentEstimatedValue);
  }, 0) || 0;

  const totalYield = portfolio?.reduce((accumulator, x) => {
    return accumulator + parseInt(x.netRentalYield)
  }, 0) || 0;
  const averageYield = portfolio?.length ? totalYield / portfolio.length : 0;

  const handleOptionChange = async (event: any, value: Agent | any) => {

    setLoading(true)
    console.log(value, '--')

    setBuyersAgent(value);
    sessionStorage.setItem('investorData', JSON.stringify(value));
    if (value) {
      const initialAgentId = JSON.parse(sessionStorage.getItem('investorData') || '{}').investor_id;

      // const initialAgentId = value.investor_id;
      await getUserProfile(initialAgentId)
      await fetchInvestorData(initialAgentId);
      setLoading(false)
    }
  };

  const backHandler = () => {
    if (activeTab === 0) {
      sessionStorage.removeItem('investorData')
      window.location.href = '/ba-search'
    } else if (activeTab === 1) {
      setActiveTab(0)
    } else {
      setActiveTab(0)
    }
  }

  const handleOpenEmailModal = (investor: Buyer) => {
    setCurrentInvestor(investor);
    setIsEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  const handleSendEmail = async (investorEmail: string, investorName: string, emailContent: string) => {
    try {
      const response = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "post",
        "/agents/send-email",
        {
          body: {
            investorEmail,
            agentName: buyerAgent,
            investorName,
            emailContent
          }
        }
      );

      if (response && response.success) {
        toast.success("Email sent successfully");
        handleCloseEmailModal();
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      toast.error("Failed to send email");
      console.error('Error sending email:', error);
    }
  };

  console.log('activeAddUser', activeAdduser)

  const handleDownloadPdf = async () => {
    setButtonLabel("Preparing PDF...")
    await GeneratePdf(profileData, auth, portfolio);
    setButtonLabel("Download Reports")
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {

    setActiveTab(newValue);
  };
  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setActiveTab(newValue);

  //   // Navigate to the appropriate route based on the selected tab
  //   if (newValue === 1) {
  //     navigate('/buy-sell');
  //   } else if (newValue === 0) {
  //     navigate('/portfolio-summary');
  //   } else if (newValue === 2) {
  //     navigate('/cashflow-analysis');
  //   }
  // };
  useEffect(() => {
    if (activetab === 1 && scenarios?.length === 1) {

      setActiveTab(0)
    } else {
      if (activetab === 1) {

        setActiveTab(0)
      }

    }
  }, [activetab])
  console.log('activetab', activeTab)
  return (
    <Grid container columns={12} style={{ marginLeft: "0px" }} className="p-6" ref={printRef}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100%"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          <Grid item md={12} lg={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }} className="p-6">
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontSize: "30px",
                  fontWeight: "400",
                  color: colors.grey[100],
                  width: "auto",
                  flexGrow: 1,
                }}
              >
                Investor Name <b>{profileData && profileData.personalInfo?.personalInfo && profileData.personalInfo?.personalInfo[0]?.first_name || ''}</b>
                👋
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadPdf}
                sx={{
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  color: "#ffffff",
                  padding: "10px 28px",
                }}
              >
                <DownloadIcon />
                {buttonLabel}
              </Button>
            </Box>
          </Grid>

          <Grid item md={12} className="dashBoard_search">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Button onClick={backHandler} color="secondary">
                <ArrowBackIcon sx={{ marginRight: "5px" }} /> Go Back
              </Button>
              <Grid item sm={12} md={5} lg={4} className="dashBoard_search">
                <Box className="custom_searchOne mb-4" flexGrow={1}>
                  <Autocomplete
                    id="free-solo-demo"
                    options={agents || []}
                    getOptionLabel={(option: Agent) => option?.investor_name}
                    onChange={handleOptionChange}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Search by Investor Name"
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </div>
          </Grid>

          <Grid item md={12}>

            {/* <Tabs value={activeTab} onChange={(event, newValue) => handleTabChange(event, newValue)} aria-label="investor tabs"> */}
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="investor tabs">

              {/* {activeTab !== 3 ? ( */}

              <Tab label="Portfolio Summary" />
              <Tab label="Invest Net" />
              <Tab label="Cashflow Analysis" />

              {/* ) : ( */}
              {/* {activeAdduser === 'onboarding' && ( */}

              <Tab label="User Onboarding" />
              {/* )} */}



              {/* )} */}
            </Tabs>
          </Grid>

          {activeTab === 0 && (
            <>
              <Grid item md={12}>
                <Card
                  sx={{
                    padding: "20px !important",
                    marginBottom: "0px !important",
                    borderRadius: "15px !important",
                    boxShadow: "none !important",
                    backgroundColor: colors.primary[400],
                  }}
                >
                  <Typography
                    variant="h4"
                    align="left"
                    pb={2}
                    zIndex={1}
                    gutterBottom
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Portfolio Summary
                  </Typography>
                  <ul className="profileBox_summarylist">
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center", // Centers items vertically
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#6870fa' textAlign='center'  ><ScoreIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="700" textAlign='center' paddingTop='10px'>{strategy?.[0]?.strategy?.investorReadinessScore ?? 0}</Typography>
                            <Typography fontWeight="500" textAlign='center' paddingTop='10px'>Investar Score</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center",
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#4cceac' textAlign='center' ><HomeIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="900" textAlign='center' paddingTop='10px'>{portfolio?.length}</Typography>
                            <Typography fontWeight="500" paddingTop='10px'>Properties Owned</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center",
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#6870fa' textAlign='center'><AttachMoneyIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="700" textAlign='center' paddingTop='10px'>${formatCurrency(totalValue)}</Typography>
                            <Typography fontWeight="500" textAlign='center' paddingTop='10px'>Total Value</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box className="profile_box">
                        <Box sx={{
                          flex: "100% !important",
                          maxWidth: "100% !important",
                          display: "flex", // To enable flexbox
                          flexDirection: "column", // Aligns items vertically
                          alignItems: "center", // Centers items horizontally
                          justifyContent: "center",
                        }}>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography color='#141414' textAlign='center'><PercentIcon sx={{ height: '40px !important', width: '40px !important' }} /></Typography>
                            <Typography fontWeight="700" textAlign='center' paddingTop='10px'>{averageYield.toFixed(0)}</Typography>
                            <Typography fontWeight="500" textAlign='center' paddingTop='10px'> Average Yield</Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                    <li>
                      <Box
                        className={`${toatlEquity >= 70000 ? "gradient-border-ba-dash" : "profile_box"}`}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            fontWeight="600"
                            sx={{
                              color: colors.grey[100],
                              fontSize: "14px",
                              lineHeight: 1,
                              padding: "10px 28px",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Typography textAlign='center' color='#2e7d32'><BusinessIcon sx={{ height: '30px !important', width: '40px !important' }} /></Typography>
                            <Typography className="pt-5" fontWeight="600">
                              Borrowable Equity: ${toatlEquity.toFixed(0)}
                              {toatlEquity >= 70000 && (
                                <>
                                  <Tooltip title={`Your Portfolio Borrowable Equity is ${toatlEquity.toFixed(0)}`}>
                                    <InfoIcon sx={{ ml: 1 }} />
                                  </Tooltip>
                                </>
                              )}
                              <Tooltip title="Send email to investor">
                                <IconButton
                                  onClick={() => handleOpenEmailModal(buyerAgentData)}
                                  sx={{ ml: 1 }}
                                >
                                  <EmailIcon />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  </ul>
                </Card>
              </Grid>

              <Grid container item md={12} spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} xl={3}>
                      <Card
                        sx={{
                          padding: "20px 20px 20px !important",
                          marginBottom: "0px !important",
                          borderRadius: "15px !important",
                          boxShadow: "none !important",
                          height: "auto",
                          backgroundColor: colors.primary[400],
                        }}
                      >
                        <Typography
                          variant="h4"
                          align="left"
                          pb={2}
                          zIndex={1}
                          gutterBottom
                          sx={{
                            fontWeight: "700",
                          }}
                        >
                          Portfolio Growth
                        </Typography>
                        <PropertyGrowthChart data={data} propertyname={propertyName} />
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                      <Card
                        sx={{
                          padding: "20px 20px 20px !important",
                          marginBottom: "0px !important",
                          borderRadius: "15px !important",
                          boxShadow: "none !important",
                          height: "auto",
                          backgroundColor: colors.primary[400],
                        }}
                      >
                        <Typography
                          variant="h4"
                          align="left"
                          pb={2}
                          zIndex={1}
                          gutterBottom
                          sx={{
                            fontWeight: "700",
                          }}
                        >
                          Portfolio Yields
                        </Typography>
                        <Propertyyields netRentalYields={netRentalYields} propertyname={propertyName} />
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                      <Card
                        sx={{
                          padding: "20px 20px 20px !important",
                          marginBottom: "0px !important",
                          borderRadius: "15px !important",
                          boxShadow: "none !important",
                          height: "auto",
                          backgroundColor: colors.primary[400],
                        }}
                      >
                        <Typography
                          variant="h4"
                          align="left"
                          pb={2}
                          zIndex={1}
                          gutterBottom
                          sx={{
                            fontWeight: "700",
                          }}
                        >
                          Portfolio Borrowable Equity
                        </Typography>
                        <BorrowableEquity borrowableEquities={borrowableEquities} propertyname={propertyName} />
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3}>
                      <Card
                        sx={{
                          padding: "20px 20px 20px !important",
                          marginBottom: "0px !important",
                          borderRadius: "15px !important",
                          boxShadow: "none !important",
                          height: "385px",
                          backgroundColor: colors.primary[400],
                        }}
                      >
                        <Typography
                          variant="h4"
                          align="left"
                          pb={2}
                          zIndex={1}
                          gutterBottom
                          sx={{
                            fontWeight: "700",
                          }}
                        >
                          Portfolio Loan to Value Ratio
                        </Typography>
                        <LoanToValueRatioChart
                          equity={Equitypercente}
                          loanamount={Debtpercente}
                          totalLoan={toatlLoanAmount}
                          totalEquity={toatlEquity}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Card
                  sx={{
                    padding: "0px !important",
                    marginBottom: "0px !important",
                    borderRadius: "15px !important",
                    boxShadow: "none !important",
                    backgroundColor: colors.primary[400],
                  }}
                >
                  <Grid container spacing={0} columns={12} alignItems={"center"} p={2.5}>
                    <Grid item md={12} lg={6}>
                      <Typography
                        variant="h4"
                        align="left"
                        zIndex={1}
                        gutterBottom
                        sx={{
                          margin: "0px",
                          fontWeight: "600",
                        }}
                      >
                        Properties
                      </Typography>
                    </Grid>
                  </Grid>
                  <TableContainer className="table_styleOne">
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "yourColorHere",
                            }}
                          >
                            Address
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "yourColorHere",
                            }}
                            align="right"
                          >
                            Value
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "yourColorHere",
                            }}
                            align="right"
                          >
                            Classification
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {profileData?.currentPortfolio?.investmentProperties.map((property, index) => {
                          const propertyData = portfolioData.find(
                            (p) => p.propertyId === property.id
                          );

                          return (
                            <TableRow key={index}>
                              <TableCell>{property.address}</TableCell>
                              <TableCell align="right">{property.currentestimatedvalue}</TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="contained"
                                  color={
                                    propertyData &&
                                      propertyData.classification ===
                                      "Performing Well"
                                      ? "success"
                                      : propertyData &&
                                        propertyData.classification === "Average"
                                        ? "warning"
                                        : propertyData &&
                                          propertyData.classification === "Risky"
                                          ? "error"
                                          : "info"
                                  }
                                  sx={{
                                    borderRadius: "10px",
                                    size: "small",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    color: "#ffffff",
                                    padding: "10px 10px",
                                  }}
                                >
                                  {propertyData && propertyData.classification}
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </>
          )}

          {activeTab === 1 && (
            <Grid item xs={12}>
              <BuyAndSellPage buyerAgent={buyersAgent} />
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid item xs={12}>
              <CashFlowAnalysisNew agentProfile={true} investarData={profileData} />
            </Grid>
          )}

          {activeTab === 3 && (
            <Grid item xs={12}>
              <MultiStepForm investorID={investorID} />
            </Grid>
          )}
        </>
      )}
      <EmailCompositionModal
        open={isEmailModalOpen}
        handleClose={handleCloseEmailModal}
        onSendEmail={handleSendEmail}
        investorEmail={currentInvestor?.investor_email || ''}
        investorName={currentInvestor?.investor_name || ''}
      />
    </Grid>
  );
};

export default withAuthenticator(BuyersAgentDashboard, {
  hideSignUp: true,
  formFields: {
    setupTOTP: {
      QR: {
        totpIssuer: "investar.io",
      },
    },
  },
});