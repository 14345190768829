import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import investedPropertiesReducer from './features/investedProperties';
import cashFlowAnalysisReducer from './features/cashFlow';
import scenariosReducer from './features/scenarios';


const rootReducer = combineReducers({
  user: userReducer,
  investedProperties: investedPropertiesReducer,
  cashFlowAnalysis: cashFlowAnalysisReducer,
  scenarios: scenariosReducer,

  // other reducers can be added here
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;