import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Grid, TextField, Typography, useTheme } from '@mui/material';
import { AcquisitionCostsData, PropertyDetailsData } from '../../../../types/CashFlow';
import { tokens } from '../../../../theme';
import { useDispatch } from 'react-redux';
import { updateAcquisitionCosts } from '../../../../features/cashFlow';
import { calculateTransferFee, formatNumberWithCommas, State } from '../../../../hooks/properties/properties';

interface AcquisitionCostsProps {
  acquisitionCosts: AcquisitionCostsData | null;
  propertyDetails: PropertyDetailsData | null;
  // updateAcquisitionCosts: (updates: Partial<AcquisitionCostsData>) => void;
}

const AcquisitionCosts: React.FC<AcquisitionCostsProps> = ({ acquisitionCosts, propertyDetails }) => {
  console.log("propertyDetails", propertyDetails)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const dispatch = useDispatch()
  const [registrationFee, setRegistrationFee] = useState('');
  const handleChange = useCallback(
    (field: keyof AcquisitionCostsData, value: string | number) => {
      if (acquisitionCosts?.[field] !== value) {
        const values = { [field]: value };
        dispatch(updateAcquisitionCosts(values));
      }
    },
    [acquisitionCosts, dispatch]
  );
  // const handleChange = (field: keyof AcquisitionCostsData, value: string | number) => {
  //   updateAcquisitionCosts({ [field]: value });
  // };

  const calculateTotalCost = (): number => {
    if (!acquisitionCosts) return 0;

    const {
      deposit,
      buildingAndPestInspection,
      buyersAgentFee,
      stampDuty,
      conveyancingFees,
      depreciationSchedule,
    } = acquisitionCosts;
    // dispatch(updateAcquisitionCosts(acquisitionCosts));
    return (
      Number(deposit) +
      Number(buildingAndPestInspection) +
      Number(buyersAgentFee) +
      Number(stampDuty) +
      Number(conveyancingFees) +
      Number(depreciationSchedule) +
      Number(registrationFee)
    );
  };
  const handleRegistrationFeeBlur = () => {
    const value = registrationFee;
    // setRegistrationFee(value);
    handleChange('registrationFees', value);
  };
  useEffect(() => {
    const value = propertyDetails?.state
      ? calculateTransferFee(propertyDetails.state as unknown as State, Number(propertyDetails.propertyValue))
      : 0
    setRegistrationFee(value.toString())
  }, [propertyDetails && propertyDetails.state, propertyDetails && propertyDetails.propertyValue])


  return (

    <Box>
      <Card
        sx={{
          padding: "18px 20px 20px 20px !important",
          marginBottom: "30px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
          backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
        }}>
        <div className="flex justify-between">
          <Typography variant="h6" gutterBottom pb={2}>
            Acquisition Costs
          </Typography>
          <Typography variant="h5" gutterBottom pb={2}>
            Total:  <b>${formatNumberWithCommas(calculateTotalCost().toFixed(0))}</b>
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Deposit"
              type="number"
              value={Number(acquisitionCosts?.deposit) || ''}
              // onChange={(e) => handleChange('deposit', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Building & Pest Inspection"
              type="number"
              value={acquisitionCosts?.buildingAndPestInspection || ''}
              onChange={(e) => handleChange('buildingAndPestInspection', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Miscellaneous Cost"
              type="number"
              value={acquisitionCosts?.buyersAgentFee || ''}
              onChange={(e) => handleChange('buyersAgentFee', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Stamp Duty"
              type="number"
              value={acquisitionCosts?.stampDuty || ''}
              // onChange={(e) => handleChange('stampDuty', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Conveyancing Fees"
              type="number"
              value={acquisitionCosts?.conveyancingFees || ''}
              onChange={(e) => handleChange('conveyancingFees', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Transfer Registration Fees"
              type="number"
              onChange={(e) => setRegistrationFee(e.target.value)}
              onBlur={handleRegistrationFeeBlur}
              value={propertyDetails?.acquisitionCosts.registrationFees || registrationFee}
              // value={propertyDetails?.state 
              //   ? calculateTransferFee(propertyDetails.state as unknown as State, Number(propertyDetails.propertyValue))
              //   : 0}
              // onChange={(e) => handleChange('conveyancingFees', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Depreciation Schedule"
              type="number"
              value={acquisitionCosts?.depreciationSchedule || ''}
              onChange={(e) => handleChange('depreciationSchedule', Number(e.target.value))}
              InputProps={{
                startAdornment: '$',
              }}
              fullWidth
              sx={{
                '& .MuiInputBase-input-focused': {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100],
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
          <TextField
            label="Total"
            type="number"
            value={calculateTotalCost()}
            InputProps={{
            readOnly: true,
              startAdornment: '$',
            }}
            fullWidth
            sx={{
              '& .MuiInputBase-input-focused': {
                color: colors.grey[100],
                borderColor: colors.grey[100],
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.grey[100],
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.grey[100], // Adjust as needed
              },
            }}
          />
        </Grid> */}
        </Grid>
      </Card>
    </Box>
  );
};

export default AcquisitionCosts;