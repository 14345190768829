import { Box, Typography, useTheme, IconButton, Button, Grid, Card, } from "@mui/material";
import React, { useRef, useState } from "react";

import { tokens } from "../../../theme";

import ReportCard from "../../components/ReportCard";

import LineChart from "../../components/charts/LineChart";

import PieChart from "../../components/charts/PieChart";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BarChart from "../../components/charts/BarChart";
import { useNavigate, useSearchParams } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { GeneratePdf } from "../buyersAgentDashboard/GeneratePdf";
const BuySellReporting = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const buySellPortfolio = useSelector(
    (state: RootState) => state.investedProperties.buySellPortfolio
  );
  const lastPortfolio = buySellPortfolio[buySellPortfolio.length - 1];
  const BarChartPreTaxIncome = useSelector(
    (state: RootState) => state.investedProperties.barChartPreTaxPassiveIncome
  );
  const [buttonLabel, setButtonLabel] = useState("DOWNLOAD REPORTS");
  const [searchParams] = useSearchParams();
  console.log('searchParams', searchParams)
  const scenarioId = searchParams.get('scenarioId') || '';
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/buy-sell-modelling?scenarioId=${scenarioId}`);
  };
  // const formatValue = (value:any) => {
  //   if (value >= 100000000) {
  //     return (value / 1000000).toFixed(2) + 'M';
  //   }
  //   return value.toLocaleString();
  // };
  const pdfRef = useRef<HTMLDivElement>(null);
  console.log(pdfRef, "elem")

  const generatePDF = async () => {
    setButtonLabel("Preparing PDF...");

    const element = pdfRef.current;
    console.log(element, "element")
    if (!element) return;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save("buy-sell-reporting.pdf");

    setButtonLabel("DOWNLOAD REPORTS");
  };


  const totalCashInHand = (Number(lastPortfolio.outstandingLoansInProjectYear) || 0) - (Number(lastPortfolio && (lastPortfolio.remainingCash)))
  const Remaining_cash: any = lastPortfolio.remainingCash // Could be of any type
  // const stringValue = value.toString(); // Convert it to a string just in case

  return (
    <Box className="p-6">

      <div ref={pdfRef}>
        {/* GRID & CHARTS */}
        <Box display="flex" py={2}>
          <div className="d-flex" style={{ gap: "1rem", display: 'flex' }}>
            <Button variant="outlined" onClick={handleBack} color="secondary">
              <ArrowBackIcon sx={{ marginRight: "5px" }} /> Go Back
            </Button>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }} className="p-6"> */}
            <Button
              variant="outlined"
              color="secondary"
              onClick={generatePDF}
              sx={{
                // borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "capitalize",
                color: "",
                padding: "10px 28px",
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <DownloadIcon />
              {buttonLabel}
            </Button>
          </div>
          {/* </Box> */}
          <Typography
            variant="h4"
            align="right"
            className="gradient-text mr-1 "

            style={{
              marginLeft: "auto",
              // animation: "blink 1s infinite",
              fontWeight: "600",
            }}
          >

            {lastPortfolio && lastPortfolio.isTestPassed ? "Goal achieved" : "Goal not achieved"}
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="10px"
        >
          {/* ROW 1 */}
          <Box
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",
              width: "100%",
              height: "100px",
            }}
            display="flex"
            gridColumn="span 3"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Initial Asset Valuation"}
              price={lastPortfolio && lastPortfolio.initialPropertyValue || 0}
              color={colors.greenAccent[400]}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",

              width: "100%",
              height: "100px",
            }}
            gridColumn="span 3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Projected Asset Value"}
              price={(lastPortfolio && lastPortfolio.modelledPropertyValue) || 0}
              color={"#FE8723"}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",

              width: "100%",
              height: "100px",
            }}
            gridColumn="span 3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Projected Asset Value (Loan-Free)"}
              price={lastPortfolio && lastPortfolio.propertyValueNoLoans || 0}
              color={"#5b8a7e"}
            />
          </Box>

          <Box
            className="gradient-border"

            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",

              width: "100%",
              height: "100px",
            }}

            gridColumn="span 3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Pre-Tax Passive Income Forecast"}
              price={lastPortfolio && lastPortfolio.preTaxPassiveIncome || 0}
              color={"#F9433E"}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",

              width: "100%",
              height: "100px",
            }}
            gridColumn="span 3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Outstanding Loans In Projection Year"}
              price={lastPortfolio && lastPortfolio.outstandingLoansInProjectYear || 0}
              color={"#3F46F7"}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",

              width: "100%",
              height: "100px",
            }}
            gridColumn="span 3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Cash Residue Post-Sale"}
              price={lastPortfolio && (lastPortfolio.remainingCash) || 0}
              color={"#A95C1"}
            />
          </Box>


          {/* <Box
          sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "20px",

            width: "100%",
            height: "100px",
          }}
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ReportCard
            title={"Cash in hand post loan closures"}
            price={(
              lastPortfolio.outstandingLoansInProjectYear - parseFloat(Remaining_cash.replace(/,/g, ''))
            ) || 0 }
                        color={"#A95C1"}
          />
        </Box> */}
          <Box
            sx={{
              backgroundColor: colors.primary[400],
              borderRadius: "20px",

              width: "100%",
              height: "100px",
            }}
            gridColumn="span 3"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ReportCard
              title={"Loan after sales"}
              price={(
                lastPortfolio.loanPostSales)
              }
              color={"#A95C1"}
            />
          </Box>



          {/* ROW 2 */}
          <Box gridColumn="span 12" gridRow="span 2" >


            <Grid container item md={12} spacing={2} pb={5}>
              <Grid item xs={12} sm={6} xl={7}>
                <Card
                  sx={{
                    padding: "20px 20px 20px !important",
                    marginBottom: "0px !important",
                    borderRadius: "15px !important",
                    boxShadow: "none !important",
                    height: "auto",
                    backgroundColor: colors.primary[400],
                  }}
                >
                  <Typography
                    variant="h4"
                    align="left"
                    pb={2}
                    zIndex={1}
                    gutterBottom
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Pre-Tax Income
                  </Typography>

                  <Box height="250px" m="-20px 0 0 0">
                    <BarChart isDashboard={true} />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} xl={5}>
                <Card
                  sx={{
                    padding: "20px 20px 20px !important",
                    marginBottom: "0px !important",
                    borderRadius: "15px !important",
                    boxShadow: "none !important",
                    height: "auto",
                    backgroundColor: colors.primary[400],
                  }}
                >
                  <Typography
                    variant="h4"
                    align="left"
                    pb={2}
                    zIndex={1}
                    gutterBottom
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Loans after Sales
                  </Typography>
                  <Box height="250px" m="-20px 0 0 0">
                    <PieChart isDashboard={true} />
                  </Box>
                </Card>
              </Grid>
            </Grid>


            <Grid item xs={12} md={7} lg={3}>
              <Card
                sx={{
                  padding: "20px !important",
                  marginBottom: "0px !important",
                  borderRadius: "15px !important",
                  boxShadow: "none !important",
                  height: "500px",
                  backgroundColor: colors.primary[400],
                }}
              >
                <Typography
                  variant="h4"
                  align="left"

                  zIndex={1}
                  gutterBottom
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Modelled Year - {lastPortfolio && lastPortfolio.modelledYear}
                </Typography>

                <LineChart isDashboard={true} />
              </Card>
            </Grid>

          </Box>
        </Box>
      </div>
    </Box >
  );
};

export default BuySellReporting;