
export const calculateBorrowableEquity = (investmentProperties: any) => {

  console.log(investmentProperties, '++++++++')
  // return investmentProperties?.map((property: any) => {
  const loan = parseFloat(investmentProperties?.currentloanamount) || 0;
  const currentEstimatedValue = parseFloat(investmentProperties?.currentEstimated_value) || 0;

  const currentLoanAmount = loan || 0;

  const borrowableEquity = currentEstimatedValue * 0.8 - currentLoanAmount;
  console.log(currentEstimatedValue, currentLoanAmount)
  console.log(borrowableEquity)

  return borrowableEquity

  // const currentEstimatedValue = parseFloat(investmentProperties?.value) || 0;
  // const loanToValueRatio = 0.8;  // 80% of the property value
  // const loanAmount = currentEstimatedValue * loanToValueRatio;  // 80% of the property value
  // const result = loanAmount - investmentProperties?.deposit;
  // return result
};
// export const calculateBorrowableEquity = (investmentProperties: any) => {
//   // Check if investmentProperties is an array
//   if (!Array.isArray(investmentProperties)) {
//     console.warn('Expected investmentProperties to be an array, but got:', investmentProperties);
//     return []; // Return an empty array if it's not an array
//   }

//   return investmentProperties.map((property: any) => {
//     const currentEstimatedValue = parseFloat(property.currentestimatedvalue) || 0;
//     const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
//     return currentEstimatedValue * 0.8 - currentLoanAmount;
//   });
// };
